import classNames from 'classnames';
import styles from './ProductDetails.module.scss';
import { useEffect, useState } from 'react';
import {
	Button,
	Paragraph,
	Heading,
	InformationText,
	Picture,
	Link,
	Spinner,
} from 'ui/components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Modal } from '../Modal';
import { ProductDiscontinuedInfo } from '../ProductDiscontinuedInfo';
import { currentCulture } from 'application/adapters/store/slices/cultureSlice';
import { useSelector } from 'react-redux';

export interface ProductDetailsProps {
	className?: string;
	product: Models.CategoryProduct;
	imageSizes?: string;
	isLoggedIn?: boolean;
	updateBasketCallback: (cartProduct: Models.UpdateBasketProduct) => void;
	getProductPriceCallback: (cartProduct: Models.GetPriceRequest) => Promise<void>;
	basketId: string;
	profile?: {
		name?: string | null;
		email?: string | null;
		image?: string | null;
	};
	onSignIn: () => void;
	bpId: string;
	dictionary: { [key: string]: string };
}

export const ProductDetails: React.FC<ProductDetailsProps> = ({
	className,
	product,
	imageSizes,
	isLoggedIn = false,
	updateBasketCallback,
	getProductPriceCallback,
	basketId,
	profile,
	onSignIn,
	bpId,
	dictionary,
}) => {
	const culture = useSelector(currentCulture);
	isLoggedIn = profile ? true : false;

	const [activeSlide, setActiveSlide] = useState<number | string>(0);
	const [quantityValue, setQuantityValue] = useState<number | string>(1);

	const [isOpen, setIsOpen] = useState(false);
	const [isGettingPrice, setIsGettingPrice] = useState(false);
	const handleClose = () => setIsOpen(false);

	function handleAddToCart() {
		const cartProduct: Models.UpdateBasketProduct = {
			basketId,
			quantityDelta: Number(quantityValue),
			sku: product?.sku,
			cultureCode: culture,
			bpId,
		};
		updateBasketCallback(cartProduct);
	}

	const handleGetPrice = async () => {
		setIsGettingPrice(true);
		const reqData: Models.GetPriceRequest = {
			qty: 1, //default value, if ever needs to be changed,
			// consider adding a quantity input for price calculation
			// that is either different from the one from add to cart or change the UI because it's confusing to use the same input for both
			sku: product?.sku,
			bpId,
		};
		await getProductPriceCallback(reqData);
		setIsGettingPrice(false);
	}

	// No scroll when Modal is open
	useEffect(() => {
		if (isOpen) {
			document.querySelector('body').style.overflow = 'hidden';
		} else {
			handleClose();
			document.querySelector('body').style.overflow = 'unset';
		}
	}, [isOpen]);

	function swiperSlides() {
		if (product?.productImages?.length > 0) {
			return product?.productImages?.map((productImage, index) => {
				return (
					<SwiperSlide
						className={styles.ProductDetails_swiperSlide}
						key={index + 1}
					>
						<div
							className={
								styles.ProductDetails_swiperSlideContainer
							}
							onClick={() => setIsOpen(true)}
						>
							<Picture
								url={productImage}
								properties={{ altText: product?.name }}
							/>
						</div>
					</SwiperSlide>
				);
			});
		} else {
			let defaultSlide;
			if (product?.image) {
				defaultSlide = (
					<SwiperSlide
						className={styles.ProductDetails_swiperSlide}
						key={0}
					>
						<div
							className={
								styles.ProductDetails_swiperSlideContainer
							}
							onClick={() => setIsOpen(true)}
						>
							<Picture
								sizes={imageSizes}
								url={product.image}
								properties={{ altText: product?.name }}
							/>
						</div>
					</SwiperSlide>
				);
			}

			let variantsSlide;
			if (!product?.variants) {
				variantsSlide = !product?.variants
					? null
					: product?.variants?.map((productVariant, index) => (
							<SwiperSlide
								className={styles.ProductDetails_swiperSlide}
								key={index + 1}
							>
								<div
									className={
										styles.ProductDetails_swiperSlideContainer
									}
									onClick={() => setIsOpen(true)}
								>
									<Picture
										sizes={imageSizes}
										{...product?.image}
									/>
								</div>
							</SwiperSlide>
					  ));
			}

			return [defaultSlide, variantsSlide];
		}
	}

	return (
		<div className={classNames(styles.ProductDetails, className)}>
			{isOpen && (
				<Modal
					handleClose={handleClose}
					modalType="productDetailsModal"
					product={product}
					activeSlide={activeSlide}
				/>
			)}
			<div className={styles.ProductDetails_image}>
				<Swiper
					slidesPerView={1}
					// navigation
					pagination={{
						clickable: true,
						type: 'bullets',
					}}
					onSlideChange={(e) => {
						setActiveSlide(e.realIndex);
					}}
				>
					{swiperSlides()}
				</Swiper>
			</div>

			<div className={styles.ProductDetails_description}>
				{product?.isDiscontinued && (
					<ProductDiscontinuedInfo
						replacements={product?.replacements}
						hasReplacements={product?.hasReplacements}
					/>
				)}
				{product?.sku && (
					<p className={styles.ProductDetails_serial}>
						{dictionary?.productNumber}
						<b className={styles.ProductDetails_serialvalue}>
							{product?.sku}
						</b>
					</p>
				)}

				{product?.name && (
					<Heading
						className={styles.ProductDetails_heading}
						headingLevel="h2"
					>
						{product?.name}
					</Heading>
				)}

				{product?.shortDescription && (
					<Paragraph className="u-top-margin--xs u-bottom-margin--xl">
						{product?.shortDescription}
					</Paragraph>
				)}
				{product?.transportHazard && (
					<InformationText
						className={styles.ProductDetails_transportHazard}
						text={dictionary.transportHazard}
						iconText={dictionary.transportHazardInfo}
					/>
				)}
			</div>

			{/* ADD TO CART */}
			<div className={styles.ProductDetails_cart}>
				{isGettingPrice ? (
					<div className={styles.ProductDetails_spinner}>
						<Spinner size="large" speed="slow" center text={dictionary.waitText} />
					</div>

				) : (
					product?.price?.formattedPrice != null || !isLoggedIn ? (
						<div className={styles.ProductDetails_priceWrapper}>
							<b
								className={classNames(
									styles.ProductDetails_priceLabel,
								)}
							>
								{dictionary.price}
							</b>
							<div
								className={styles.ProductDetails_priceValueWrapper}
							>
								{isLoggedIn ? (
									<Heading headingLevel="h3" style="md">
										{product?.price?.currencyCode?.length ===
											3 && Number(product?.price?.price) > 0
											? product?.price?.formattedPrice
											: ``}
									</Heading>
								) : (
									<p className={styles.ProductDetails_price}>
										{dictionary?.signInToViewPrice}
									</p>
								)}
							</div>
						</div>
					) : bpId && (
						<Button
							className={classNames(
								styles.ProductDetails_fetchPriceButton,
								
							)}
							style="secondary"
							onClick={() => handleGetPrice()}
						>
							{dictionary.fetchProductPrice}
						</Button>
					)
				)}

				{isLoggedIn ? (
					<>
						<div>
							<label htmlFor="quantity" className={styles.ProductDetails_quantityLabel}>{dictionary.quantityLabel}</label>
							<div className={styles.ProductDetails_action}>
								<input
									type="number"
									className={classNames(
										styles.ProductDetails_input,
										quantityValue === '' && styles.hasError,
									)}
									name="quantity"
									value={quantityValue}
									min={1}
									onChange={(e) => {
										const value = e.target.value.replace(
											/[^\d]/,
											'',
										);
										if (parseInt(value) !== 0) {
											setQuantityValue(value);
										}
									}}
								/>
								<Button
									className={classNames(
										styles.ProductDetails_submitButton,
										styles.ProductDetails_submitButton___loggedIn,
									)}
									style="primary"
									disabled={
										quantityValue === '' ||
										!bpId ||
										product?.isDiscontinued
											? true
											: false
									}
									onClick={() => handleAddToCart()}
								>
									{dictionary?.addToCart}
								</Button>
							</div>
						</div>

						{!bpId && (
							<Link
								url={'/my-account/delivery-addresses'}
								className={styles.CheckoutReceipt_link}
							>
								{dictionary?.chooseBusinessPartner}
							</Link>
						)}
					</>
				) : (
					<>
						<Button
							className={styles.ProductDetails_submitButton}
							style="primary"
							onClick={() => {
								onSignIn();
							}}
						>
							{dictionary?.signInButton}
						</Button>
					</>
				)}
			</div>
		</div>
	);
};
