import { useState, useContext, Fragment } from 'react';
import styles from './HelpForm.module.scss';
import {
	Button,
	Paragraph,
	Form,
	FormfieldSelect,
	FormfieldString,
	FormfieldTextarea,
	Grid,
	GridCell,
} from 'ui/components';
import { useForm, SubmitHandler, useFormState } from 'react-hook-form';
import countryList from 'react-select-country-list';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import classNames from 'classnames';
import { CategoryFilter } from '../CategoryFilter';

interface FormData {
	selectedCategory: number;
	email: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	faxNumber: string;
	company: string;
	address: string;
	addressLine2: string;
	postalCode: string;
	city: string;
	state: string;
	country: string;
	shopRole: string;
	invoiceEmail: string;
	textarea: string;
	description: string;
}

export interface HelpFormProps {
	description?: string;
	categoriesTitle?: string;
	categories: Models.CategoryFilterItem[];
	onFormSubmit: (data: any) => void;
}

export const HelpForm: React.FC<HelpFormProps> = ({
	description,
	categoriesTitle,
	categories,
	onFormSubmit,
}) => {
	const dictionary = useContext(DictionaryContext);

	// FORM HANDLING
	const [allErrors, setAllErrors] = useState(0);
	const [errorSummary, setErrorSummary] = useState(0);
	const [selectedCategory, setSelectedCategory] = useState(0);
	const { register, handleSubmit, control, setValue } = useForm<FormData>();
	const onSubmit: SubmitHandler<FormData> = (data) => {
		onFormSubmit({
			category:
				categories?.find((c) => c.categoryNumber === selectedCategory)
					?.children ?? '',
			...data,
		});
	};

	const handleCategoryChanged = (
		categoryFilterItem: Models.CategoryFilterItem,
	) => {
		setSelectedCategory(categoryFilterItem.categoryNumber);
	};

	const { errors } = useFormState({
		control,
	});

	const onError = (data) => {
		const totalErrors = Object.keys(data).length;
		setErrorSummary(totalErrors);
		setAllErrors(data);
	};

	const errorMessage = dictionary.getValue(
		'Form.ErrorMessage',
		null,
		'This field is required',
	);

	// Populate country selector with all countries
	const countrySelectOptions = countryList()
		.getData()
		.map((obj: { label: string; value: string; text: string }) => {
			obj.text = obj.label;

			return obj;
		});
	// / FORM HANDLING

	return (
		<>
			<Form
				className={styles.ModalHelpForm}
				onSubmit={handleSubmit(onSubmit, onError)}
				errorSummary={errorSummary}
			>
				{description && (
					<Paragraph
						className={classNames(
							styles.HelpForm_Description,
							'u-bottom-margin--md',
						)}
					>
						<span
							dangerouslySetInnerHTML={{
								__html: description,
							}}
						></span>
					</Paragraph>
				)}

				<div className={classNames(styles.HelpForm_Categories)}>
					<CategoryFilter
						className="CategoryFilter_Modal"
						smallHeading={categoriesTitle}
						categoryFilterItems={categories}
						handleSelectionChanged={handleCategoryChanged}
					/>
				</div>

				<Grid wrap={true} container={true}>
					<GridCell mobileWidth="100" desktopWidth="100">
						<FormfieldTextarea
							id="description"
							name="description"
							label={dictionary.getValue(
								'Form.Description',
								null,
								'Description',
							)}
							placeholder={dictionary.getValue(
								'Form.TypeHere',
								null,
								'Type here',
							)}
							className={classNames(
								styles.Formfield,
								'u-top-margin--md u-bottom-margin--md',
							)}
							register={register}
							state={
								errors.description
									? { hasError: true, required: true }
									: { required: true }
							}
							errorMessage={dictionary.getValue(
								'Form.ErrorMessage',
								null,
								'This field is required',
							)}
						/>
					</GridCell>
					<GridCell mobileWidth="100" desktopWidth="50">
						<FormfieldString
							id="firstName"
							name="firstName"
							label={dictionary.getValue(
								'Form.FirstName',
								null,
								'First name',
							)}
							type="text"
							register={register}
							className={classNames(
								styles.Formfield,
								'u-bottom-margin--md',
							)}
							state={
								errors.firstName
									? {
											hasError: true,
											required: true,
									  }
									: { required: true }
							}
							errorMessage={errorMessage}
						/>
					</GridCell>
					<GridCell mobileWidth="100" desktopWidth="50">
						<FormfieldString
							id="lastName"
							name="lastName"
							label={dictionary.getValue(
								'Form.LastName',
								null,
								'Last name',
							)}
							type="text"
							register={register}
							className={classNames(
								styles.Formfield,
								'u-bottom-margin--md',
							)}
							state={
								errors.lastName
									? {
											hasError: true,
											required: false,
									  }
									: { required: false }
							}
							errorMessage={errorMessage}
						/>
					</GridCell>
					<GridCell mobileWidth="100" desktopWidth="50">
						<FormfieldString
							id="email"
							name="email"
							label={dictionary.getValue(
								'Form.Email',
								null,
								'Email',
							)}
							type="email"
							register={register}
							className={classNames(
								styles.Formfield,
								'u-bottom-margin--md',
							)}
							state={
								errors.email
									? {
											hasError: true,
											required: true,
									  }
									: { required: true }
							}
							errorMessage={errorMessage}
							pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
						/>
					</GridCell>
					<GridCell mobileWidth="100" desktopWidth="50">
						<FormfieldString
							id="phoneNumber"
							name="phoneNumber"
							label={dictionary.getValue(
								'Form.PhoneNumber',
								null,
								'Phone number',
							)}
							type="text"
							register={register}
							className={classNames(
								styles.Formfield,
								'u-bottom-margin--md',
							)}
							state={
								errors.phoneNumber
									? {
											hasError: true,
											required: false,
									  }
									: { required: false }
							}
							errorMessage={errorMessage}
						/>
					</GridCell>
					<GridCell mobileWidth="100" desktopWidth="50">
						<FormfieldString
							id="company"
							name="company"
							label={dictionary.getValue(
								'Form.CompanyName',
								null,
								'Company Name',
							)}
							type="text"
							register={register}
							className={classNames(
								styles.Formfield,
								'u-bottom-margin--md',
							)}
							state={
								errors.company
									? {
											hasError: true,
											required: false,
									  }
									: { required: false }
							}
							errorMessage={errorMessage}
						/>
					</GridCell>
					<GridCell mobileWidth="100" desktopWidth="50">
						<FormfieldString
							id="company"
							name="company"
							label={dictionary.getValue(
								'Form.CompanyName',
								null,
								'Company Name',
							)}
							type="text"
							register={register}
							className={classNames(
								styles.Formfield,
								'u-bottom-margin--md',
							)}
							state={
								errors.company
									? {
											hasError: true,
											required: false,
									  }
									: { required: false }
							}
							errorMessage={errorMessage}
						/>
					</GridCell>
					<GridCell mobileWidth="100" desktopWidth="50">
						<FormfieldSelect
							id="country"
							name="country"
							label={dictionary.getValue(
								'Form.Country',
								null,
								'Country',
							)}
							defaultValue=""
							options={[
								{
									value: '',
									text: 'Choose a country',
									hidden: true,
									disabled: true,
								},
								...countrySelectOptions,
							]}
							className={classNames(
								styles.Formfield,
								'u-bottom-margin--md',
							)}
							register={register}
							state={
								errors.country
									? {
											hasError: true,
											required: false,
									  }
									: { required: false }
							}
							errorMessage={errorMessage}
						/>
					</GridCell>
					<GridCell mobileWidth="100" desktopWidth="50">
						<FormfieldString
							id="city"
							name="city"
							label={dictionary.getValue(
								'Form.City',
								null,
								'City',
							)}
							type="text"
							register={register}
							className={classNames(
								styles.Formfield,
								'u-bottom-margin--md',
							)}
							state={
								errors.city
									? {
											hasError: true,
											required: false,
									  }
									: { required: false }
							}
							errorMessage={errorMessage}
						/>
					</GridCell>
				</Grid>

				<GridCell
					mobileWidth="100"
					desktopWidth="50"
					className={styles.HelpForm_submitButtonContainer}
				>
					<Button
						style="primary"
						type="submit"
						aria-label={dictionary.getValue(
							'Form.Send',
							null,
							'Send Enquiry',
						)}
					>
						{dictionary.getValue('Form.Send', null, 'Send Enquiry')}
					</Button>
				</GridCell>
			</Form>
		</>
	);
};
