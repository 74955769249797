import { useState } from 'react';
import styles from './Facet.module.scss';
import classNames from 'classnames';
import { Button, Icon } from 'ui/components';
import { ReactComponent as ChevronIcon } from 'ui/icons/icon-chevron.svg';
import { FacetProp } from '../Facets';

interface FacetModel {
	facetChanged: (value: string) => void;
	facet: FacetProp;
	selectionId: string;
}

export const Facet: React.FC<FacetModel> = ({
	facetChanged,
	facet,
	selectionId,
}) => {
	const [isActive, setIsActive] = useState(false);

	return (
		<div
			className={classNames(styles.Facet_section, {
				[styles.isOpen]: isActive,
			})}
		>
			<Button
				className={styles.Facet_triggerButton}
				style="secondary"
				onClick={() => setIsActive(!isActive)}
			>
				<h3 className={styles.Facet_sectionHeading}>{facet.displayName}</h3>
				<div className={styles.Facet_buttonIconContainer}>
					<Icon size="xl" className={styles.Facet_buttonIcon}>
						<ChevronIcon />
					</Icon>
				</div>
			</Button>
			<div className={styles.Facet_content}>
				{facet.facetvalues &&
					facet.facetvalues.map((facetInput) => (
						<label
							className={styles.Facet_label}
							key={selectionId + facet.name + facetInput.value}
						>
							<input
								className={styles.Facet_checkbox}
								type="checkbox"
								id="scales"
								name="scales"
								onClick={() => facetChanged(facetInput.value)}
							/>
							{facetInput.value}{' '}
							{facetInput.count && '(' + facetInput.count + ')'}
						</label>
					))}
			</div>
		</div>
	);
};
