import classNames from 'classnames';
import styles from './ArticleMedia.module.scss';
import { Container, ContainerWidth, Picture } from 'ui/components';

export interface ArticleMediaProps {
	className?: string;
	image: Umbraco.Image;
	caption: string;
	containerWidth: ContainerWidth;
}

export const ArticleMedia: React.FC<ArticleMediaProps> = ({
	className,
	image,
	caption,
	containerWidth = 'Small',
}) => (
	<Container width={containerWidth}>
		<figure className={classNames(styles.ArticleMedia, className)}>
			{image && (
				<span className={styles.ArticleMedia_mediabox}>
					<Picture
						aspectRatio={0.4}
						focalPoint={image.focalPoint}
						url={image.url}
						properties={image.properties}
						sizes="100vw"
						className={styles.ArticleMedia_mediabox}
					/>
				</span>
			)}
			{caption && (
				<div className="">
					<figcaption className={styles.ArticleMedia_caption}>
						{caption}
					</figcaption>
				</div>
			)}
		</figure>
	</Container>
);
