import styles from './ModalProductImages.module.scss';
import classNames from 'classnames';
import { Picture } from 'ui/components';
import { useEffect, useState } from 'react';

export interface ModalProductImagesProps {
	product: Models.CategoryProduct;
	activeSlide?: number | string;
}

export const ModalProductImages: React.FC<ModalProductImagesProps> = ({
	product,
	activeSlide,
}) => {
	const [isActive, setIsActive] = useState<number | string>();

	useEffect(() => {
		setIsActive(activeSlide);
	}, [activeSlide]);

	if (product.productImages && product.productImages.length > 0) {
		return (
			<div className={styles.ModalProductImages}>
				<div className={styles.ModalProductImages_imagesContainer}>
					{product.productImages.map((productVariant, index) => (
						<div
							key={index}
							className={classNames(
								styles.ModalProductImages_images,
								isActive === index ? styles.isActive : '',
							)}
						>
							<Picture
								sizes={'(max-width: 768px)'}
								url={productVariant}
								aspectRatio={0.75}
							/>
						</div>
					))}
				</div>

				<div className={styles.ModalProductImages_paginationContainer}>
					{product.productImages.map((productVariant, index) => (
						<div
							key={index}
							className={classNames(
								styles.ModalProductImages_pagination,
								isActive === index ? styles.isActive : '',
							)}
							onClick={() => {
								setIsActive(index);
							}}
						>
							<Picture
								sizes={'(max-width: 768px) 50vw, 50vw'}
								url={productVariant}
								aspectRatio={0.75}
							/>
						</div>
					))}
				</div>
			</div>
		);
	} else {
		return (
			<div className={styles.ModalProductImages}>
				<div className={styles.ModalProductImages_imagesContainer}>
					<div
						key={0}
						className={classNames(
							styles.ModalProductImages_images,
							styles.isActive,
						)}
					>
						<Picture
							sizes={'(max-width: 768px)'}
							url={product.image}
							aspectRatio={0.75}
						/>
					</div>
				</div>

				<div className={styles.ModalProductImages_paginationContainer}>
					<div
						key={0}
						className={classNames(
							styles.ModalProductImages_pagination,
							styles.isActive,
						)}
					>
						<Picture
							sizes={'(max-width: 768px) 50vw, 50vw'}
							url={product.image}
							aspectRatio={0.75}
						/>
					</div>
				</div>
			</div>
		);
	}
};
