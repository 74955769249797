import styles from './OrderDetails.module.scss';
import { Button, Container, Heading, Spinner } from 'ui/components';
export interface OrderDetailsProps {
	orderNumber?: string;
	orderStatus?: string;
	purchasedOrderNumber?: string;
	recipientEmail?: string;
	customerReference?: string;
	plannedDeliveryDate?: string;
	reorderDisabled?: boolean;
	handleReorder: () => void;
	reorderButtonText: string;
	dictionary: { [key: string]: string };
}

export const OrderDetails: React.FC<OrderDetailsProps> = ({
	orderNumber,
	orderStatus,
	purchasedOrderNumber,
	recipientEmail,
	customerReference,
	plannedDeliveryDate,
	dictionary,
	reorderDisabled,
	handleReorder,
	reorderButtonText,
}) => {
	return (
		<Container width="Standard" className="u-bottom-margin--xl">
			<Heading headingLevel="h2" className="u-bottom-margin--xl">
				Order #{orderNumber}
			</Heading>
			<div className={styles.OrderDetails_details}>
				<div className={styles.OrderDetails_columns}>
					<div className={styles.OrderDetails_column}>
						{orderNumber && (
							<div className={styles.OrderDetails_column_item}>
								<strong>{dictionary?.salesOrderNumber}</strong>{' '}
								{orderNumber}
							</div>
						)}
						{orderStatus && (
							<div className={styles.OrderDetails_column_item}>
								<strong>{dictionary?.orderStatus}</strong>{' '}
								{orderStatus}
							</div>
						)}
						{plannedDeliveryDate && (
							<div className={styles.OrderDetails_column_item}>
								<strong>{dictionary?.plannedDeliveryDate}:</strong>{' '}
								{plannedDeliveryDate}
							</div>
						)}
					</div>
					<div className={styles.OrderDetails_column}>
						{purchasedOrderNumber && (
							<div className={styles.OrderDetails_column_item}>
								<strong>
									{dictionary?.purchasedOrderNumber}
								</strong>{' '}
								{purchasedOrderNumber}
							</div>
						)}
						{recipientEmail && (
							<div className={styles.OrderDetails_column_item}>
								<strong>{dictionary?.sdsRecipientEmail}</strong>{' '}
								{recipientEmail}
							</div>
						)}
						{customerReference && (
							<div className={styles.OrderDetails_column_item}>
								<strong>{dictionary?.customerReference}</strong>{' '}
								{customerReference}
							</div>
						)}
					</div>
				</div>
				<Button
					className={styles.OrderDetails_button}
					style="primary"
					onClick={handleReorder}
					disabled={reorderDisabled}
				>
					<span>{reorderButtonText}</span>
					{reorderDisabled && (
						<Spinner size="small" color="light" speed="slow" />
					)}
				</Button>
			</div>
		</Container>
	);
};
