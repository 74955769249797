import React from 'react';
import classNames from 'classnames';
import styles from './Heading.module.scss';

export interface HeadingProps {
	children?: React.ReactNode;
	className?: string;
	headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	style?: 'default' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	textCenter?: boolean,
	uppercase?: boolean;
}

export const Heading: React.FC<HeadingProps> = ({
	children,
	className,
	headingLevel,
	style = 'default',
	textCenter,
	uppercase,
}) =>
	React.createElement(
		headingLevel,
		{
			className: classNames(
				styles.Heading,
				styles[`Heading___${style}`],
				{
					[styles.Heading___textCenter]: textCenter,
					[styles.Heading___uppercase]: uppercase,
				},
				className,
			),
		},
		children,
	);
