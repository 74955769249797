import { combineReducers } from '@reduxjs/toolkit';
import cartReducers from './slices/cartSlice';
import productReducers from './slices/productsSlice';
import categoryReducers from './slices/categorySlice';
import profileReducers from './slices/profileSlice';
import customerReducers from './slices/customerSlice';
import cultureReducers from './slices/cultureSlice';
import orderReducers from './slices/orderSlice';

export const rootReducer = combineReducers({
	products: productReducers,
	cart: cartReducers,
	category: categoryReducers,
	profile: profileReducers,
	customer: customerReducers,
	culture: cultureReducers,
	order: orderReducers,
});
