import { Heading, Icon, Link, Paragraph } from 'ui/components';
import styles from './ProductDiscontinuedInfo.module.scss';
import classNames from 'classnames';
import { ReactComponent as DiscontinuedIcon } from 'ui/icons/icon-discontinued.svg';
import { useContext } from 'react';
import { DictionaryContext } from 'application/adapters/context/Dictionary';

export interface ProductDiscontinuedInfoProps {
	replacements: string[];
	hasReplacements: boolean;
}

export const ProductDiscontinuedInfo: React.FC<ProductDiscontinuedInfoProps> =
	({ replacements, hasReplacements }) => {
		const dictionary = useContext(DictionaryContext);

		return (
			<div
				className={classNames(
					styles.ProductDiscontinued_boxView,
					styles.ProductDiscontinued_boxViewDiscontinued,
				)}
			>
				<div className={styles.ProductDiscontinued_discontinuedContent}>
					<Icon
						size="xl"
						className={styles.ProductDiscontinued_discontinuedIcon}
					>
						<DiscontinuedIcon />
					</Icon>

					<div
						className={styles.ProductDiscontinued_discontinuedText}
					>
						<Heading headingLevel="h4">
							{dictionary.getValue(
								'ShoppingCart.ProductDiscontinuedHeading',
								null,
								'This is a discontinued product',
							)}
						</Heading>

						{hasReplacements && replacements.length > 0 && (
							<>
								<Paragraph style="xs">
									{dictionary.getValue(
										'ShoppingCart.ProductDiscontinuedCanBeReplacedBy',
										null,
										'Can be replaced by: ',
									)}

									{replacements.map((replacement, index) => (
										<Link
											key={index}
											url={`/product/${replacement}`}
											className={
												styles.ProductDiscontinued_replacements
											}
										>
											{replacement}
										</Link>
									))}
								</Paragraph>

								<Paragraph
									style="xs"
									className={
										styles.ProductDiscontinued_clickTheLick
									}
								>
									{dictionary.getValue(
										'ShoppingCart.ProductDiscontinuedReplacement',
										null,
										'Click the link to go to the new product and add it. Remove this line to continue to checkout',
									)}
								</Paragraph>
							</>
						)}

						{!hasReplacements && replacements.length > 0 && (
							<>
								<Paragraph style="xs">
									{dictionary.getValue(
										'ShoppingCart.ProductDiscontinuedNoReplacement',
										null,
										'Remove this line to continue to checkout',
									)}
								</Paragraph>
							</>
						)}
					</div>
				</div>
			</div>
		);
	};
