import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'application/adapters/store/store';
import { FacetProp } from 'ui/components';
import { CategoryFilterProps } from 'ui/components/3-organisms/CategoryFilter';

interface CategoryState {
	categories: CategoryFilterProps;
	selection?: Models.CategoryFilterItem;
	facets: {
		count: number;
		name: string;
		displayName: string;
		facetvalues: { value: string; count: number }[];
	}[];
}

const initialState: CategoryState = {
	categories: {} as CategoryFilterProps,
	selection: null,
	facets: null,
};

const categorySlice = createSlice({
	name: 'category',
	initialState,
	reducers: {
		setCategories: (
			state,
			action: PayloadAction<{ [key: string]: any }>,
		) => {
			state.categories = action.payload as CategoryFilterProps;
		},
		setSelectedCategory: (
			state,
			action: PayloadAction<{ [key: string]: any }>,
		) => {
			state.selection = action.payload as Models.CategoryFilterItem;
		},
		facetsChanged: (
			state,
			action: PayloadAction<{ [key: string]: any }>,
		) => {
			state.facets = action.payload as FacetProp[];
		},
	},
});

export const { setSelectedCategory, setCategories, facetsChanged } =
	categorySlice.actions;

export const categories: (state: RootState) => CategoryFilterProps = (
	state: RootState,
) => state.category.categories;
export const selection: (state: RootState) => Models.CategoryFilterItem = (
	state: RootState,
) => state.category.selection;
export const facets: (state: RootState) => FacetProp[] = (state: RootState) =>
	state.category.facets;

export default categorySlice.reducer;
