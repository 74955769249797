import React from 'react';
import { getCookie, setCookie } from 'cookies-next';

export type FormattingOptions = {
	weekday: string;
	year: string;
	month: string;
	day: string;
};

export class Locale {
	private _dateFormatter: Intl.DateTimeFormat;
	private _locale: string;
	private _language: string;
	private _country: string;

	public constructor(locale: string) {
		this.setLocale(locale);
	}

	setLocaleParams(language: string, country = ''): void {
		this._language = language;
		this._country = country ?? this._country;
		this._locale =
			country?.length === 2
				? `${language}-${country.toUpperCase()}`
				: language;
		this._dateFormatter = new Intl.DateTimeFormat(this._locale);
		this.setLocaleToCookie(this._locale);
	}

	setLocale(locale: string): void {
		this._locale = locale;
		[this._language, this._country] = this._locale.split('-');
		this._dateFormatter = new Intl.DateTimeFormat(locale);
	}

	setLocaleCountry(country: string): void {
		if (this._language) {
			this.setLocaleParams(this._language, country);
		} else {
			this.setLocaleParams(
				this.getLocaleFromCookie()?.language,
				country,
			);
		}
	}

	getLocale(): string {
		return this._locale;
	}

	getLocaleCountry(): string {
		return this._country;
	}

	getLocaleLanguage(): string {
		return this._language;
	}

	setLocaleToCookie(locale: string): void {
		const expirationDate = new Date();
		expirationDate.setFullYear(expirationDate.getFullYear() + 1);

		setCookie('NEXT_LOCALE', locale, {
			path: '/',
			domain: process.env.NEXT_PUBLIC_TOPDOMAIN ?? '',
			expires: expirationDate,
			maxAge: 60 * 60 * 24 * 365,
		});
	}

	getLocaleFromCookie() {
		if (typeof window === 'undefined') return null;
		const cookie = getCookie('NEXT_LOCALE') as string;

		if (!cookie) return null;

		const cookieComponents = cookie.split('-');
		const language = cookieComponents[0];
		const country = cookieComponents[1];

		return {
			language,
			country,
		};
	}

	formatPrice(price: number, currency: string) {
		if (!currency || !price) return null;
		return new Intl.NumberFormat(this._locale, {
			style: 'currency',
			currencyDisplay: 'code',
			currency,
		}).format(price ?? 0);
	}

	formatDate(date: Date, options?: FormattingOptions): string {
		if (!date) return '';
		const d = this._dateFormatter.format(date).toString();

		return d;
	}

	formatStringDate(date: string, options?: FormattingOptions): string {
		if (!date) return '';
		const d = this._dateFormatter.format(new Date(date));

		return d;
	}

	formatLanguage(
		language: string,
		country: string,
	): { language: string | null; country: string | null } {
		const languageName = new Intl.DisplayNames(language, {
			type: 'language',
		});
		const regionName = new Intl.DisplayNames(language, {
			type: 'region',
		});
		const formattedLanguage = language ? languageName.of(language) : null;
		const isCountryCode = country?.length === 2;
		const formattedCountry = isCountryCode
			? regionName.of(country.toUpperCase())
			: country;

		return {
			language: formattedLanguage,
			country: formattedCountry,
		};
	}
	// write a formatting date like the previous one but which takes options as parameter
}
export const LocaleContext = React.createContext<Partial<Locale>>(
	new Locale(process.env.DEFAULT_LOCALE ?? 'en-DK'),
);
