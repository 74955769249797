import { useEffect, useState } from 'react';
import styles from './SignupForm.module.scss';
import {
	Button,
	Heading,
	Paragraph,
	Form,
	FormfieldSelect,
	FormfieldString,
	Spinner,
} from 'ui/components';
import { useForm, SubmitHandler, useFormState } from 'react-hook-form';
import countryList from 'react-select-country-list';
import classNames from 'classnames';

interface FormData {
	email: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	faxNumber: number;
	company: string;
	address: string;
	addressLine2: string;
	postalCode: string;
	city: string;
	state: string;
	country: string;
	shopRole: string;
	invoiceEmail: string;
	userCaptcha: string;
}

export interface SignupFormProps {
	contactTitle?: string;
	contactDescription?: string;
	shippingTitle?: string;
	shippingDescription?: string;
	shopRoleTitle?: string;
	shopRoleDescription?: string;
	invoiceTitle?: string;
	invoiceDescription?: string;
	onFormSubmit: (data: any) => void;
	dictionary?: { [key: string]: string };
	isLoading?: boolean;
	formIsSent?: boolean;
	reCaptchaApiKey?: string | undefined;
	userBrowserLocale?: string;
}

declare global {
	interface Window {
		grecaptcha: any;
	}
}

export const SignupForm: React.FC<SignupFormProps> = ({
	contactTitle,
	contactDescription,
	shippingTitle,
	shippingDescription,
	shopRoleTitle,
	shopRoleDescription,
	invoiceTitle,
	invoiceDescription,
	onFormSubmit,
	dictionary,
	isLoading,
	formIsSent,
	reCaptchaApiKey,
	userBrowserLocale,
}) => {
	// FORM HANDLING
	const [allErrors, setallErrors] = useState(0);
	const [errorSummary, setErrorSummary] = useState(0);
	const { register, handleSubmit, control, getValues } = useForm<FormData>({
		mode: 'onBlur',
	});

	/* eslint-disable @typescript-eslint/no-var-requires */
	const reactSimpleCaptcha = require('react-simple-captcha');
	const { LoadCanvasTemplate } = reactSimpleCaptcha;
	const { loadCaptchaEnginge } = reactSimpleCaptcha;
	const { validateCaptcha } = reactSimpleCaptcha;

	window.grecaptcha = window.grecaptcha || {};
	const [token, setToken] = useState<undefined | string>();
	useEffect(() => {
		if (userBrowserLocale === 'zh') {
			document.getElementById('reload_href').textContent =
				dictionary.formCaptchaReload;
			document.getElementById('canv').style.border = '1px solid black';
			loadCaptchaEnginge(6);
		} else {
			const script = document.createElement('script');
			script.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaApiKey}`;
			script.addEventListener('load', () => {
				window.grecaptcha.ready(() => {
					window.grecaptcha
						.execute(reCaptchaApiKey)
						.then((token: string) => setToken(token));
				});
			});
			document.body.appendChild(script);
		}
	}, []);

	const onSubmit: SubmitHandler<FormData> = (data) => {
		// If user locale is in China
		if (userBrowserLocale === 'zh') {
			const userCaptchaVal = getValues('userCaptcha');

			if (validateCaptcha(userCaptchaVal) === false) {
				alert(dictionary.formCaptchaInvalid);
				loadCaptchaEnginge(6);
				return;
			} else {
				onFormSubmit(data);
			}
		}

		if (userBrowserLocale !== 'zh') {
			const appendedData = {
				...data,
				ReCaptchaToken: token,
			};

			onFormSubmit(appendedData);
		}
	};

	const { errors } = useFormState({
		control,
	});

	const onError = (data) => {
		const totalErrors = Object.keys(data).length;
		setErrorSummary(totalErrors);
		setallErrors(data);
	};

	// Populate country selector with all countries
	const countrySelectOptions = countryList()
		.getData()
		.map((obj: { label: string; value: string; text: string }) => {
			obj.text = obj.label;

			return obj;
		});

	// NO NUMBERS REGEX
	const regexNoNumbers = new RegExp(/^([^0-9]*)$/);
	const textOnlyInputRules = {
		pattern: {
			value: regexNoNumbers,
			message: 'Numbers are not allowed here',
		},
	};
	const textOnlyInputKeyPressHandler = (
		event: React.KeyboardEvent<HTMLInputElement>,
	) => {
		const regex = regexNoNumbers;

		// preventDefault if the key pressed is a number
		if (!regex.test(event.key)) {
			event.preventDefault();
		}
	};

	// NO LETTERS REGEX
	const regexNoLetters = new RegExp(/^\d+$/);
	const numbersOnlyInputRules = {
		pattern: {
			value: regexNoLetters,
			message: 'Letters are not allowed here',
		},
	};
	const numbersOnlyInputKeyPressHandler = (
		event: React.KeyboardEvent<HTMLInputElement>,
	) => {
		const regex = regexNoLetters;

		if (!regex.test(event.key)) {
			event.preventDefault();
		}
	};

	// VALID PHONE NUMBER REGEX
	const regexValidPhoneNumber = new RegExp(/^[\d()\-+]+$/);
	const validPhoneNumberInputRules = {
		pattern: {
			value: regexValidPhoneNumber,
			message: 'Phone number is invalid',
		},
	};
	const validPhoneNumberInputKeyPressHandler = (
		event: React.KeyboardEvent<HTMLInputElement>,
	) => {
		const regex = regexValidPhoneNumber;
		if (!regex.test(event.key)) {
			event.preventDefault();
		}
	};
	// / FORM HANDLING

	return (
		<>
			{formIsSent ? (
				<div className={styles.SignupForm_success}>
					<Heading headingLevel="h2">
						{dictionary?.formThankYou}
					</Heading>
				</div>
			) : (
				<Form
					className={styles.ModalSignupForm}
					onSubmit={handleSubmit(onSubmit, onError)}
					errorSummary={errorSummary}
				>
					{/* Contact section */}
					{contactTitle && (
						<Heading headingLevel="h2">{contactTitle}</Heading>
					)}
					{contactDescription && (
						<Paragraph className="u-bottom-margin--md">
							<span
								dangerouslySetInnerHTML={{
									__html: contactDescription,
								}}
							></span>
						</Paragraph>
					)}

					<FormfieldString
						id="email"
						name="email"
						label={dictionary?.formEmail}
						type="email"
						register={register}
						className={classNames(
							styles.Formfield,
							'u-top-margin--md',
							'u-bottom-margin--md',
						)}
						layout="row"
						state={
							errors.email
								? {
										hasError: true,
										required: true,
								  }
								: { required: true }
						}
						errorMessage={
							errors.email?.message ||
							dictionary?.formErrorMessage
						}
					/>

					<FormfieldString
						id="firstName"
						name="firstName"
						label={dictionary?.formFirstName}
						type="text"
						register={register}
						className={classNames(
							styles.Formfield,
							'u-bottom-margin--md',
						)}
						layout="row"
						state={
							errors.firstName
								? {
										hasError: true,
										required: true,
								  }
								: { required: true }
						}
						errorMessage={errors.firstName?.message}
						rules={textOnlyInputRules}
						handleKeyPress={textOnlyInputKeyPressHandler}
					/>

					<FormfieldString
						id="lastName"
						name="lastName"
						label={dictionary?.formLastName}
						type="text"
						register={register}
						className={classNames(
							styles.Formfield,
							'u-bottom-margin--md',
						)}
						layout="row"
						state={
							errors.lastName
								? {
										hasError: true,
										required: true,
								  }
								: { required: true }
						}
						errorMessage={errors.lastName?.message}
						rules={textOnlyInputRules}
						handleKeyPress={textOnlyInputKeyPressHandler}
					/>

					<FormfieldString
						id="phoneNumber"
						name="phoneNumber"
						label={dictionary?.formPhoneNumber}
						type="text"
						register={register}
						className={classNames(
							styles.Formfield,
							'u-bottom-margin--md',
						)}
						layout="row"
						state={
							errors.phoneNumber
								? {
										hasError: true,
										required: true,
								  }
								: { required: true }
						}
						errorMessage={errors.phoneNumber?.message}
						rules={validPhoneNumberInputRules}
						handleKeyPress={validPhoneNumberInputKeyPressHandler}
					/>

					<FormfieldString
						id="faxNumber"
						name="faxNumber"
						label={dictionary?.formFaxNumber}
						type="number"
						register={register}
						className={classNames(
							styles.Formfield,
							'u-bottom-margin--md',
						)}
						layout="row"
						state={
							errors.faxNumber
								? {
										hasError: true,
										required: false,
								  }
								: { required: false }
						}
						errorMessage={errors.faxNumber?.message}
						rules={numbersOnlyInputRules}
						handleKeyPress={numbersOnlyInputKeyPressHandler}
					/>

					{/* Shipping section */}
					{shippingTitle && (
						<Heading
							className={classNames(
								'u-top-margin--xl u-bottom-margin--md',
							)}
							headingLevel="h2"
						>
							{shippingTitle}
						</Heading>
					)}
					{shippingDescription && (
						<Paragraph className="u-bottom-margin--md">
							{shippingDescription}
						</Paragraph>
					)}

					<FormfieldString
						id="company"
						name="company"
						label={dictionary?.formCompanyName}
						type="text"
						register={register}
						className={classNames(
							styles.Formfield,
							'u-top-margin--md u-bottom-margin--md',
						)}
						layout="row"
						state={
							errors.company
								? {
										hasError: true,
										required: true,
										hasTextNoWrap: true,
								  }
								: { required: true, hasTextNoWrap: true }
						}
						errorMessage={errors.company?.message}
					/>

					<FormfieldString
						id="address"
						name="address"
						label={dictionary?.formAddress}
						type="text"
						register={register}
						className={classNames(
							styles.Formfield,
							'u-bottom-margin--md',
						)}
						layout="row"
						state={
							errors.address
								? {
										hasError: true,
										required: true,
								  }
								: { required: true }
						}
						errorMessage={errors.address?.message}
					/>

					<FormfieldString
						id="addressLine2"
						name="addressLine2"
						label={dictionary?.formAddressLine2}
						type="text"
						register={register}
						className={classNames(
							styles.Formfield,
							'u-bottom-margin--md',
						)}
						layout="row"
						state={
							errors.addressLine2
								? {
										hasError: true,
										required: false,
								  }
								: { required: false }
						}
						errorMessage={errors.addressLine2?.message}
					/>

					<FormfieldString
						id="city"
						name="city"
						label={dictionary?.formCity}
						type="text"
						register={register}
						className={classNames(
							styles.Formfield,
							'u-bottom-margin--md',
						)}
						layout="row"
						state={
							errors.city
								? {
										hasError: true,
										required: true,
								  }
								: { required: true }
						}
						errorMessage={errors.city?.message}
					/>

					<FormfieldString
						id="postalCode"
						name="postalCode"
						label={dictionary?.formPostalCode}
						type="text"
						register={register}
						className={classNames(
							styles.Formfield,
							'u-bottom-margin--md',
						)}
						layout="row"
						state={
							errors.postalCode
								? {
										hasError: true,
										required: true,
								  }
								: { required: true }
						}
						errorMessage={errors.postalCode?.message}
					/>

					<FormfieldString
						id="state"
						name="state"
						label={dictionary?.formState}
						type="text"
						register={register}
						className={classNames(
							styles.Formfield,
							'u-bottom-margin--md',
						)}
						layout="row"
						state={
							errors.state
								? {
										hasError: true,
										required: false,
								  }
								: { required: false }
						}
						errorMessage={errors.state?.message}
					/>

					<FormfieldSelect
						id="country"
						name="country"
						label={dictionary?.formCountry}
						defaultValue=""
						options={[
							{
								value: '',
								text: dictionary?.formCountrySelect,
								hidden: true,
								disabled: true,
							},
							...countrySelectOptions,
						]}
						className={classNames(
							styles.Formfield,
							'u-bottom-margin--md',
						)}
						layout="row"
						register={register}
						state={
							errors.country
								? {
										hasError: true,
										required: true,
								  }
								: { required: true }
						}
						errorMessage={errors.country?.message}
					/>

					{/* Shop Role section */}
					{shopRoleTitle && (
						<Heading
							className={classNames('u-top-margin--xl')}
							headingLevel="h2"
						>
							{shopRoleTitle}
						</Heading>
					)}
					{shopRoleDescription && (
						<Paragraph className="u-bottom-margin--md">
							{shopRoleDescription}
						</Paragraph>
					)}

					<FormfieldSelect
						id="shopRole"
						name="shopRole"
						label={dictionary?.formShopRole}
						defaultValue=""
						options={[
							{
								value: '',
								text: dictionary?.formShopRoleSelect,
								hidden: true,
								disabled: true,
							},
							{
								value: 'businessPartner',
								text: dictionary?.formShopRoleBusinessPartner,
							},
							{
								value: 'reseller',
								text: dictionary?.formShopRoleReseller,
							},
						]}
						className={classNames(
							styles.Formfield,
							'u-bottom-margin--md',
						)}
						layout="row"
						register={register}
						state={
							errors.shopRole
								? {
										hasError: true,
										required: true,
								  }
								: { required: true }
						}
						errorMessage={
							errors.shopRole?.message ||
							dictionary?.formErrorMessage
						}
					/>

					{/* Invoicing section */}
					{invoiceTitle && (
						<Heading
							className={classNames('u-top-margin--xl')}
							headingLevel="h2"
						>
							{invoiceTitle}
						</Heading>
					)}
					{invoiceDescription && (
						<Paragraph className="u-bottom-margin--md">
							{invoiceDescription}
						</Paragraph>
					)}

					<FormfieldString
						id="invoiceEmail"
						name="invoiceEmail"
						label={dictionary?.formInvoiceEmailAddress}
						type="email"
						errorMessage={
							errors.invoiceEmail?.message ||
							dictionary?.formErrorMessage
						}
						className={classNames(
							styles.Formfield,
							'u-bottom-margin--md',
						)}
						layout="row"
						state={
							errors.invoiceEmail
								? {
										hasError: true,
										required: false,
								  }
								: { required: false }
						}
						register={register}
					/>

					{/* Captcha */}
					{userBrowserLocale === 'zh' ? (
						<>
							<LoadCanvasTemplate />
							<FormfieldString
								id="userCaptcha"
								name="userCaptcha"
								type="text"
								state={
									errors.userCaptcha
										? {
												hasError: true,
												required: true,
										  }
										: { required: true }
								}
								register={register}
								label={dictionary?.formCaptcha}
								layout="row"
							/>
						</>
					) : (
						<div
							className="g-recaptcha"
							data-sitekey={reCaptchaApiKey}
							data-size="invisible"
						/>
					)}

					{/* Submit */}
					<div
						className={classNames(
							styles.SignupForm_buttonWrapper,
							'u-top-margin--xl',
						)}
					>
						{isLoading ? (
							<div>
								<Spinner />
							</div>
						) : (
							<Button
								style="primary"
								type="submit"
								aria-label={dictionary?.formSend}
							>
								{dictionary?.formSend}
							</Button>
						)}
					</div>
				</Form>
			)}
		</>
	);
};
