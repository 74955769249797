import classNames from 'classnames';
import styles from './ArticleByline.module.scss';

export interface ArticleBylineProps {
	className?: string;
	fullName?: string;
	workTitle?: string;
}

export const ArticleByline: React.FC<ArticleBylineProps> = ({ className, fullName, workTitle }) => (
	<div className={classNames(styles.ArticleByline, className)}>
		<div className={styles.ArticleByline_name}>{fullName}</div>
		<div className={styles.ArticleByline_title}>{workTitle}</div>
	</div>
);
