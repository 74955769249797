import { ChangeEventHandler, KeyboardEventHandler } from 'react';
import classNames from 'classnames';
import styles from './SearchField.module.scss';
import { Button, ErrorMessage, Icon } from 'ui/components';
import { ReactComponent as SearchIcon } from 'ui/icons/icon-search.svg';

export interface SearchFieldProps {
	className?: string;
	id?: string;
	name?: string;
	placeholder?: string;
	state?: {
		required?: boolean;
		disabled?: boolean;
		hasError?: boolean;
		isHidden?: boolean;
	};
	errorMessage?: string;
	register?: any;
	handleChange?: ChangeEventHandler<HTMLInputElement>;
	handleKeyUp?: KeyboardEventHandler<HTMLInputElement>;
}

export const SearchField: React.FC<SearchFieldProps> = ({
	className,
	state,
	id,
	name,
	placeholder,
	errorMessage,
	register,
	handleChange,
	handleKeyUp,
}) => {
	const { required, disabled, hasError, isHidden } = state ?? {};

	if (isHidden) return null;
	return (
		<div
			className={classNames(
				styles.SearchField,
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
			)}
		>
			<div className={styles.SearchField_wrapper}>
				<input
					type="search"
					id={id}
					name={name}
					placeholder={placeholder}
					className={styles.SearchField_input}
					disabled={disabled}
					onChange={handleChange}
					onKeyUp={handleKeyUp}
					{...(register && { ...register(name, { required }) })}
				/>
				<Button
					type="submit"
					style="primary"
					disabled={disabled}
					className={styles.SearchField_button}
				>
					<Icon size="md" className={styles.SearchField_icon}>
						<SearchIcon />
					</Icon>
				</Button>
				{state?.hasError && errorMessage && (
					<ErrorMessage className={styles.SearchField_errorMessage}>
						{errorMessage}
					</ErrorMessage>
				)}
			</div>
		</div>
	);
};
