import { Locale } from 'application/adapters/context/LocaleContext';

export default function formatCartPrices(basket: Models.Basket, localeContext: Partial<Locale>, currencyCode: string) {
	if (!basket?.total) return basket;
	return ({
		...basket,
		formattedSubTotal: localeContext.formatPrice(
			basket.total.subTotal,
			currencyCode,
		),
		formattedHandlingFee: localeContext.formatPrice(
			basket.fees.handling,
			currencyCode,
		),
		formattedHazardousFee: localeContext.formatPrice(
			basket.fees.hazardous,
			currencyCode,
		),
		formattedFreight: localeContext.formatPrice(
			basket.fees.shipping,
			currencyCode,
		),
		formattedTaxTotal: localeContext.formatPrice(
			basket.total.taxTotal,
			currencyCode,
		),
		formattedOrderTotal: localeContext.formatPrice(
			basket.total.orderTotal,
			currencyCode,
		),
	});
}