import classNames from 'classnames';
import styles from './ServiceNavigation.module.scss';
import { Link } from 'ui/components';

export interface ServiceNavigationProps {
	className?: string;
	serviceNavigationItems?: Navigation.NestedNavigationItem[];
	isLoggedIn?: boolean;
	isNavActive?: boolean;
	isAvatarActive?: boolean;
	handleClick?: () => void;
}

export const ServiceNavigation: React.FC<ServiceNavigationProps> = ({
	className,
	serviceNavigationItems,
	isLoggedIn,
	isNavActive,
	isAvatarActive,
	handleClick,
}) => {
	if (!serviceNavigationItems) return null;
	return (
		<ul
			className={classNames(styles.ServiceNavigation, className, {
				[styles.isNavActive]: isNavActive,
				[styles.isAvatarActive]: isAvatarActive,
				[styles.isLoggedIn]: isLoggedIn,
			})}
		>
			{serviceNavigationItems.map(
				(item: Navigation.NestedNavigationItem, index) => (
					<li key={index} className={styles.ServiceNavigation_item}>
						<Link
							className={styles.ServiceNavigation_link}
							{...item}
							handleClick={handleClick}
						>
							{item.title || item.name}
						</Link>
					</li>
				),
			)}
		</ul>
	);
};
