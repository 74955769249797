import classNames from 'classnames';
import styles from './Link.module.scss';
import { Icon } from 'ui/components';
import { ReactComponent as ChevronLeft } from 'ui/icons/icon-chevron-left.svg';
import { ReactComponent as ChevronRight } from 'ui/icons/icon-chevron-right.svg';
import { NextLink } from './NextLink/NextLink';
import { MouseEventHandler } from 'react';

export interface LinkProps {
	children: React.ReactNode;
	style?: 'primary' | 'sm' | 'underline' | 'secondary';
	ariaLabel?: string;
	className?: string;
	url?: string;
	locale?: string;
	target?: string;
	asText?: boolean;
	chevron?: 'before' | 'after' | 'none';
	handleClick?: MouseEventHandler<HTMLAnchorElement> | (() => void) | void;
	role?: string;
	props?: any;
}

export const Link: React.FC<LinkProps> = ({
	children,
	style,
	ariaLabel,
	className,
	url,
	locale,
	target,
	asText,
	chevron,
	handleClick,
	props,
}) => {
	return (
		<>
			{asText ? (
				<p
					className={classNames(
						styles.Link,
						styles[`Link___${style}`],
						className,
					)}
				>
					{children}
				</p>
			) : (
				<NextLink href={url} locale={locale}>
					<a
						target={target ? target : '_self'}
						aria-label={ariaLabel}
						className={classNames(
							styles.Link,
							styles[`Link___${style}`],
							className,
							{ [styles.Link___chevron]: chevron },
						)}
						onClick={handleClick}
						{...props}
					>
						{chevron === 'before' && (
							<Icon className={styles.Link_chevron}>
								<ChevronLeft />
							</Icon>
						)}
						{children}
						{chevron === 'after' && (
							<Icon className={styles.Link_chevron}>
								<ChevronRight />
							</Icon>
						)}
					</a>
				</NextLink>
			)}
		</>
	);
};
