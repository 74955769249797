import classNames from 'classnames';
import { LinkButton } from 'ui/components';
import styles from './OrderHistoryItem.module.scss';

export interface OrderHistoryItemProps {
	className?: string;
	statusActive: boolean;
	statusText: string;
	orderId: number;
	orderDate: Date;
	estDeliveryDate: Date;
	estDeliveryDateLabel: string;
	numberOfItems: number;
	totalPrice: number;
	attPerson: string;
	handleViewInvoice?: () => void;
	url: string;
	locale: string;
	dictionary: { [key: string]: string };
	formattedOrderDate: string;
	formattedEstDeliveryDate: string;
	formattedTotalPrice: string;
}

export const OrderHistoryItem: React.FC<OrderHistoryItemProps> = ({
	className,
	locale,
	statusActive,
	statusText,
	orderId,
	orderDate,
	estDeliveryDate,
	numberOfItems,
	totalPrice,
	attPerson,
	handleViewInvoice,
	url,
	dictionary,
	formattedOrderDate,
	formattedEstDeliveryDate,
	formattedTotalPrice,
}) => {
	const {
		statusLabel,
		orderIdLabel,
		orderDateLabel,
		estDeliveryDateLabel,
		numberOfItemsLabel,
		totalPriceLabel,
		attPersonLabel,
		invoiceLabel,
		viewOrderLabel,
	} = dictionary;

	return (
		<div className={classNames(styles.OrderHistoryItem, className)}>
			<ul className={styles.OrderHistoryItem_column}>
				<li className={styles.OrderHistoryItem_column_item}>
					<strong>{statusLabel}:</strong>{' '}
					<span
						className={classNames(
							styles.OrderHistoryItem_status,
							statusActive
								? styles.OrderHistoryItem_status___isActive
								: null,
						)}
					>
						{statusText}
					</span>
				</li>
				<li className={styles.OrderHistoryItem_column_item}>
					<strong>{orderIdLabel}:</strong> #{orderId}
				</li>
				<li className={styles.OrderHistoryItem_column_item}>
					<strong>{orderDateLabel}:</strong> {formattedOrderDate}
				</li>
			</ul>
			<ul className={styles.OrderHistoryItem_column}>
				{/* new Date('1/1/1980') is an arbitrary date to check that the date we have is not just a minimum date */}
				{estDeliveryDate > new Date('1/1/1980') && (
					<li className={styles.OrderHistoryItem_column_item}>
						<strong>{estDeliveryDateLabel}: </strong>
						{formattedEstDeliveryDate}
					</li>
				)}

				<li className={styles.OrderHistoryItem_column_item}>
					<strong>{numberOfItemsLabel}:</strong> {numberOfItems}
				</li>
				<li className={styles.OrderHistoryItem_column_item}>
					<strong>{totalPriceLabel}: </strong>
					{formattedTotalPrice}
				</li>
			</ul>
			<ul className={styles.OrderHistoryItem_column}>
				{attPerson && (
					<li className={styles.OrderHistoryItem_column_item}>
						<strong>{attPersonLabel}:</strong> {attPerson}
					</li>
				)}
			</ul>
			<div className={styles.OrderHistoryItem_buttons}>
				{/* Invoice button to be enabled in a future iteration */}
				{/* {invoiceLabel && handleViewInvoice && (
					<LinkButton
						className={styles.OrderHistoryItem_button}
						style={'secondary'}
						onClick={handleViewInvoice}
						url={''}
					>
						{invoiceLabel}
					</LinkButton>
				)} */}
				<LinkButton
					className={styles.OrderHistoryItem_button}
					style={'secondary'}
					url={url}
				>
					{viewOrderLabel}
				</LinkButton>
			</div>
		</div>
	);
};
