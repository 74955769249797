import classNames from 'classnames';
import { useState, useEffect, useRef } from 'react';
import styles from './Picture.module.scss';
import { GetImageUrl, GetSrcSetString } from 'helpers/getImageUrl';
import config from 'helpers/imageSizes';
import { inView } from 'helpers/inView';

export interface PictureProps extends Umbraco.Image {
	sizes?: string;
	lazyload?: boolean;
	objectFitFallback?: boolean;
	isCover?: boolean;
	aspectRatio?: number;
	className?: string;
}

export const Picture: React.FC<PictureProps> = ({
	isCover = true,
	sizes = '100vw',
	url,
	lazyload = true,
	objectFitFallback = true,
	properties,
	focalPoint,
	aspectRatio,
	className,
}) => {
	const { thumbnailSize, fallbackSize } = config.images;

	const thumbnailImageUrl = GetImageUrl({ url, width: thumbnailSize });
	const thumbnailImageUrlWebp = GetImageUrl({
		url,
		width: thumbnailSize,
		format: 'webp',
	});

	const [src, setSrc] = useState(thumbnailImageUrl);
	const [imgSrcSet, setImgSrcSet] = useState(thumbnailImageUrl);
	const [sourceSrcSet, setSourceSrcSet] = useState(thumbnailImageUrlWebp);
	const [isFallback, setIsFallback] = useState(false);

	const pictureRef = useRef(null);

	useEffect(() => {
		function reveal() {
			const srcSetString = GetSrcSetString({
				url,
				focalPoint,
				aspectRatio,
			});
			const srcSetStringWebp = GetSrcSetString({
				url,
				focalPoint,
				format: 'webp',
				aspectRatio,
			});
			const srcString = GetImageUrl({ url, width: fallbackSize });

			setImgSrcSet(srcSetString);
			setSourceSrcSet(srcSetStringWebp);
			setSrc(srcString);
		}

		function objectFit() {
			// If the useragent doesn't support ObjectFit (IE), we need to
			// add fallback image to the picture element
			const supportObjectFit =
				'objectFit' in document.documentElement.style;

			if (!supportObjectFit) setIsFallback(true);
		}

		if (url) {
			if (objectFitFallback) objectFit();
			if (lazyload) inView({ elm: pictureRef.current, callback: reveal });
			if (!lazyload) reveal();
		}
	}, [
		url,
		lazyload,
		objectFitFallback,
		aspectRatio,
		fallbackSize,
		focalPoint,
	]);

	return (
		<picture
			ref={pictureRef}
			className={classNames(
				styles.Picture,
				{ [styles.Picture___cover]: isCover },
				{ [styles.isFallback]: isFallback },
				className,
			)}
			style={isFallback ? { backgroundImage: `url(${src})` } : {}}
		>
			{!isFallback && (
				<>
					{sourceSrcSet && (
						<source
							sizes={sizes}
							type="image/webp"
							srcSet={`${sourceSrcSet ? sourceSrcSet : null} 100w`}
						/>
					)}

					{(src || imgSrcSet) && (
						<img
							width={100}
							src={src ? src : '#'}
							alt={
								properties?.altText
									? properties?.altText
									: 'image'
							}
							className={styles.Picture_asset}
							srcSet={`${imgSrcSet ? imgSrcSet : null} 100w`}
							sizes={sizes}
						/>
					)}
				</>
			)}
		</picture>
	);
};
