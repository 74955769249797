import classNames from 'classnames';
import styles from './Header.module.scss';
import {
	Link,
	Logo,
	SkipToMain,
	Navigation,
	ServiceNavigation,
	ShoppingCart,
	Container,
	Grid,
	SearchField,
	Form,
} from 'ui/components';
import { useContext, useEffect, useState } from 'react';
import { Avatar } from 'ui/components/3-organisms/Avatar';
import { ReactComponent as AvatarIcon } from 'ui/icons/icon-avatar.svg';
import { useForm } from 'react-hook-form';
import { MouseEventHandler } from 'react';
import { LoginStatus } from 'ui/components/3-organisms/LoginStatus';
import { useSelector } from 'react-redux';
import { currency, subTotal } from 'application/adapters/store/slices/cartSlice';
import { LocaleContext } from 'application/adapters/context/LocaleContext';

export interface HeaderProps {
	className?: string;
	navHeading?: string;
	navigationItems?: Navigation.NestedNavigationItem[];
	serviceNavigationItems?: Navigation.NestedNavigationItem[];
	shoppingCartText?: string;
	placeholder?: string;
	logoClicked: MouseEventHandler<HTMLAnchorElement>;
	href: string;
	handleSearchSubmit?: (query: string) => void;
	onSignIn: () => void;
	onSignOut: () => void;
	onNavigateToUserProfile?: () => void;
	profile?: Models.User;
	accessToken?: any;
	handleGoToCheckout: any;
	loginStatusDictionary?: { [key: string]: string };
	avatarDictionary?: { [key: string]: string };
	addresses?: Models.Address[];
	bpId?: string;
	links?: Models.NavigationItem[];
	dictionary?: { [key: string]: string };
	enableViewCart?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
	className,
	navHeading,
	navigationItems,
	serviceNavigationItems,
	shoppingCartText,
	placeholder,
	logoClicked,
	href,
	profile,
	handleSearchSubmit,
	onSignIn,
	onSignOut,
	onNavigateToUserProfile,
	accessToken,
	handleGoToCheckout,
	loginStatusDictionary,
	avatarDictionary,
	addresses,
	bpId,
	links,
	dictionary,
	enableViewCart = true,
}) => {
	const cartSubTotal = useSelector(subTotal);
	const [isNavActive, setIsNavActive] = useState(false);
	const [isAvatarActive, setIsAvatarActive] = useState(false);
	const currencyCode = useSelector(currency);
	const localeContext = useContext(LocaleContext);

	serviceNavigationItems = serviceNavigationItems || [];
	useEffect(() => {
		if (isNavActive || isAvatarActive) {
			document.querySelector('body').style.overflow = 'hidden';
		} else {
			document.querySelector('body').style.overflow = '';
		}
	}, [isNavActive, isAvatarActive]); //todo check hidden header for mobile

	type FormData = {
		query: string;
		headerSearch: string;
	};

	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<FormData>();

	const onSubmit = async ({ headerSearch }) => {
		handleSearchSubmit(headerSearch);
	};

	return (
		// TODO - Service navigation mediaqueries active
		<header className={classNames(styles.Header, className, placeholder)}>
			<SkipToMain
				link="#main"
				title="Click to skip navigations and go directly to content section"
			>
				{dictionary?.skipToContent}
			</SkipToMain>
			<Container width="Standard" className={styles.Header_topContainer}>
				<Grid className={styles.Header_top}>
					<section className={styles.Header_identity}>
						<Logo href={href} logoClicked={logoClicked} />
					</section>
					<div className={styles.Header_service}>
						<ServiceNavigation
							serviceNavigationItems={serviceNavigationItems}
						/>
						<Avatar
							onSignIn={onSignIn}
							onSignOut={() => {
								setIsAvatarActive(false);
								onSignOut();
							}}
							profile={profile}
							isAvatarActive={isAvatarActive}
							onSetAvatarActive={() =>
								setIsAvatarActive(!isAvatarActive)
							}
							dictionary={avatarDictionary}
						></Avatar>
					</div>
				</Grid>
				{
					//todo get all the info that's static here
				}
				{isAvatarActive && profile && (
					<LoginStatus
						// accounts={accounts}
						onSignIn={onSignIn}
						onSignOut={() => {
							setIsAvatarActive(false);
							onSignOut();
						}}
						onNavigateToUserProfile={() => {
							setIsAvatarActive(!isAvatarActive);
							onNavigateToUserProfile();
						}}
						handleLinkClick={() =>
							setIsAvatarActive(!isAvatarActive)
						}
						profile={profile}
						dictionary={loginStatusDictionary}
						addresses={addresses}
						bpId={bpId}
						missingCustomerProfileMessage={
							loginStatusDictionary.missingAddressesUrl &&
							loginStatusDictionary.missingCustomerProfileMessage && (
								<Link
									url={
										loginStatusDictionary.missingAddressesUrl
									}
									handleClick={() => {
										setIsAvatarActive(false);
									}}
								>
									{
										loginStatusDictionary.missingCustomerProfileMessage
									}
								</Link>
							)
						}
						links={links}
					/>
				)}
			</Container>
			<div
				className={classNames(styles.Header_overlay, {
					[styles.isActive]: isAvatarActive,
				})}
				onClick={() => setIsAvatarActive(!isAvatarActive)}
			></div>
			<Grid
				nogutter={true}
				center={true}
				className={styles.Header_bottom}
			>
				<Container
					width="Standard"
					className={styles.Header_bottomContainer}
				>
					{navigationItems && (
						<div
							className={classNames(styles.Header_navigation, {
								[styles.isActive]: isAvatarActive,
							})}
						>
							<div
								className={classNames(styles.Header_navToggle, {
									[styles.isActive]:
										isNavActive || isAvatarActive,
								})}
								onClick={() => {
									setIsNavActive(!isNavActive);
									if (isAvatarActive) {
										setIsNavActive(false);
										setIsAvatarActive(false);
									}
								}}
							>
								<span className={styles.Header_iconBar}></span>
								<span className={styles.Header_iconBar}></span>
								<span className={styles.Header_iconBar}></span>
							</div>

							<AvatarIcon
								className={styles.Header_avatarIcon}
								onClick={() =>
									setIsAvatarActive(!isAvatarActive)
								}
							/>

							{isNavActive && (
								<div className={styles.Header_mobileNav}>
									<Navigation
										className={
											styles.Header_mobileGlobalNav
										}
										heading={navHeading}
										navigationItems={navigationItems}
										handleMobileNavLinkClick={() => {
											setIsNavActive(false);
										}}
									/>
									<ServiceNavigation
										serviceNavigationItems={
											serviceNavigationItems
										}
										isNavActive={isNavActive}
										handleClick={() => {
											setIsNavActive(!isNavActive);
										}}
									/>
								</div>
							)}

							{isAvatarActive && !profile && (
								<div className={styles.Header_mobileAvatar}>
									<ServiceNavigation
										serviceNavigationItems={
											serviceNavigationItems
										}
										isAvatarActive={isAvatarActive}
										isLoggedIn={!!profile}
										handleClick={() => {
											setIsAvatarActive(!isAvatarActive);
										}}
									/>
									<Avatar
										// isLoggedIn={isLoggedIn}
										// accounts={accounts}
										onSignIn={onSignIn}
										onSignOut={onSignOut}
										profile={profile}
										isAvatarActive={isAvatarActive}
										onSetAvatarActive={() =>
											setIsAvatarActive(!isAvatarActive)
										}
										dictionary={avatarDictionary}
									/>
								</div>
							)}

							<div
								className={styles.Header_nav}
								data-ref="header__navPanel"
							>
								<Grid nogutter={true} wrap={true}>
									<Navigation
										heading={navHeading}
										navigationItems={navigationItems}
										style="primary"
									/>
								</Grid>
							</div>
						</div>
					)}

					<Logo
						href={href}
						logoClicked={logoClicked}
						className={styles.Header_identity___mobile}
					/>
					{enableViewCart && handleGoToCheckout && (
						<div className={styles.Header_cart}>
							<ShoppingCart
								bpId={bpId}
								shoppingCartText={shoppingCartText}
								accessToken={accessToken}
								handleGoToCheckout={handleGoToCheckout}
								formattedSum={localeContext.formatPrice(
									cartSubTotal,
									currencyCode,
								)}
							/>
						</div>
					)}
				</Container>
			</Grid>

			<Container width="Standard">
				<Form
					onSubmit={handleSubmit(onSubmit)}
					className="u-bottom-margin--lg"
				>
					<SearchField
						placeholder={placeholder}
						name="headerSearch"
						state={
							errors.query
								? { hasError: true, required: true }
								: { required: true }
						}
						register={register}
					/>
				</Form>
			</Container>
		</header>
	);
};
