import classNames from 'classnames';

import styles from './Container.module.scss';

export type ContainerWidth =
	| ''
	| 'Small'
	| 'Standard'
	| 'Full width'
	| 'Slim'
	| 'Skinny';

export interface ContainerProps {
	width: ContainerWidth;
	children: React.ReactNode;
	className?: string;
	textAlign?: '' | 'Left' | 'Right' | 'Center';
}

export const Container: React.FC<ContainerProps> = ({
	width = 'Standard',
	children,
	className,
	textAlign,
}) => {
	return (
		<div
			className={classNames(
				styles.Container,
				{ [styles.Container___standard]: width === 'Standard' },
				{ [styles.Container___small]: width === 'Small' },
				{ [styles.Container___slim]: width === 'Slim' },
				{ [styles.Container___skinny]: width === 'Skinny' },
				{ [styles[`Container___text${textAlign}`]]: textAlign },
				className,
			)}
		>
			{children}
		</div>
	);
};
