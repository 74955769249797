import classNames from 'classnames';
import styles from './HeroCard.module.scss';
import { Heading } from 'ui/components';

export interface HeroCardProps {
	className?: string;
	cardLinkUrl?: string;
	cardActionLinkText?: string;
	kicker?: string;
	heading?: string;
	style?: 'min';
}

export const HeroCard: React.FC<HeroCardProps> = ({ heading, cardLinkUrl, style, kicker, className }) => {
	return (
		<header className={classNames(styles.HeroCard, styles[`HeroCard___${style}`], className)}>
			<a className={styles.HeroCard_link} href={cardLinkUrl}>
				<div className={styles.HeroCard_textbox}>
					<div className={styles.HeroCard_kicker}>{kicker}</div>
					<Heading headingLevel="h1" style="xl" className="HeroCard_heading">
						{heading}
					</Heading>
				</div>
			</a>
		</header>
	);
};
