import React from 'react';
import { PageContentResponse } from 'application/repositories/pageContentRepository';

export class Site {
	private _host: string;
	private _culture: string;
	private _siteSettings?: PageContentResponse['root']['properties']['siteSettings'];
	private _root: PageContentResponse['root'];

	public constructor(
		root: PageContentResponse['root'],
		host: string,
		culture: string,
		siteSettings?: PageContentResponse['root']['properties']['siteSettings'],
	) {
		this._host = host;
		this._culture = culture;
		this._root = root;
		this._siteSettings = siteSettings;
	}

	get culture(): string {
		return this._culture;
	}

	get host(): string {
		return this._host;
	}

	get siteSettings(): PageContentResponse['root']['properties']['siteSettings'] {
		return this._siteSettings;
	}

	get root(): PageContentResponse['root'] {
		return this._root;
	}

	public getSettings(key: 'googleTagManager'): Umbraco.GoogleTagManager['content']['properties'];
	public getSettings(key: 'mapsSettings'): Umbraco.MapSettings['content']['properties'];
	public getSettings(
		key: 'googleTagManager' | 'mapsSettings',
	): Umbraco.GoogleTagManager['content']['properties'] | Umbraco.MapSettings['content']['properties'] {
		if (!this.siteSettings) return null;
		return this.siteSettings.find((e) => e.documentType === key)?.content?.properties;
	}

	public getNavigationItems(currentPageId?: number): Models.NavigationItem[] {
		return this._root?.header?.reduce((filtered, headerItem) => {
			headerItem.properties.pageSettings.forEach((pageSetting) => {
				if (pageSetting.documentType === 'navigation' && !pageSetting.content.properties.umbracoNaviHide) {
					const { navigationTitle } = pageSetting?.content?.properties ?? {};

					filtered.push({
						title: navigationTitle ? navigationTitle : headerItem.name,
						url: headerItem?.url,
						state: currentPageId === headerItem?.id ? 'isActive' : '',
					});
				}
			});

			return filtered;
		}, []);
	}
}

export const SiteContext = React.createContext<Partial<Site>>(new Site(null, 'localhost', 'en', []));
