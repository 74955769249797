import classNames from 'classnames';
import styles from './ArticleText.module.scss';
import { Container } from 'ui/components';

export interface ArticleTextProps {
	content?: string;
	className?: string;
}

export const ArticleText: React.FC<ArticleTextProps> = ({ className, content }) => (
	<Container width="Slim">
		<article className={className}>
			<div
				className={classNames(styles.ArticleText, className)}
				dangerouslySetInnerHTML={{ __html: content }}
			></div>
		</article>
	</Container>
);