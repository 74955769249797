import styles from './Pagination.module.scss';
import { Icon, Link } from 'ui/components';
import { ReactComponent as ChevronLeft } from 'ui/icons/icon-chevron-left.svg';
import { ReactComponent as ChevronRight } from 'ui/icons/icon-chevron-right.svg';
import classNames from 'classnames';

export interface PaginationProps {
	controlFirst?: PaginationControlProps;
	controlPrev?: PaginationControlProps;
	controlNext?: PaginationControlProps;
	controlLast?: PaginationControlProps;
	paginationItems?: Array<PaginationItemProps>;
}

export interface PaginationItemProps {
	label?: string;
	link?: string;
	active?: boolean;
}

export interface PaginationControlProps {
	direction?: 'next' | 'prev';
	label?: string;
	link?: string;
	title?: string;
	ariaLabel?: string;
}

export const Pagination: React.FC<PaginationProps> = ({
	controlFirst,
	controlPrev,
	controlNext,
	controlLast,
	paginationItems,
}) => (
	<ol className={styles.Pagination}>
		{controlFirst && (
			<PaginationControl {...controlFirst}>
				<Icon>
					<ChevronLeft />
				</Icon>
			</PaginationControl>
		)}

		{controlPrev && (
			<PaginationControl {...controlPrev}>
				<Icon>
					<ChevronLeft />
				</Icon>
			</PaginationControl>
		)}

		{paginationItems.map((paginationItem: PaginationItemProps, index) => {
			if (
				paginationItems.length > 5 &&
				index > 2 &&
				index < paginationItems.length - 2
			) {
				return null;
			} else if (
				paginationItems.length > 5 &&
				index === paginationItems.length - 2
			) {
				return (
					<li
						key={index}
						className={classNames(styles.Pagination_item)}
					>
						<span className={styles.Pagination_link}>...</span>
					</li>
				);
			}

			const label = paginationItem.label || (index + 1).toString();

			return (
				<li
					key={index}
					className={classNames(styles.Pagination_item, {
						[styles.Pagination_item___active]:
							paginationItem.active,
					})}
				>
					<Link
						className={styles.Pagination_link}
						url={paginationItem.link}
					>
						{label}
					</Link>
				</li>
			);
		})}

		{controlNext && (
			<PaginationControl {...controlNext}>
				<Icon>
					<ChevronRight />
				</Icon>
			</PaginationControl>
		)}

		{controlLast && (
			<PaginationControl {...controlLast}>
				<Icon>
					<ChevronRight />
				</Icon>
			</PaginationControl>
		)}
	</ol>
);

const PaginationControl: React.FC<PaginationControlProps> = ({
	children,
	direction,
	label = '',
	link,
	title,
	ariaLabel,
}) => {
	const hasLabelStyle =
		label?.length > 0
			? classNames(
					styles.Pagination_item,
					styles.Pagination_item___gutter,
					styles.Pagination_item___mobile,
			  )
			: styles.Pagination_item;

	return (
		<li className={hasLabelStyle}>
			<Link
				className={classNames(
					styles.Pagination_link,
					styles.Pagination_link___next,
				)}
				url={link ?? ''}
				aria-label={ariaLabel}
			>
				{direction === 'prev' && label}
				{label && (
					<span className={styles.Pagination_chevron}>
						{children}
					</span>
				)}
				{direction === 'next' && label}
			</Link>
		</li>
	);
};
