import { getContactForm, postFormToUrl } from 'application/adapters/cms/formAdapter';
import { getSignupForm } from 'application/adapters/cms/formAdapter';

export interface GetFormInterface {
	host: string | string[];
	accessToken?: string;
	locale: string;
}

export interface FormRepositoryInterface {
	getForm: (formName: string) => Form.ContactForm;
}

//not used currently
export const FormRepositoryFactory = (
	data?: Form.ContactForm,
): FormRepositoryInterface => {
	if (!data) return;
	return {
		getForm(formName = 'modalHelpForm'): Form.ContactForm {
			const form = data;

			return form;
		},
	};
};

export const postForm: (
	url: string,
	data: any,
	accessToken: any,
	locale: string,
	host: string,
) => Promise<any> = async (url, data, accessToken, locale, host) => {
	const product = postFormToUrl(url, data, accessToken, locale, host);

	return product;
};

export const getContactFormData = async ({
	host,
	accessToken,
	locale,
}: GetFormInterface): Promise<Form.ContactForm> => {
	const formResponse = await getContactForm({
		host,
		accessToken,
		locale: locale,
		method: 'POST',
	});

	return formResponse;
};

export const getSignupFormData = async ({
	host,
	accessToken,
	locale,
}: GetFormInterface): Promise<Form.ContactForm> => {
	const formResponse = await getSignupForm({
		host,
		accessToken,
		locale: locale,
		method: 'POST',
	});

	return formResponse;
};
