import type { AppProps as NextAppProps } from 'next/app';
import { Preview } from 'ui/components/4-habitats/Preview';
import { Provider } from 'react-redux';
import { PersistGateSSR } from 'application/contexts/PersistGateSSR';
import { store } from '../application/adapters/store/store';
import { persistStore } from 'redux-persist';
import { SiteContext, Site } from 'application/adapters/context/SiteContext';
import {
	DictionaryContext,
	Dictionary,
} from 'application/adapters/context/Dictionary';
import {
	PageContext,
	Page as PageContent,
} from 'application/adapters/context/PageContext';
import { NavigationContextProvider } from 'application/adapters/context/NavigationContext';
import 'ui/styles/globals.scss';
import { AuthenticationContextProvider } from 'application/contexts/AuthenticationContext';
import { FormContextProvider } from 'application/adapters/context/FormContext';
import {
	Locale,
	LocaleContext,
} from 'application/adapters/context/LocaleContext';
import { getCookie } from 'cookies-next';

type AppProps<P = any> = {
	pageProps: P;
} & Omit<NextAppProps<P>, 'pageProps'>;

const App: React.FC<NextAppProps> = ({
	Component,
	pageProps,
	router,
}: AppProps) => {
	const { host, content, navigationData, session, formData } =
		pageProps ?? {};
	const { dictionary, root } = content ?? {};
	const cookieLocale = getCookie('NEXT_LOCALE');
	const locale = (cookieLocale as string) ?? router.locale ?? 'en-DK';
	const { siteSettings } = root?.properties ?? {};
	const persistor = persistStore(store);
	const localeProvider = new Locale(locale);
	const dictionaryProvider = new Dictionary(
		dictionary,
		localeProvider.getLocaleLanguage(),
	);

	const site = new Site(root, host, locale, siteSettings);
	const pageContent: PageContent = new PageContent(content);

	return (
		<Provider store={store}>
			<PersistGateSSR loading={null} persistor={persistor}>
				<AuthenticationContextProvider session={session}>
					<SiteContext.Provider value={site}>
						<DictionaryContext.Provider value={dictionaryProvider}>
							<LocaleContext.Provider value={localeProvider}>
								<PageContext.Provider value={pageContent}>
									<NavigationContextProvider
										navigationData={navigationData}
									>
										<FormContextProvider
											formData={formData}
										>
											<Preview content={content}>
												<Component
													{...content}
													{...pageProps}
												/>
											</Preview>
										</FormContextProvider>
									</NavigationContextProvider>
								</PageContext.Provider>
							</LocaleContext.Provider>
						</DictionaryContext.Provider>
					</SiteContext.Provider>
				</AuthenticationContextProvider>
			</PersistGateSSR>
		</Provider>
	);
};

export default App;
