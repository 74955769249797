import axios from 'axios';
import { ECOMMERCE_API_URL } from './_constants';

export const queryGetCategory: any = (parentId, accessToken, culture, bpId) => {
	const method = 'GET';
	const headers = {
		Authorization: `Bearer ${accessToken}`,
	};

	const path = `getcategories?locale=${culture}&bpId=${bpId}${parentId ? `&parent=${parentId}` : ''}`;
	// const ecommerceUrl = getEcommerceUrl();
	// // TODO: Delete when environment variables work in dev again
	// const ecommerceUrl =
	// 	'https://app-strub2b-cms-dev-001.azurewebsites.net/umbraco/api/search/';
	const endpointUrl = `${ECOMMERCE_API_URL}${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	}).then((res) => {
		const { data } = res;
		return data;
	});
};
