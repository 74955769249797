import { Button, Form, FormfieldString } from 'ui/components';
import { useForm, SubmitHandler, useFormState } from 'react-hook-form';
import styles from './QuickOrder.module.scss';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

interface FormData {
	sku: string;
	quantity: number;
}

export interface QuickOrderProps {
	productUpdated: (product: { sku: string }, quantity: number) => void;
	quickOrderError?: string;
	dictionary: { [key: string]: string };
}

export const QuickOrder: React.FC<QuickOrderProps> = ({
	productUpdated,
	quickOrderError,
	dictionary,
}) => {
	const { register, handleSubmit, control, setError, clearErrors } =
		useForm<FormData>();
	const { errors } = useFormState({
		control,
	});
	const onSubmit: SubmitHandler<FormData> = (data) => {
		productUpdated(data, data.quantity);
	};

	const [errorMessageOverride, setErrorMessageOverride] =
		useState<string>(null);

	useEffect(() => {
		if (quickOrderError !== "") {
			setError(
				'sku',
				{
					type: 'custom',
					message: quickOrderError,
				},
				{ shouldFocus: true },
			);
			setErrorMessageOverride(quickOrderError);
		}
		return () => {
			clearErrors();
			setErrorMessageOverride(null);
		};
	}, [clearErrors, dictionary, quickOrderError, setError]);

	return (
		<Form className={styles.QuickOrder} onSubmit={handleSubmit(onSubmit)}>
			<FormfieldString
				register={register}
				id="sku"
				name="sku"
				type="text"
				placeholder={dictionary.quickOrderTypeNumberHere}
				label={dictionary.quickOrderItemNumber}
				labelStyle="small"
				className={styles.QuickOrder_inputItemNumber}
				state={
					errors.sku
						? {
								hasError: true,
								required: true,
						  }
						: { required: true }
				}
				errorMessage={
					errorMessageOverride || dictionary.formErrorMessage
				}
			/>
			<FormfieldString
				register={register}
				id="quantity"
				name="quantity"
				type="text"
				label={dictionary.quickOrderQuantity}
				labelStyle="small"
				className={styles.QuickOrder_inputQuantity}
				state={
					errors.quantity
						? {
								hasError: true,
								required: true,
						  }
						: { required: true }
				}
			/>

			<div
				className={classNames(
					styles.QuickOrder_inputButtonWrapper,
					(errors.sku || errors.quantity) &&
						styles.QuickOrder_inputButtonWrapper___hasError,
				)}
			>
				<Button
					style="primary"
					type="submit"
					aria-label={dictionary.shoppingCartAddToCart}
					className={styles.QuickOrder_inputButton}
				>
					{dictionary.shoppingCartAddToCart}
				</Button>
			</div>
		</Form>
	);
};
