import classNames from 'classnames';
import styles from './SocialLinks.module.scss';
import { Link } from 'ui/components';
import { LinkProps } from 'ui/components/1-atoms/Action/Link/Link';
import { Icon } from 'ui/components';
import { ReactComponent as LinkedInIcon } from 'ui/icons/icon-linkedin.svg';
import { ReactComponent as FacebookIcon } from 'ui/icons/icon-facebook.svg';
import { ReactComponent as YouTubeIcon } from 'ui/icons/icon-youtube.svg';

export interface SocialLinksProps {
	className?: string;
	SocialLinksItems?: Umbraco.SocialLink[];
	iconType?: string;
}

const icon = (iconType: string) => {
	switch (iconType) {
		case 'LinkedIn': {
			return <LinkedInIcon />;
		}
		case 'Facebook': {
			return <FacebookIcon />;
		}
		case 'YouTube': {
			return <YouTubeIcon />;
		}
		default: {
			return <YouTubeIcon />;
		}
	}
};

export const SocialLinks: React.FC<SocialLinksProps> = ({
	className,
	SocialLinksItems,
}) => (
	<ul className={classNames(styles.SocialLinks, className)}>
		{SocialLinksItems?.map((SocialLinksItem: Umbraco.SocialLink, index) => {
			const { socialLinkUrl, socialLinkType } =
				SocialLinksItem?.content?.properties ?? {};
			return (
				<li key={index} className={styles.SocialLinks_item}>
					<Link
						className={styles.SocialLinks_link}
						url={socialLinkUrl?.url ?? ''}
						ariaLabel={socialLinkType}
					>
						<Icon size="lg">{icon(socialLinkType)}</Icon>
					</Link>
				</li>
			);
		})}
	</ul>
);
