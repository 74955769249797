import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { FormEventHandler, useContext } from 'react';
import { Notification } from 'ui/components';

export interface FormProps {
	className?: string;
	children: React.ReactNode;
	errorSummary?: number;
	errorSummaryPosition?: 'top' | 'bottom';
	onSubmit?: FormEventHandler<HTMLFormElement>;
}

export const Form: React.FC<FormProps> = ({
	className,
	children,
	errorSummary,
	errorSummaryPosition = 'top',
	onSubmit,
}) => {
	const dictionary = useContext(DictionaryContext);
	let errorMessage: React.ReactNode;
	if (errorSummary > 0) {
		errorMessage = (
			<Notification hidden>
				<p>
					{dictionary.getValue(
						'Form.SubmissionFailedErrors',
						null,
						'Submission failed. The number of errors: ',
					)}{' '}
					{errorSummary}.
				</p>
			</Notification>
		);
	}
	return (
		<form className={className} onSubmit={onSubmit}>
			{errorSummaryPosition === 'top' && errorMessage && (
				<div className="u-bottom-margin--md">{errorMessage}</div>
			)}
			{children}
			{errorSummaryPosition === 'bottom' && (
				<div className="u-top-margin--md">{errorMessage}</div>
			)}
		</form>
	);
};
