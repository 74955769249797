import styles from './FeaturedProducts.module.scss';
import classNames from 'classnames';
import { useState } from 'react';
import { ProductCard, Heading, Button, Icon, Container } from 'ui/components';
import { ReactComponent as PlusIcon } from 'ui/icons/icon-plus.svg';

export interface FeaturedProductsProps {
	className?: string;
	columns: number;
	loadMoreText: string;
	numberOfProducts: number;
	products: Models.Product[];
	heading?: React.ReactNode;
	isLoggedIn: boolean;
	hasBp: boolean;
	handleAddProductToCart: (product: Models.UpdateBasketProduct) => void;
}

export const FeaturedProducts: React.FC<FeaturedProductsProps> = ({
	className,
	columns,
	loadMoreText,
	numberOfProducts,
	products,
	heading,
	isLoggedIn,
	hasBp,
	handleAddProductToCart,
}) => {
	// Gets column width for Product Card
	const getColumnWidth = (columns: number) => {
		if (columns > 4) columns = 4;
		if (!columns || columns === 1) columns = 2;
		const columnsWidth = Math.round(100 / columns);
		return JSON.stringify(columnsWidth) as Models.GridCellWidth;
	};
	const columnWidth = getColumnWidth(columns);
	const [noProductsShown, setNoProductsShown] = useState(columns);
	// Defines image sizes for Product Card
	const imageSizes = '(max-width: 768px) 50vw, ' + columnWidth + 'vw';
	const onLoadMoreProducts = () => {
		setNoProductsShown(
			noProductsShown + columns > products.length
				? products.length
				: noProductsShown + columns,
		);
	};

	return (
		<div className={classNames(styles.FeaturedProducts, className)}>
			<Container width="Standard">
				<Heading
					headingLevel="h1"
					style="sm"
					className={styles.FeaturedProducts_heading}
				>
					{heading}
				</Heading>
				<div
					className={classNames(
						styles.FeaturedProductsItems,
						className,
					)}
				>
					{products
						.slice(0, noProductsShown)
						.map((product, index) => {
							if (index < numberOfProducts) {
								return (
									<ProductCard
										product={product as Models.Product}
										columnWidth={columnWidth}
										key={index}
										imageSizes={imageSizes}
										isLoggedIn={isLoggedIn}
										onAddToCart={handleAddProductToCart}
										hasBp={hasBp}
									/>
								);
							}
						})}
				</div>
				{noProductsShown < products.length && (
					<Button
						className={styles.FeaturedProducts_loadButton}
						onClick={onLoadMoreProducts}
					>
						{loadMoreText}
						<Icon
							size="sm"
							className={styles.FeaturedProducts_plusIcon}
						>
							<PlusIcon />
						</Icon>
					</Button>
				)}
			</Container>
		</div>
	);
};
