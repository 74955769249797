import classNames from 'classnames';
import styles from './LoginStatus.module.scss';
import { Link } from 'ui/components';
import { Button, Heading, Paragraph } from 'ui/components/1-atoms';
import { Grid, GridCell } from 'ui/components/4-habitats';
import { AddressCard } from 'ui/components/2-molecules/Card/AddressCard';
import { MouseEventHandler } from 'react';

export interface LoginStatusProps {
	className?: string;
	style?: 'primary';
	dictionary?: { [key: string]: string };
	profile: Models.User;
	addresses?: Models.Address[];
	bpId?: string;
	missingCustomerProfileMessage?: string | React.ReactElement;
	links?: Models.NavigationItem[];
	handleLinkClick?: MouseEventHandler<HTMLAnchorElement>;
	onSignIn: () => void;
	onSignOut: () => void;
	onNavigateToUserProfile: () => void;
}
export const LoginStatus: React.FC<LoginStatusProps> = ({
	className,
	style,
	dictionary,
	addresses,
	bpId,
	missingCustomerProfileMessage,
	links,
	handleLinkClick,
	onSignIn,
	onSignOut,
	onNavigateToUserProfile,
	profile,
}) => {
	return (
		<>
			<div className={classNames(styles.LoginStatus_container)}>
				<div className={classNames(styles.LoginStatus_userContainer)}>
					<div>
						<Heading
							headingLevel="h3"
							style="md"
							className={classNames(styles.LoginStatus_user)}
						>
							{profile?.name}
						</Heading>
						<Paragraph>{dictionary?.loggedInAs}:</Paragraph>
						<Paragraph>{profile?.email}</Paragraph>
					</div>
					<div>
						<div className={styles.LoginStatus_buttonContainer}>
							<div className={styles.LoginStatus_buttonWrapper}>
								<Button
									style="transparent"
									className={styles.LoginStatus_button}
									onClick={() => {
										onNavigateToUserProfile();
									}}
								>
									{dictionary?.firstButtonText}
								</Button>
							</div>

							<div className={styles.LoginStatus_buttonWrapper}>
								<Button
									style="transparent"
									className={styles.LoginStatus_button}
									onClick={() => {
										onSignOut();
									}}
								>
									{dictionary?.secondButtonText}
								</Button>
							</div>
						</div>
					</div>
				</div>
				<div
					className={classNames(
						styles.LoginStatus_addressesContainer,
					)}
				>
					<Heading headingLevel="h3" style="sm">
						{dictionary?.registeredAddresses}
					</Heading>
					<Grid
						wrap
						container={true}
						className={classNames(styles.LoginStatus_addresses)}
					>
						{addresses?.length > 0 &&
							addresses.map((address: Models.Address, index) => {
								if (index > 2) return null;

								return (
									<GridCell
										key={index}
										desktopWidth="33"
										mobileWidth="100"
									>
										<AddressCard
											className={
												styles.LoginStatus_address
											}
											key={index}
											style="secondary"
											addressType={address?.key}
											address={address?.value}
											id={bpId}
											dictionary={dictionary}
										/>
									</GridCell>
								);
							})}
						{!addresses?.length && (
							<GridCell desktopWidth="100" mobileWidth="100">
								{missingCustomerProfileMessage}
							</GridCell>
						)}
					</Grid>
				</div>
				<div className={classNames(styles.LoginStatus_linksContainer)}>
					<Heading headingLevel="h3" style="sm">
						{dictionary?.links}
					</Heading>
					<div className={classNames(styles.LoginStatus_links)}>
						{links &&
							links.map((link, index) => {
								return (
									<div
										key={index}
										className={classNames(
											styles.LoginStatus_link,
										)}
									>
										<Link
											url={link.url}
											chevron="after"
											handleClick={handleLinkClick}
										>
											{link.title}
										</Link>
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</>
	);
};
