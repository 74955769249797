import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'application/adapters/store/store';

export interface CostDetailsState {
	subTotal: number;
	fees: number;
	handlingFee: number;
	freight?: number;
	hazardousFee?: number;
	salesTax: number;
	formattedSubtotal: string;
	formattedHandlingFee: string;
	formattedHazardousFee: string;
	formattedFreight: string;
	formattedTaxTotal: string;
	formattedOrderTotal: string;
}

export interface CostTotal {
	discountTotal: number;
	orderTotal: number;
	subTotal: number;
	shippingTotal: number;
	taxTotal: number;
	vat: number;
	totalPrice?: number;
	formattedTotal?: string;
}
export interface CartState {
	cartProducts: Models.CartProduct[];
	totalSum: number;
	showCart: boolean;
	cartId: string;
	totalQuantity: number;
	total: CostTotal;
	costDetails: CostDetailsState;
	currency: string;
	information: Models.BasketInformation;
	earliestShipping: boolean;
	deliveryInformation: Models.AddressLines;
	hasCardPayment: boolean;
	
}

const initialState: CartState = {
	cartProducts: [],
	totalSum: 0,
	showCart: false,
	cartId: null,
	totalQuantity: 0,
	total: {
		discountTotal: 0,
		orderTotal: 0,
		subTotal: 0,
		shippingTotal: 0,
		taxTotal: 0,
		vat: 0,
		totalPrice: 0,
	},
	costDetails: {
		subTotal: 0,
		fees: 0,
		handlingFee: 0,
		salesTax: 0,
		formattedSubtotal: '0',
		formattedHandlingFee: '0',
		formattedHazardousFee: '0',
		formattedFreight: '0',
		formattedTaxTotal: '0',
		formattedOrderTotal: '0',
	},
	currency: null,
	information: {
		additionalDeliveryNotes: '',
		additionalReference: '',
		paymentMethod: 'Credit',
		purchaseNumberRef: '',
		sDSReceiptEmail: '',
		shippingMethod: 'Standard',
		termsAccepted: false,
	},
	earliestShipping: true,
	deliveryInformation: null,
	hasCardPayment: false,
};

export const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		toggleCartVisiblity: (state, action: PayloadAction<boolean>) => {
			state.showCart = action.payload;

			return state;
		},
		setCart: (state, action: PayloadAction<Models.Basket>) => {
			if (!action.payload) {
				return state;
			}
			state.cartId = action.payload?.basketId;
			state.cartProducts = action.payload?.lines;
			state.totalSum = action.payload?.total.orderTotal;
			state.totalQuantity = state.cartProducts
				?.map((cartProduct) => cartProduct?.quantity)
				.reduce(
					(previousVal, currentVal) => previousVal + currentVal,
					0,
				);
			state.total = {
				discountTotal: action.payload.total.discountTotal ?? 0,
				orderTotal: action.payload.total.orderTotal ?? 0,
				subTotal: action.payload.total.subTotal ?? 0,
				shippingTotal: action.payload.total.shippingTotal ?? 0,
				taxTotal: action.payload.total.taxTotal ?? 0,
				vat: action.payload.total.vat ?? 0,
			};
			state.costDetails = {
				subTotal: state.total.orderTotal - state.total.taxTotal,
				fees: 0,
				handlingFee: action.payload.fees.handling ?? 0,
				hazardousFee: action.payload.fees.hazardous ?? 0,
				freight: action.payload.fees.shipping ?? 0,
				salesTax: state.total.taxTotal ? state.total.taxTotal : 0,
				formattedSubtotal: action.payload.formattedSubTotal,
				formattedHandlingFee: action.payload.formattedHandlingFee,
				formattedHazardousFee: action.payload.formattedHazardousFee,
				formattedFreight: action.payload.formattedFreight,
				formattedTaxTotal: action.payload.formattedTaxTotal,
				formattedOrderTotal: action.payload.formattedOrderTotal,
			};
			state.currency = action.payload.total.currency;
			state.information = action.payload.information;
			state.earliestShipping = action.payload.earliestShipping;
			state.deliveryInformation = action.payload.deliveryInformation;
			state.hasCardPayment = action.payload.hasCardPayment;

			return state;
		},
		resetCart: () => {
			return initialState;
		},
		setBasketInformation: (
			state,
			action: PayloadAction<Models.BasketInformation>,
		) => {
			state.information = action.payload;

			return state;
		},
	},
});

export const { toggleCartVisiblity, setCart, resetCart, setBasketInformation } =
	cartSlice.actions;

export const currentCartId: (state: RootState) => string = (state: RootState) =>
	state.cart.cartId;
export const totalQuantity: (state: RootState) => number = (state: RootState) =>
	state.cart.totalQuantity;
export const selectCartProducts: (state: RootState) => Models.CartProduct[] = (
	state: RootState,
) => state.cart.cartProducts;
export const totalSum: (state: RootState) => number = (state: RootState) =>
	state.cart.totalSum;
export const subTotal: (state: RootState) => number = (state: RootState) =>
	state.cart.total.subTotal;
export const cart: (state: RootState) => CartState = (state: RootState) =>
	state.cart;
export const costDetails: (state: RootState) => CostDetailsState = (
	state: RootState,
) => state.cart.costDetails;

export const total: (state: RootState) => CostTotal = (state: RootState) =>
	state.cart.total;

export const currency: (state: RootState) => string = (state: RootState) =>
	state.cart.currency;

export const information: (state: RootState) => Models.BasketInformation = (
	state: RootState,
) => state.cart.information;

export const deliveryInformation: (state: RootState) => Models.AddressLines = (
	state: RootState,
) => state.cart.deliveryInformation;

export const earliestShipping: (state: RootState) => boolean = (
	state: RootState,
) => state.cart.earliestShipping;

export const hasCardPayment: (state: RootState) => boolean = (
	state: RootState,
) => state.cart.hasCardPayment;

export default cartSlice.reducer;
