import { Link } from 'ui/components';
import styles from './Breadcrumb.module.scss';
import classNames from 'classnames';

export interface BreadcrumbProps {
	breadcrumbItems: Array<BreadcrumbItemProps>;
}

export interface BreadcrumbItemProps {
	url?: string;
	title: string;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumbItems }) => (
	<ul className={styles.Breadcrumb}>
		{breadcrumbItems.map((breadcrumbItem: BreadcrumbItemProps, index) => (
			<li key={index} className={styles.Breadcrumb_item}>
				{breadcrumbItem.url ? (
					<Link className={styles.Breadcrumb_url} {...breadcrumbItem}>
						{breadcrumbItem.title}
					</Link>
				) : (
					<p className={classNames(styles.Breadcrumb_url, styles.Breadcrumb_url___current)}>
						{breadcrumbItem.title}
					</p>
				)}
			</li>
		))}
	</ul>
);
