import {
	getProductBySkuFromAdapter,
	getProductByIdFromAdapter,
	getGlobalCategoryByParent,
	getAllCategories,
	getProductsByGuid,
	// getBasketById,
	updateBasket as callUpdateBasket,
	updateBasketMultiple as callUpdateBasketMultiple,
	validateBasket,
	updateInformation,
	updateShippingData,
	createPaymentIntent,
	createSetupIntent,
	getPaymentMethods,
	getCarrierOptions,
	callGetUserBasket,
	addOrUpdateOrderFees,
	removeAllOrderFees,
	addOrUpdateOrderTax,
	invoicePaymentCheckout,
	getOrderState,
	getBasketQuoteByEmail,
	deleteUserBasketId,
	callGetProductPrice,
} from 'application/adapters/ecommerce/ecommerceAdapter';

export const getProductById: (
	productId: string,
	accessToken: any,
	locale: string,
	bpId: string,
) => Promise<Models.Product> = async (productId, accessToken, locale, bpId) => {
	const product = getProductByIdFromAdapter(
		productId,
		accessToken,
		locale,
		bpId,
	);

	return product;
};

export const getProductBySku: (
	productId: string,
	accessToken: any,
	locale: string,
	bpId: string,
	country?: string,
) => Promise<Models.CategoryProduct> = async (
	productId,
	accessToken,
	locale,
	bpId,
	country,
) => {
	const product = getProductBySkuFromAdapter(
		productId,
		accessToken,
		locale,
		bpId,
		country,
	);

	return product;
};

export const getProductsByCategory = async (
	categoryId,
	facets,
	locale,
	accessToken,
	bpId,
	pageSize,
	page,
) => {
	const products = getProductsByGuid(
		categoryId,
		facets,
		locale,
		accessToken,
		bpId,
		pageSize,
		page,
	);

	return products;
};

export const getCategoryById = async (id, accessToken, culture, bpId) => {
	const category = await getGlobalCategoryByParent(
		id,
		accessToken,
		culture,
		bpId,
	);

	return category;
};

export const getCategories = async (accessToken, culture, bpId) => {
	const categories = await getAllCategories(accessToken, culture, bpId);

	return categories;
};

// export const basketById: (accessToken, id, bpId, culture) => Promise<Models.Basket> =
// 	async (accessToken, id, bpId) => {
// 		return await getBasketById(accessToken, id, bpId, culture);
// 	};

export const getUserBasket: (
	accessToken,
	culture,
	bpId,
	basketName?,
) => Promise<Models.Basket> = async (
	accessToken,
	culture,
	bpId,
	basketName,
) => {
	return await callGetUserBasket(accessToken, culture, bpId, basketName);
};

export const updateBasket: (
	accessToken,
	updateBasket,
	isQuickOrder?,
	skipFeeUpdate?,
) => Promise<Models.Basket> = async (
	accessToken,
	updateBasket,
	isQuickOrder,
	skipFeeUpdate,
) => {
	return await callUpdateBasket(
		accessToken,
		updateBasket,
		isQuickOrder,
		skipFeeUpdate,
	);
};

export const getProductPrice: (
	accessToken,
	data,
) => Promise<Models.Price> = async (
	accessToken,
	data,
) => {
	return await callGetProductPrice(
		accessToken,
		data,
	);
};

export const updateBasketMultiple: (
	accessToken,
	updateBasketMultiple,
) => Promise<Models.BasketMultiple> = async (
	accessToken,
	updateBasketMultiple,
) => {
	return await callUpdateBasketMultiple(accessToken, updateBasketMultiple);
};

export const validate: (
	accessToken,
	id,
	bpId,
	culture,
) => Promise<Models.Basket> = async (accessToken, id, bpId, culture) => {
	return await validateBasket(accessToken, id, bpId, culture);
};

export const updateInfo: (accessToken, model) => Promise<Models.Basket> =
	async (accessToken, model) => {
		return await updateInformation(accessToken, model);
	};

export const updateShipping: (accessToken, data) => Promise<Models.Basket> =
	async (accessToken, data) => {
		return await updateShippingData(accessToken, data);
	};

export const getAvailablePaymentMethods: (
	accessToken,
	bpId: string,
) => Promise<string[]> = async (accessToken, bpId) => {
	return await getPaymentMethods(accessToken, bpId);
};

export const createPayment: (
	accessToken,
	bpId: string,
	basketId: string,
) => Promise<Models.StripeIntent> = async (accessToken, bpId, basketId) => {
	return await createPaymentIntent(accessToken, bpId, basketId);
};

export const createSetup: (
	accessToken,
	bpId: string,
	basketId: string,
) => Promise<Models.StripeIntent> = async (accessToken, bpId, basketId) => {
	return await createSetupIntent(accessToken, bpId, basketId);
};

export const getShippingMethods: (
	accessToken,
	bpId,
	culture,
) => Promise<Models.FreightCarrier[]> = async (accesToken, bpId, culture) => {
	return await getCarrierOptions(accesToken, bpId, culture);
};

export const addOrUpdateBasketOrderFees: (
	accessToken,
	basketId,
	bpId,
	chosenCarrrierId, //TODO: CAN BE NULL (IF NO SHIPPING METHOD CHOSEN OR AVAILABLE)
	culture,
) => Promise<Models.Basket> = async (
	accesToken,
	basketId,
	bpId,
	chosenCarrrierId,
	culture,
) => {
	return await addOrUpdateOrderFees(
		accesToken,
		basketId,
		bpId,
		chosenCarrrierId,
		culture,
	);
};

export const removeAllBasketOrderFees: (
	accessToken,
	basketId,
	bpId,
	culture,
) => Promise<Models.Basket> = async (accesToken, basketId, bpId, culture) => {
	return await removeAllOrderFees(accesToken, basketId, bpId, culture);
};

export const addOrUpdateBasketOrderTax: (
	accessToken,
	basketId,
	bpId,
	culture,
) => Promise<Models.Basket> = async (accesToken, basketId, bpId, culture) => {
	return await addOrUpdateOrderTax(accesToken, basketId, bpId, culture);
};

export const callInvoicePaymentCheckout: (
	accessToken,
	basketId,
) => Promise<string> = async (accesToken, basketId) => {
	return await invoicePaymentCheckout(accesToken, basketId);
};
export const callGetOrderState: (
	accessToken,
	orderId,
) => Promise<Models.OrderState> = async (accesToken, orderId) => {
	return await getOrderState(accesToken, orderId);
};

export const getQuoteByEmail: (accessToken, basketId, bpId, culture) => void =
	async (accesToken, basketId, bpId, culture) => {
		await getBasketQuoteByEmail(accesToken, basketId, bpId, culture);
	};

export const deleteUserBasket: (bpId: string) => void = async (bpId) => {
	await deleteUserBasketId(bpId);
};
