import classNames from 'classnames';
import { Container, Heading, Link, RichText } from 'ui/components';
import styles from './CheckoutReceipt.module.scss';
import React, { useContext } from 'react';
import { DictionaryContext } from 'application/adapters/context/Dictionary';

export interface CheckoutReceiptProps {
	className?: string;
	heading?: string;
	orderNumber: string;
	orderNumberLabel: string;
	orderStatus: string;
	orderStatusLabel: string;
	orderStatusBlocked: boolean;
	orderStatusNotice: string;
	text?: string;
	nextStepsHeading?: string;
	reviewOrderLinkUrl?: string;
	reviewOrderLinkText?: string;
	orderHistoryLinkUrl?: string;
	orderHistoryLinkText?: string;
	additionalLinks?: Umbraco.Link[];
}

export const CheckoutReceipt: React.FC<CheckoutReceiptProps> = ({
	className,
	heading,
	orderNumber,
	orderNumberLabel,
	orderStatus,
	orderStatusLabel,
	orderStatusBlocked = false,
	orderStatusNotice,
	text,
	nextStepsHeading,
	reviewOrderLinkUrl,
	reviewOrderLinkText,
	orderHistoryLinkUrl,
	orderHistoryLinkText,
	additionalLinks,
}) => {
	
	const dictionary = useContext(DictionaryContext);
	return (
		<Container
			width="Standard"
			className={classNames(className, styles.CheckoutReceipt)}
		>
			{heading && (
				<Heading
					headingLevel="h3"
					className={styles.CheckoutReceipt_heading}
				>
					{heading}
				</Heading>
			)}
			<div className={styles.CheckoutReceipt_info}>
				<div className={styles.CheckoutReceipt_infoItems}>
					{orderNumber && orderNumberLabel && (
						<div className={styles.CheckoutReceipt_infoItems_item}>
							<strong>{orderNumberLabel}</strong>
							<span>{orderNumber}</span>
						</div>
					)}
					{orderStatus && orderStatusLabel && (
						<div className={styles.CheckoutReceipt_infoItems_item}>
							<strong>{orderStatusLabel}</strong>
							{orderStatus === 'Blocked' ? (
								<strong>{orderStatus}</strong>
							) : (
								<span
									className={
										styles.CheckoutReceipt_orderStatus
									}
								>
									{dictionary.getValue(
										'Checkout.' + orderStatus,
										null,
										orderStatus
									)}
								</span>
							)}
						</div>
					)}
				</div>
				{orderStatus && orderStatusLabel && orderStatusBlocked && (
					<RichText
						className={styles.CheckoutReceipt_orderStatusNotice}
						content={orderStatusNotice}
					/>
				)}
			</div>
			{text && (
				<RichText
					className={styles.CheckoutReceipt_text}
					content={text}
				/>
			)}
			<Heading
				headingLevel="h3"
				className={styles.CheckoutReceipt_nextStepsHeading}
			>
				{nextStepsHeading}
			</Heading>
			{reviewOrderLinkUrl && reviewOrderLinkText && (
				<Link
					className={styles.CheckoutReceipt_link}
					url={reviewOrderLinkUrl}
				>
					{reviewOrderLinkText}
				</Link>
			)}
			{orderHistoryLinkUrl && orderHistoryLinkText && (
				<Link
					url={orderHistoryLinkUrl}
					className={styles.CheckoutReceipt_link}
				>
					{orderHistoryLinkText}
				</Link>
			)}
			{additionalLinks &&
				additionalLinks.map((link, index) => {
					return (
						<Link
							className={styles.CheckoutReceipt_link}
							url={link.url}
							target={link.target}
							key={index}
						>
							{link.name}
						</Link>
					);
				})}
		</Container>
	);
};
