import styles from './ProductList.module.scss';
import classNames from 'classnames';
import {
	ProductCard,
	ProductSorting,
	SortingParameter,
	SortingTypes,
	Facets,
	Button,
} from 'ui/components';
import { useEffect, useState } from 'react';
import { Container } from 'ui/components/4-habitats';
import { PageSpinner } from 'ui/components/1-atoms/Media/PageSpinner';
import { PaginationWithButtons, PaginationWithButtonsProps } from 'ui/components/2-molecules/Navigation/Pagination/PaginationWithButtons';

export interface ProductListProps {
	className?: string;
	columns?: number;
	numberOfProducts: number;
	products: Models.Product[];
	filterSettings?: {
		priceRangeMinMax: [number, number];
	};
	sortingSettings?: SortingParameter[];
	paginationData?: PaginationWithButtonsProps;
	isLoggedIn: boolean;
	isLoadStory?: boolean;
	facets?: {
		count: number;
		name: string;
		displayName: string;
		facetvalues: { value: string; count: number }[];
	}[];
	handleFacetsChanged: (name: string, value: string) => void;
	handleAddProductToCart: (product: Models.UpdateBasketProduct) => void;
	selectionId?: string;
	hasBp: boolean;
	dictionary: { [key: string]: string };
}

export const ProductList: React.FC<ProductListProps> = ({
	className,
	columns,
	products,
	numberOfProducts,
	filterSettings,
	sortingSettings,
	paginationData,
	isLoggedIn,
	isLoadStory = false,
	facets,
	handleFacetsChanged,
	handleAddProductToCart,
	selectionId,
	hasBp,
	dictionary,
}) => {
	// Defines available filters in state with default values
	const [filters] = useState<{ priceRange: [number, number] }>({
		priceRange: [
			filterSettings?.priceRangeMinMax[0],
			filterSettings?.priceRangeMinMax[1],
		],
	});
	const [sortingParameter, setSortingParameter] = useState<SortingParameter>({
		parameter: sortingSettings && sortingSettings[0].parameter,
		text: sortingSettings && sortingSettings[0].text,
	});

	const handleSorting = (sortingParameter) => {
		setSortingParameter(sortingParameter);
	};

	// Filters Products
	const filteredProducts = products.filter((product) => {
		if (product?.price?.price <= filters?.priceRange[0]) return false;
		if (product?.price?.price >= filters?.priceRange[1]) return false;
		return true;
	});

	const sortedProducts = filteredProducts.sort((a, b) => {
		if (sortingParameter.parameter === SortingTypes.NAME) {
			return a.name.localeCompare(b.name);
		}
		if (sortingParameter.parameter === SortingTypes.PRICEMIN) {
			return a.price.price - b.price.price;
		}
		if (sortingParameter.parameter === SortingTypes.PRICEMAX) {
			return b.price.price - a.price.price;
		}
	});

	// Gets column width for Product Card
	const getColumnWidth = (columns: number) => {
		if (columns > 4) columns = 4;
		if (!columns || columns === 1) columns = 2;
		const columnsWidth = Math.round(100 / columns);
		return JSON.stringify(columnsWidth) as Models.GridCellWidth;
	};
	const columnWidth = getColumnWidth(columns);

	// Defines image sizes for Product Card
	const imageSizes = '(max-width: 768px) 50vw, ' + columnWidth + 'vw';

	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsLoading(isLoadStory);
	}, [isLoadStory]);

	return (
		<Container width="Standard">
			<div className={classNames(styles.ProductList)} id='productList'>
				{isLoading && <PageSpinner size="large" />}

				<div
					className={classNames(
						styles.ProductListContainer,
						className,
					)}
				>
					<div className={styles.ProductList_offcanvas}>
						<ProductSorting
							className={styles.ProductList_sorting}
							sortingSettings={sortingSettings && sortingSettings}
							setSorting={handleSorting}
							chosenSortingParameter={sortingParameter}
							dictionary={dictionary}
						/>
						<Facets
							facets={facets}
							handleFacetsChanged={handleFacetsChanged}
							selectionId={selectionId}
						/>
						<Button
							style="primary"
							className={styles.ProductList_offcanvasButton}
						>
							Show result
						</Button>
					</div>
					<section
						className={classNames(
							styles.ProductListWrapper,
							className,
						)}
					>
						<div
							className={classNames(
								styles.ProductListItems,
								className,
							)}
						>
							{sortedProducts &&
								sortedProducts.map((product, index) => {
									if (index < numberOfProducts) {
										return (
											<ProductCard
												product={
													product as Models.Product
												}
												columnWidth={columnWidth}
												key={product.sku}
												imageSizes={imageSizes}
												isLoggedIn={isLoggedIn}
												onAddToCart={
													handleAddProductToCart
												}
												hasBp={hasBp}
											/>
										);
									}
								})}
						</div>
						{paginationData && (
							<PaginationWithButtons {...paginationData} />
						)}
					</section>
				</div>
			</div>
		</Container>
	);
};
