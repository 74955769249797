import classNames from 'classnames';
import { Heading, Link } from 'ui/components';
import styles from './FooterCard.module.scss';

export interface FooterCardProps {
	className?: string;
	heading: string;
	links: Umbraco.Link[];
}

export const FooterCard: React.FC<FooterCardProps> = ({
	className,
	heading,
	links,
}) => {
	return (
		<>
			<Heading
				headingLevel="h3"
				style="sm"
				className={styles.FooterCard_heading}
			>
				{heading}
			</Heading>
			<ul className={classNames(styles.FooterCard_links, className)}>
				{links?.map((link, index) => (
					<li
						className={styles.FooterCard_linkItem}
						key={`footerLink ${index}`}
					>
						<Link
							className={styles.FooterCard_link}
							style={'sm'}
							{...link}
							chevron={'none'}
						>
							{link.name.toLocaleUpperCase()}
						</Link>
					</li>
				))}
			</ul>
		</>
	);
};
