import styles from './RichText.module.scss';
import cx from 'classnames';
export interface RichText {
	content: string;
	className?: string;
}

export const RichText: React.FC<RichText> = ({ content, className }) => {
	return <div className={cx(styles.RichText, className)} dangerouslySetInnerHTML={{ __html: content }} />;
};
