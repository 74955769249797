import styles from './MobileShipmentContent.module.scss';
import { useState, useContext } from 'react';
import classNames from 'classnames';
import { Paragraph, Icon } from 'ui/components';
import { ReactComponent as ChevronIcon } from 'ui/icons/icon-chevron.svg';
import { DictionaryContext } from 'application/adapters/context/Dictionary';

export interface MobileShipmentContentProps {
	item: Models.ShipmentOption;
}

export const MobileShipmentContent: React.FC<MobileShipmentContentProps> = ({
	item,
}) => {
	const [isActive, setIsActive] = useState(false);
	const toggleVisibility = () => {
		setIsActive(!isActive);
	};
	const dictionary = useContext(DictionaryContext);

	return (
		<div
			className={classNames(
				styles.MobileShipmentContent_mobileShipment,
				styles.MobileShipmentContent_mobileProductView,
				styles.MobileShipmentContent_mobileProductView___shipment,
			)}
		>
			<div
				onClick={toggleVisibility}
				className={classNames(
					styles.MobileShipmentContent_shipmentGridBox,
				)}
			>
				<Paragraph
					className={styles.MobileShipmentContent_mediumText}
					style="md"
				>
					{dictionary.getValue(
						'ShoppingCart.Shipment',
						null,
						'Shipment: ',
					)}
					{item.splitShipmentNumber} {`(Qty ${item.quantity})`}
				</Paragraph>
				<div
					className={styles.MobileShipmentContent_buttonIconContainer}
				>
					<Icon
						size="md"
						className={classNames(
							styles.MobileShipmentContent_buttonIcon,
							{
								[styles.isActive]: isActive,
							},
						)}
					>
						<ChevronIcon />
					</Icon>
				</div>
			</div>

			<div
				className={classNames(
					styles.MobileShipmentContent_mobileShipmentContent,
					{
						[styles.isActive]: isActive,
					},
				)}
			>
				<Paragraph
					className={styles.MobileShipmentContent_shipmentInfo}
				>
					{dictionary.getValue(
						'ShoppingCart.ShipDate',
						null,
						'Date: ',
					)}{' '}
					{item.formattedEarliestDeliveryDate}
					,{' '}
					{dictionary.getValue(
						'ShoppingCart.EarliestPossible',
						null,
						'Earliest possible',
					)}
				</Paragraph>

				<Paragraph
					className={styles.MobileShipmentContent_shipmentInfo}
				>
					{dictionary.getValue(
						'ShoppingCart.Quantity',
						null,
						'Quantity: ',
					)}
					{item.quantity}
				</Paragraph>
			</div>
		</div>
	);
};
