import classNames from 'classnames';
import styles from './Navigation.module.scss';
import { Icon, Link } from 'ui/components';
import { ReactComponent as ChevronIcon } from 'ui/icons/icon-chevron.svg';
import { useState } from 'react';
import { Heading, Paragraph } from 'ui/components/1-atoms';
import { Container } from 'ui/components/4-habitats';
import { MouseEventHandler } from 'react';

export interface NavigationProps {
	className?: string;
	style?: 'primary';
	heading?: string;
	navigationItems?: Navigation.NestedNavigationItem[];
	handleMobileNavLinkClick?: () => void;
}

export const Navigation: React.FC<NavigationProps> = ({
	navigationItems,
	className,
	heading,
	style,
	handleMobileNavLinkClick,
}) => {
	return (
		<>
			<nav
				className={classNames(
					styles.Navigation,
					styles[`Navigation___${style}`],
					className,
					styles.Navigation_desktop,
				)}
			>
				{heading && (
					<Heading
						headingLevel="h6"
						className={styles.Navigation_heading}
					>
						{heading}
					</Heading>
				)}
				<ul className={styles.Navigation_list}>
					{navigationItems &&
						navigationItems.map(
							(
								navigationItem: Navigation.NestedNavigationItem,
								index,
							) => (
								<NavigationDropDown key={index}>
									<Link
										className={classNames(
											styles.Navigation_link,
										)}
										url={navigationItem.url}
									>
										{navigationItem.title ??
											navigationItem.name}
									</Link>
									{navigationItem.decendants &&
										navigationItem.decendants?.length !==
											0 && (
											<>
												<div
													className={
														styles.Navigation_buttonIconContainer
													}
												>
													<Icon
														size="xl"
														className={
															styles.Navigation_buttonIcon
														}
													>
														<ChevronIcon />
													</Icon>
												</div>
												<div
													className={
														styles.Navigation_dropDownContainer
													}
												>
													<Container width="Standard">
														<ul
															className={
																styles.Navigation_dropDownContainerInner
															}
														>
															{navigationItem.decendants &&
																navigationItem.decendants.map(
																	(
																		dropDownItem: Navigation.NestedNavigationItem,
																		index,
																	) => {
																		return (
																			<NavigationDropDownItem
																				key={
																					index
																				}
																			>
																				<Link
																					url={
																						dropDownItem.url
																					}
																				>
																					<div
																						className={
																							styles.Navigation_dropDownItem
																						}
																					>
																						<Heading
																							headingLevel="h4"
																							style="sm"
																							className={
																								styles.Navigation_dropDownItemHeader
																							}
																						>
																							{dropDownItem.title ??
																								dropDownItem.name}
																						</Heading>
																						<Paragraph
																							className={
																								styles.Navigation_dropDownItemParagraph
																							}
																							style="sm"
																						>
																							{dropDownItem.amount &&
																								'(' +
																									dropDownItem.amount +
																									')'}
																						</Paragraph>
																					</div>
																				</Link>

																				<div
																					className={
																						styles.Navigation_dropDownItemChildren
																					}
																				>
																					{dropDownItem.decendants &&
																						dropDownItem
																							.decendants
																							?.length >
																							0 &&
																						dropDownItem.decendants.map(
																							(
																								dropDownItemChildren: Navigation.NestedNavigationItem,
																								index,
																							) => {
																								return (
																									<Link
																										key={
																											index
																										}
																										url={
																											dropDownItemChildren.url
																										}
																										className={
																											styles.Navigation_dropDownItemChildren
																										}
																									>
																										{
																											dropDownItemChildren.name
																										}{' '}
																										{dropDownItemChildren.amount &&
																											'(' +
																												dropDownItemChildren.amount +
																												')'}
																									</Link>
																								);
																							},
																						)}
																				</div>
																			</NavigationDropDownItem>
																		);
																	},
																)}
														</ul>
													</Container>
												</div>
											</>
										)}
								</NavigationDropDown>
							),
						)}
				</ul>
			</nav>

			{/* MOBILE */}
			<nav className={styles.Navigation_mobile}>
				<NavigationItem
					navigationItems={navigationItems}
					handleMobileNavLinkClick={handleMobileNavLinkClick}
				/>
			</nav>
		</>
	);
};

const NavigationDropDown: React.FC = ({ children }) => {
	const [isActive, setIsActive] = useState(false);

	return (
		<li
			className={classNames(styles.Navigation_item, {
				[styles.isActive]: isActive,
			})}
			aria-expanded={isActive}
			onClick={() => {
				setIsActive(!isActive);
			}}
			onMouseEnter={() => {
				setIsActive(true);
			}}
			onMouseLeave={() => {
				setIsActive(false);
			}}
			onFocus={() => {
				setIsActive(true);
			}}
			onBlur={() => {
				setIsActive(false);
			}}
		>
			{children}
		</li>
	);
};

const NavigationDropDownItem: React.FC = ({ children }) => {
	return (
		<li className={classNames(styles.Navigation_dropDownItemContainer)}>
			{children}
		</li>
	);
};

// MOBILE FUNCTIONALITY
const NavigationItem = ({ navigationItems, handleMobileNavLinkClick }) => {
	const [displayChildren, setDisplayChildren] = useState({});

	return (
		<ul className={classNames(styles.Navigation_list)}>
			{navigationItems.map(
				(item: Navigation.NestedNavigationItem, index: number) => {
					return (
						<li
							key={index}
							className={classNames(styles.Navigation_item)}
						>
							<div
								className={classNames(
									styles.Navigation_dropDown,
								)}
							>
								<Link
									url={item.url}
									className={classNames(
										styles.Navigation_link,
									)}
									handleClick={handleMobileNavLinkClick}
								>
									{item.name}{' '}
									<span>
										{item.amount && '(' + item.amount + ')'}
									</span>
								</Link>

								{item.decendants &&
									item.decendants?.length !== 0 && (
										<div
											className={
												styles.Navigation_buttonIconContainer
											}
											onClick={() => {
												setDisplayChildren({
													...displayChildren,
													[item.name]:
														!displayChildren[
															item.name
														],
												});
											}}
										>
											{displayChildren[item.name] ? (
												<Icon
													size="xl"
													className={classNames(
														styles.Navigation_buttonIcon,
														styles.isActive,
													)}
												>
													<ChevronIcon />
												</Icon>
											) : (
												<Icon
													size="xl"
													className={classNames(
														styles.Navigation_buttonIcon,
													)}
												>
													<ChevronIcon />
												</Icon>
											)}
										</div>
									)}
							</div>

							{displayChildren[item.name] && item.decendants && (
								<NavigationItem
									navigationItems={item.decendants}
									handleMobileNavLinkClick={
										handleMobileNavLinkClick
									}
								/>
							)}
						</li>
					);
				},
			)}
		</ul>
	);
};
