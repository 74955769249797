import classNames from 'classnames';
import styles from './FormfieldCheckbox.module.scss';
import { ErrorMessage, Label } from 'ui/components';

export interface FormfieldCheckboxProps {
	className?: string;
	defaultChecked?: boolean;
	defaultValue?: string;
	id: string;
	label: string | React.ReactElement;
	multiline?: boolean;
	name?: string;
	fieldGroup?: boolean;
	register?: any;
	state?: {
		required?: boolean;
		disabled?: boolean;
		hasError?: boolean;
	};
	errorMessage?: string;
}

export const FormfieldCheckbox: React.FC<FormfieldCheckboxProps> = ({
	className,
	fieldGroup,
	register,
	state,
	id,
	label,
	multiline,
	name,
	defaultChecked,
	defaultValue,
	errorMessage,
}) => {
	const { required, disabled, hasError } = state ?? {};
	const labelState = {
		isHidden: false,
		disabled: state?.disabled,
		required: state?.required && !fieldGroup,
		hasError: state?.hasError,
	};

	return (
		<div
			className={classNames(
				styles.FormfieldCheckbox,
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
				{ [styles.FormfieldCheckbox___multiline]: multiline === true },
			)}
		>
			<div className={styles.FormfieldCheckbox_fieldContainer}>
				<input
					type="checkbox"
					className={classNames(
						styles.FormfieldCheckbox_input,
						styles.Input___choice,
						{
							[styles.hasError]: hasError,
						},
					)}
					name={name}
					id={id}
					defaultChecked={defaultChecked}
					disabled={disabled}
					defaultValue={defaultValue}
					{...(register &&
						register(name, {
							required: {
								value: required,
								message: 'This field is required',
							},
						}))}
				/>
				<Label
					id={id}
					className={styles.FormfieldCheckbox_label}
					state={labelState}
				>
					{label}{' '}
				</Label>
			</div>
			{hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
		</div>
	);
};
