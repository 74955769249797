export interface BuildHeadersInterface {
	domain: string;
	preview?: boolean;
	previewData?: Models.PreviewData;
	locale?: string;
	accessToken?: string;
}

export const buildHeaders = ({
	domain,
	preview,
	previewData,
	locale,
	accessToken,
}: BuildHeadersInterface): { [key: string]: string } => {
	const headers = {};
	headers['Content-Type'] = 'application/json';
	headers['Hostname'] = domain;

	if (preview && typeof previewData === 'object') {
		headers['Authorization'] = previewData?.data?.auth;
	} else if (accessToken) {
		headers['Authorization'] = `Bearer ${accessToken}`;
	}
	if (locale) {
		headers['Content-Culture'] = locale;
	}

	return headers;
};
