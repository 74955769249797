import { Button, LinkButton } from 'ui/components/';
import styles from './EmailQuote.module.scss';
import { ReactComponent as ArrowIcon } from 'ui/icons/icon_arrow.svg';
import classNames from 'classnames';
import { getQuoteByEmail } from '../../../../../application/repositories/ecommerceRepository';
import { useSelector } from 'react-redux';
import { currentCartId } from '../../../../../application/adapters/store/slices/cartSlice';
import { currentCustomer } from '../../../../../application/adapters/store/slices/customerSlice';
import { currentCulture } from 'application/adapters/store/slices/cultureSlice';

export interface EmailQuote {
	dictionary: { [key: string]: string };
	ourProductsLink: string;
	accessToken?: any;
	setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EmailQuote: React.FC<EmailQuote> = ({
	dictionary,
	ourProductsLink,
	accessToken,
	setIsLoading,
}) => {
	const basketId = useSelector(currentCartId);
	const bpId = useSelector(currentCustomer)?.id;
	const culture = useSelector(currentCulture);

	const handleClick = async () => {
		setIsLoading(true);
		await getQuoteByEmail(accessToken, basketId, bpId, culture);
		setIsLoading(false);
	};

	return (
		<div className={styles.EmailQuote}>
			<LinkButton
				url={ourProductsLink}
				className={classNames(
					styles.EmailQuote_button,
					styles.EmailQuote_button___withIcon,
				)}
				iconClassName={styles.EmailQuote_arrowIcon}
			>
				<ArrowIcon className={styles.EmailQuote_arrowIcon} />
				{dictionary.basketBrowseProducts}
			</LinkButton>

			<Button
				className={classNames(
					styles.EmailQuote_button,
					styles.EmailQuote_button___noIcon,
				)}
				style="secondary"
				onClick={handleClick}
			>
				{dictionary.basketEmailQuote}
			</Button>
		</div>
	);
};
