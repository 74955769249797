import classNames from 'classnames';
import styles from './ShoppingCart.module.scss';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CartIcon } from 'ui/icons/icon-basket.svg';
import { ReactComponent as CloseIcon } from 'ui/icons/icon_close_cross.svg';
import {
	Icon,
	Button,
	ShoppingCartItem,
	CartQuantity,
	Link,
} from 'ui/components';
import {
	selectCartProducts,
	totalQuantity,
	currentCartId,
	setCart,
	currency,
} from 'application/adapters/store/slices/cartSlice';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { updateBasket } from 'application/repositories/ecommerceRepository';
import { useState } from 'react';
import { currentCustomer } from 'application/adapters/store/slices/customerSlice';
import { PageSpinner } from 'ui/components/1-atoms/Media/PageSpinner';
import logger from 'helpers/logger';
import { LocaleContext } from 'application/adapters/context/LocaleContext';
import formatCartPrices from 'helpers/formatCartPrices';

export interface ShoppingCartProps {
	className?: string;
	shoppingCartText?: string;
	accessToken?: any;
	basketLink?: string;
	handleGoToCheckout: any;
	bpId?: string;
	formattedSum: string;
}

export const ShoppingCart: React.FC<ShoppingCartProps> = ({
	className,
	shoppingCartText,
	accessToken,
	basketLink,
	handleGoToCheckout,
	formattedSum,
	bpId,
}) => {
	//TODO: move all the dispatch and selectors to the feature containing this component
	const dispatch = useDispatch();
	const cartId = useSelector(currentCartId);
	const cartProducts = useSelector(selectCartProducts);
	const total = useSelector(totalQuantity);
	const [showCart, setShowCart] = useState<boolean>(false);
	const dictionary = useContext(DictionaryContext);
	const businessPartner = useSelector(currentCustomer);
	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	const handleCartVisibility = (show: boolean) => {
		setShowCart(show);
	};
	const currencyCode = useSelector(currency);
	const localeContext = useContext(LocaleContext);
	const handleRemoval = (cartProduct: Models.CartProduct) => {
		logger.info('productRemoved');
		setIsProcessing(true);

		const updateProduct = {
			basketId: cartId,
			quantityDelta: -cartProduct.quantity,
			sku: cartProduct.sku,
			bpId: businessPartner?.id,
		};

		return updateBasket(accessToken, updateProduct)
			.then((res) => {
				dispatch(
					setCart(formatCartPrices(res, localeContext, currencyCode)),
				);
				setIsProcessing(false);
				return Promise.resolve(res);
			})
			.catch((e) => {
				throw new Error('Product was not removed', e);
			});
	};

	const addProduct = (cartProduct: Models.CartProduct) => {
		logger.info('addProduct');
		setIsProcessing(true);

		const updateProduct = {
			basketId: cartId,
			quantityDelta: 1,
			sku: cartProduct.sku,
			bpId: businessPartner?.id,
		};
		return updateBasket(accessToken, updateProduct)
			.then((res) => {
				dispatch(
					setCart(formatCartPrices(res, localeContext, currencyCode)),
				);
				setIsProcessing(false);
				return Promise.resolve(res);
			})
			.catch((e) => {
				throw new Error('Product was not added', e);
			});
	};

	const handleDecrement = (cartProduct: Models.CartProduct) => {
		logger.info('handleDecrement');
		setIsProcessing(true);

		const updateProduct = {
			basketId: cartId,
			quantityDelta: -1,
			sku: cartProduct.sku,
			bpId: businessPartner?.id,
		};
		updateBasket(accessToken, updateProduct)
			.then((res) => {
				dispatch(
					setCart(formatCartPrices(res, localeContext, currencyCode)),
				);
				setIsProcessing(false);
				return Promise.resolve(res);
			})
			.catch((e) => {
				throw new Error('Product was not removed', e);
			});
	};

	return (
		<div className={classNames(styles.ShoppingCart, className)}>
			<button
				className={classNames(
					styles.ShoppingCart_button,
					cartProducts?.length > 0 &&
						styles.ShoppingCart_button___hasProduct,
				)}
				onClick={() => handleCartVisibility(true)}
				aria-label={dictionary.getValue(
					'ShoppingCart.OpenCart',
					null,
					'Open shopping cart',
				)}
			>
				<Icon size="md" className={styles.ShoppingCart_icon}>
					<CartIcon />
				</Icon>
				<span className={styles.ShoppingCart_text}>
					{shoppingCartText}
				</span>
				{/* Show active cart with total quantity */}
				{cartProducts?.length !== 0 ? (
					<CartQuantity status={total} />
				) : null}
			</button>

			<div
				className={classNames(
					styles.ShoppingCart_window,
					showCart && styles.ShoppingCart_window___visible,
				)}
			>
				<div className={styles.ShoppingCart_header}>
					<p className={styles.ShoppingCart_heading}>
						{dictionary.getValue(
							'ShoppingCart',
							null,
							'Shopping cart',
						)}
					</p>
					<button
						className={styles.ShoppingCart_closeBtn}
						onClick={() => handleCartVisibility(false)}
						aria-label={dictionary.getValue(
							'Actions.Close',
							null,
							'Close',
						)}
					>
						<Icon size="md">
							<CloseIcon />
						</Icon>
					</button>
				</div>
				<div className={styles.ShoppingCart_products}>
					{cartProducts
						?.map((cp) => ({
							...cp,
							price: {
								...cp.price,
								formattedTotal: localeContext.formatPrice(
									cp?.price?.total,
									currencyCode,
								),
							},
						}))
						.map((cartProduct, index) => {
							return (
								<ShoppingCartItem
									key={index}
									cartProduct={cartProduct}
									removeProduct={(cartProduct) =>
										handleRemoval(cartProduct)
									}
									addProduct={(cartProduct) =>
										addProduct(cartProduct)
									}
									decrementProduct={(cartProduct) =>
										handleDecrement(cartProduct)
									}
									handleLinkClick={() =>
										handleCartVisibility(false)
									}
								/>
							);
						})}
				</div>

				<div className={styles.ShoppingCart_checkout}>
					{cartProducts?.length > 0 && (
						<>
							<div className={styles.ShoppingCart_checkoutTotal}>
								<div
									className={
										styles.ShoppingCart_checkoutTotalRow
									}
								>
									<p>
										{dictionary.getValue(
											'Price.SubTotal',
											null,
											'Price subtotal',
										)}
										:
									</p>
									<p>{formattedSum}</p>
								</div>
							</div>
							<Button
								style="primary"
								className={styles.ShoppingCart_checkoutButton}
								onClick={() => {
									handleGoToCheckout();
									handleCartVisibility(false);
								}}
							>
								{dictionary.getValue(
									'Checkout.GoToCheckout',
									null,
									'Go to checkout',
								)}
							</Button>
						</>
					)}
					{!bpId ? (
						<Link
							url={'/my-account/delivery-addresses'}
							className={styles.CheckoutReceipt_link}
						>
							{dictionary.getValue(
								'ShoppingCart.ChooseBusinessPartner',
								null,
								'You must first choose a business partner',
							)}
						</Link>
					) : (
						cartProducts?.length == 0 && (
							<p>
								{dictionary.getValue(
									'ShoppingCart.MissingProducts',
									null,
									"You don't have any products in the shopping cart",
								)}{' '}
							</p>
						)
					)}
				</div>
				{isProcessing && <PageSpinner />}
			</div>
			<div
				className={classNames(
					styles.ShoppingCart_overlay,
					showCart && styles.ShoppingCart_overlay___visible,
				)}
				onClick={() => handleCartVisibility(false)}
			></div>
		</div>
	);
};
