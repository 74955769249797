import classNames from 'classnames';
import { Container } from 'ui/components';
import styles from './Payment.module.scss';

interface PaymentProps {
	className?: string;
	children?: React.ReactElement;
}

export const Payment: React.FC<PaymentProps> = ({
	className,
	children,
}) => {
	return (
		<Container
			width="Standard"
			className={classNames(styles.Payment, className)}
		>
			{children}
		</Container>
	);
};
