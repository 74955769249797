import classNames from 'classnames';

import styles from './Grid.module.scss';

export interface GridProps {
	wrap?: boolean;
	container?: boolean;
	nogutter?: boolean;
	center?: boolean;
	full?: boolean;
	className?: string;
}

export const Grid: React.FC<GridProps> = ({
	wrap,
	container,
	nogutter,
	center,
	full,
	children,
	className,
}) => (
	<div
		className={classNames(
			styles.Grid,
			{ [styles.Grid___wrap]: wrap },
			{ [styles.Grid___container]: container },
			{ [styles.Grid___noGutter]: nogutter },
			{ [styles.Grid___center]: center },
			{ [styles.Grid___full]: full },
			className,
		)}
	>
		{children}
	</div>
);
