import { Paragraph } from 'ui/components/';
import styles from './ShippingDate.module.scss';
import classNames from 'classnames';
import { InformationIcon } from 'ui/components/2-molecules/InformationIcon/InformationIcon';
import { DoubleButton } from 'ui/components/2-molecules/DoubleButton';

export interface ShippingDateProps {
	heading: string;
	buttonLeftText: string;
	buttonRightText: string;
	helpText?: string;
	selectionUpdated?: (left: boolean) => void;
	defaultValue?: boolean;
}

export const ShippingDate: React.FC<ShippingDateProps> = ({
	heading,
	buttonLeftText,
	buttonRightText,
	helpText,
	selectionUpdated,
	defaultValue,
}) => {
	return (
		<div className={styles.ShippingDate}>
			<div className={styles.ShippingDate_centerAlignmentBox}>
				<Paragraph className={styles.ShippingDate_text}>
					{heading}
				</Paragraph>
			</div>

			{helpText && (
				<div className={styles.ShippingDate_centerAlignmentBox}>
					<InformationIcon>{helpText}</InformationIcon>
				</div>
			)}

			<DoubleButton
				textLeft={buttonLeftText}
				textRight={buttonRightText}
				selectionUpdated={selectionUpdated}
				defaultValue={defaultValue}
			/>
		</div>
	);
};
