import styles from './CostDetails.module.scss';
import classNames from 'classnames';
import { Paragraph, Grid } from 'ui/components/';
import { ReactComponent as IconArrowRight } from 'ui/icons/icon_arrow_right.svg';
import { Button } from 'ui/components/';
import {
	CostDetailsState,
	CostTotal,
} from 'application/adapters/store/slices/cartSlice';
import { currentCulture } from 'application/adapters/store/slices/cultureSlice';
import { useSelector } from 'react-redux';

export interface CostDetailsProps {
	costs: CostDetailsState;
	total: CostTotal;
	handleProceed?: () => void;
	currency?: string;
	dictionary?: { [key: string]: string };
	proceedDisabled?: boolean;
	isBasketView?: boolean;
}

export const CostDetails: React.FC<CostDetailsProps> = ({
	costs: {
		handlingFee,
		hazardousFee,
		freight,
		formattedSubtotal,
		formattedHandlingFee,
		formattedHazardousFee,
		formattedFreight,
		formattedTaxTotal,
		formattedOrderTotal,
	},
	total: { orderTotal, subTotal, taxTotal },
	handleProceed,
	currency,
	dictionary,
	proceedDisabled,
	isBasketView,
}) => {
	const culture = useSelector(currentCulture);
	
	return (
		<div className={styles.CostDetails}>
			<div className={styles.CostDetails_view}>
				<div className={styles.CostDetails_headingWrapper}>
					<Paragraph
						style="md"
						className={styles.CostDetails_headingWrapper___heading}
					>
						{!isBasketView
							? dictionary?.costDetailsHeading
							: dictionary?.costDetailsHeadingNextStep}
					</Paragraph>
				</div>
				{!isBasketView && (
					<>
						<div className={classNames(styles.CostDetails_wrapper)}>
							<Grid
								center
								className={classNames(
									styles.CostDetails_data,
									styles.CostDetails_section___top,
								)}
							>
								<Paragraph
									className={styles.CostDetails_data___label}
								>
									{dictionary?.costDetailsSubtotal}
								</Paragraph>
								<Paragraph
									style="md"
									className={styles.CostDetails_data___price}
								>
									{formattedSubtotal}
								</Paragraph>
							</Grid>

							<Paragraph
								className={styles.CostDetails_data___label}
								style="lg"
							>
								{dictionary?.costDetailsFees}
							</Paragraph>

							{handlingFee !== null && (
								<Grid
									center
									className={styles.CostDetails_data}
								>
									<Paragraph
										className={
											styles.CostDetails_data___label
										}
									>
										{dictionary?.costDetailsHandlingFee}
									</Paragraph>
									<Paragraph
										style="md"
										className={
											styles.CostDetails_data___price
										}
									>
										{formattedHandlingFee}
									</Paragraph>
								</Grid>
							)}
							{/* Hazardous fee depends on transportHazard */}
							{hazardousFee !== null && (
								<Grid
									center
									className={classNames(
										styles.CostDetails_data,
									)}
								>
									<Paragraph
										className={
											styles.CostDetails_data___label
										}
									>
										{dictionary?.costDetailsHazardousFee}
									</Paragraph>
									<Paragraph
										style="md"
										className={
											styles.CostDetails_data___price
										}
									>
										{formattedHazardousFee}
									</Paragraph>
								</Grid>
							)}

							{freight !== null && (
								<Grid
									center
									className={classNames(
										styles.CostDetails_data,
										styles.CostDetails_section,
									)}
								>
									<Paragraph
										className={
											styles.CostDetails_data___label
										}
									>
										{dictionary?.costDetailsFreight}
									</Paragraph>
									<Paragraph
										style="md"
										className={
											styles.CostDetails_data___price
										}
									>
										{formattedFreight}
									</Paragraph>
								</Grid>
							)}

							{/* {salesTax !== null && (
								<Grid
									center
									className={classNames(
										styles.CostDetails_data,
										styles.CostDetails_section___ending,
									)}
								>
									<Paragraph
										className={
											styles.CostDetails_data___label
										}
									>
										{dictionary?.costDetailsSalesTax}
									</Paragraph>
									<Paragraph
										style="md"
										className={classNames(
											styles.CostDetails_data___price,
										)}
									>
										{`${displaySalesTax} ${currency}`}
									</Paragraph>
								</Grid>
							)} */}
							{taxTotal !== null && (
								<Grid
									center
									className={classNames(
										styles.CostDetails_data,
										styles.CostDetails_section___ending,
									)}
								>
									<Paragraph
										className={
											styles.CostDetails_data___label
										}
									>
										{dictionary?.costDetailsSalesTax}
									</Paragraph>
									<Paragraph
										style="md"
										className={classNames(
											styles.CostDetails_data___price,
										)}
									>
										{formattedTaxTotal}
									</Paragraph>
								</Grid>
							)}
						</div>
						<div
							className={classNames(
								styles.CostDetails_data___grandTotalWrapper,
							)}
						>
							<Grid
								center
								className={classNames(
									styles.CostDetails_data,
									styles.CostDetails_data___grandTotalCell,
								)}
							>
								<Paragraph
									style="lg"
									className={classNames(
										styles.CostDetails_data___label_grand,
										styles.CostDetails_data___labelGrand,
									)}
								>
									{dictionary?.costDetailsGrandTotal}
								</Paragraph>
								<Paragraph
									style="md"
									className={styles.CostDetails_data___price}
								>
									{formattedOrderTotal}
								</Paragraph>
							</Grid>
						</div>
					</>
				)}
			</div>
			{handleProceed && (
				<div className={classNames(styles.CostDetails_proceed)}>
					<Button
						style="primary"
						disabled={proceedDisabled}
						onClick={handleProceed}
						className={classNames(styles.CostDetails_proceedButton)}
					>
						{dictionary?.costDetailsProceed}
						<IconArrowRight
							className={
								styles.CostDetails_proceedButton___iconRight
							}
						/>
					</Button>
				</div>
			)}
		</div>
	);
};
