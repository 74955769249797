import axios from 'axios';
import { ECOMMERCE_API_URL, CONTENT_API_URL } from './_constants';
import { queryGetCategory } from './_api';
import logger from 'helpers/logger';

export const getProductByIdFromAdapter: (
	productId: string,
	accessToken: any,
	locale: string,
	bpId: string,
) => Promise<Models.Product> = async (productId, accessToken, locale, bpId) => {
	const method = 'GET';
	const headers = {};
	headers['Authorization'] = `Bearer ${accessToken}`;
	let path = `getproductbyguid/?id=${productId}&locale=${locale}`;
	if (bpId) {
		path += `&bpId=${bpId}`;
	}
	const ecommerceUrl = ECOMMERCE_API_URL;
	const endpointUrl = `${ecommerceUrl}${path}`;
	const contentApiUrl = CONTENT_API_URL;
	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	})
		.then((res) => {
			const { data } = res;
			if (!data) {
				return null;
			}
			const product: Models.Product = {
				id: data.guid,
				name: data.displayName ? data.displayName : data.name,
				sku: data.sku,
				price: data.price, //'2253',
				image: data?.primaryImageUrl?.includes('http')
					? data.primaryImageUrl
					: contentApiUrl + data.primaryImageUrl,
				productImages: data.productImagesList.map((x) => {
					return x.includes('http') ? x : contentApiUrl + x;
				}),
				guid: data.guid,
				categories: data.categories,
			};

			return product;
		})
		.catch((error) => {
			logger.error(`getProductByIdFromAdapter ${error}`);
			throw new Error(error);
		});
};

export const getProductBySkuFromAdapter: (
	productId: string,
	accessToken: any,
	locale: string,
	bpId?: string,
	country?: string,
) => Promise<Models.CategoryProduct> = async (
	productId,
	accessToken,
	locale,
	bpId,
	country,
) => {
	const method = 'GET';
	const headers = {};
	headers['Authorization'] = `Bearer ${accessToken}`;
	let path = `getproduct/?sku=${productId}&locale=${locale}&country=${country}`;

	if (bpId) {
		path += `&bpId=${bpId}`;
	}
	const ecommerceUrl = ECOMMERCE_API_URL;
	const endpointUrl = `${ecommerceUrl}${path}`;
	const contentApiUrl = CONTENT_API_URL;
	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	})
		.then((res) => {
			const { data } = res;

			if (!data) {
				return null;
			}

			const transformedCategory: Models.CategoryProduct = {
				name: data.displayName ? data.displayName : data.name,
				sku: data.sku,
				shortDescription: data.shortDescription,
				longDescription: data.longDescription,
				productDescriptionItems: [
					{
						id: 1,
						title: 'Product Description',
						text: data.longDescription,
						safetyDatasheets: data?.safetyDatasheetsModel,
					},
					{
						id: 2,
						title: 'Product Specs',
						text: data.productSpecification,
					},
					{
						id: 3,
						title: 'Resources',
					},
				],
				price: data.price, //'2253',
				image: data?.primaryImageUrl?.includes('http')
					? data.primaryImageUrl
					: contentApiUrl + data.primaryImageUrl,
				productImages: data.productImagesList.map((x) => {
					return x.includes('http') ? x : contentApiUrl + x;
				}),
				hasReplacements:
					data.hasReplacements == 'true' ||
					data.hasReplacements == 'True',
				isDiscontinued:
					data.webshopStatus === 'Inactive' ||
					data.pivotalActive === false
						? true
						: false,
				replacements:
					data.replacements.split(',').filter(Boolean) ?? null,
				transportHazard: data.erpItemSignal === 'G10',
			};

			return transformedCategory;
		})
		.catch((error) => {
			logger.error(`getProductBySkuFromAdapter ${error}`);
			throw new Error(error);
		});
};

export const getProductsByGuid = async (
	guid,
	facets,
	locale,
	accessToken,
	bpId,
	pageSize,
	page,
) => {
	const method = 'POST';
	const headers = {
		Accept: 'application/json, text/plain, */*',
		'Content-Type': 'application/json',
	};
	headers['Authorization'] = `Bearer ${accessToken}`;
	const path = 'postGetProductsForCategory';

	const body = {
		guid: guid,
		page,
		pageSize,
		facets,
		locale,
		bpId,
	};

	const ecommerceUrl = ECOMMERCE_API_URL;
	const endpointUrl = `${ecommerceUrl}${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
		data: body,
	})
		.then((res) => {
			const { data } = res;
			if (data.products)
				for (let i = 0; i < data.products.length; i++) {
					const product = data.products[i];

					if (product?.image && !product.image.includes('http')) {
						product.image =
							process.env.NEXT_PUBLIC_CONTENT_API_URL +
							product.image;
					}
				}
			return {
				facets: data.facets,
				totalPageCount: data.totalPageCount,
				products: data.products.map((p) => ({
					...p,
				})),
			};
		})
		.catch((error) => {
			logger.error(`getProductsByGuid ${error}`);
			throw new Error(error);
		});
};

export const getGlobalCategoryByParent = async (
	parentId,
	accessToken,
	culture,
	bpId,
) => {
	const response = await queryGetCategory(
		parentId,
		accessToken,
		culture,
		bpId,
	);
	const category = response[0];

	const transformedCategory = {
		heading: category?.displayName ?? category?.name,
		categoryNumber: category?.count,
		id: category?.guid,
		categoryFilterItems: category?.subCategories?.map((subCat) => {
			return {
				children: subCat?.displayName ?? subCat?.name,
				categoryNumber: subCat?.count,
				id: subCat?.guid,
			};
		}),
		chosenSortingParameter: null,
	};

	return transformedCategory;
};

export const getAllCategories = async (accessToken, culture, bpId) => {
	const categories = await queryGetCategory(
		undefined,
		accessToken,
		culture,
		bpId,
	);

	const transformedCategories = {
		categoryListItems: categories.map((category) => {
			return {
				link: {
					children: category?.displayName ?? category.name,
					url: `/our-products/${category.name.toLowerCase()}`,
				},
				categoryNumber: category?.count,
				id: category?.guid,
			};
		}),
	};

	return transformedCategories;
};

function convertBasketResponse(data, culture): Models.Basket {
	const transformedCategory: Models.Basket = {
		basketId: data.BasketId,
		culture: data.CultureCode,
		total: {
			discountTotal: data.Total.DiscountTotal,
			orderTotal: data.Total.OrderTotal,
			taxTotal: data.Total.TaxTotal,
			paymentTotal: data.Total.PaymentTotal,
			shippingTotal: data.Total.ShippingTotal,
			subTotal: data.Total.SubTotal,
			vat: data.Total.Vat,
			currency: data.Total.CurrencyCode,
		},
		lines:
			data.Lines?.length > 0
				? data.Lines.map((x) => {
						const cartProduct: Models.CartProduct = {
							price: {
								discount: x.Price.Discount,
								price: x.Price.Price,
								total: x.Price.Total,
								unitDiscount: x.Price.UnitDiscount,
								unitPriceBeforeDiscount:
									x.Price.UnitPriceBeforeDiscount,
								vat: x.Price.VAT,
								currency: data.Total.CurrencyCode,
							},
							name: x.Name,
							quantity: x.Quantity,
							lineGuid: x.LineGuid,
							id: x.Id,
							orderLineId: x.OrderLineId,
							imageUrl: x?.ImageUrl?.includes('http')
								? x?.ImageUrl
								: process.env.NEXT_PUBLIC_CONTENT_API_URL +
								  x?.ImageUrl,
							sku: x.Sku,
							isDiscontinued: x.NotForSale,
							hasReplacements: x.HasReplacements,
							replacements:
								x.Replacements?.split(',').filter(Boolean) ??
								null,
							transportHazardous: x.IsHazardous,
							shipments:
								x.Shipments?.length > 0 // todo put back single map of array
									? x.Shipments.map((shipment) => ({
											earliestDeliveryDate:
												shipment.EarliestDeliveryDate,
											splitShipmentNumber:
												shipment.SplitShipmentNumber,
											quantity: shipment.Quantity,
									  }))
									: [],
						};
						return cartProduct;
				  })
				: [],
		information: {
			additionalDeliveryNotes:
				data.Information.AdditionalDeliveryNotes ?? '',
			additionalReference: data.Information.AdditionalReference ?? '',
			paymentMethod: data.Information.PaymentMethod ?? '',
			purchaseNumberRef: data.Information.PurchaseNumberRef ?? '',
			sDSReceiptEmail: data.Information.SDSReceiptEmail ?? '',
			shippingMethod: data.Information.ShippingMethod ?? '',
			termsAccepted:
				data.Information.TermsAccepted == 'true' ? true : false,
		},
		earliestShipping: data.EarliestShipping,
		deliveryInformation: {
			company: data.DeliveryInformation.bpAdrName,
			address1:
				data.DeliveryInformation.bpAddress +
				' ' +
				data.DeliveryInformation.bpAdrHouseno,
			address2: data.DeliveryInformation.bpAddress2,
			country: data.DeliveryInformation.bpAdrCountry,
			zipCode: data.DeliveryInformation.bpAdrZip,
			bpId: data.DeliveryInformation.bpId,
		},
		fees: data.Fees?.reduce((acc, fee) => {
			acc[`display${fee.Key}Fee`] = fee.Value?.toLocaleString(culture);
			acc[fee.Key.toLowerCase()] = fee.Value;

			return acc;
		}, {}),
		hasCardPayment: data.HasCardPayment,
	};

	return transformedCategory;
}

function convertStripeIntentResponse(data): Models.StripeIntent {
	return {
		clientSecret: data.ClientSecret,
		publishableKey: data.PublishableKey,
		orderGuid: data.OrderGuid,
	};
}

function convertFreightCarrierResponse(data): Models.FreightCarrier {
	return {
		carrierId: data.carrierId,
		isPriorityCarrier: data.isPriorityCarrier,
		carrierWeightUnitIsKg: data.carrierWeightUnitIsKg,
		carrierDisplayName: data.carrierDisplayName,
		zoneChartId: data.zoneChartId,
		rateChartId: data.rateChartId,
	};
}

export const callGetUserBasket: (
	accessToken,
	culture: string,
	bpId: string,
	basketName?: string,
) => Promise<Models.Basket> = async (
	accessToken,
	culture,
	bpId,
	basketName = 'default',
) => {
	const method = 'GET';
	const headers = {};
	headers['Authorization'] = `Bearer ${accessToken}`;
	const path = `?culture=${culture}&bpId=${bpId}&basketName=${basketName}`;
	const basketApiUrl = CONTENT_API_URL;
	const endpointUrl = `${basketApiUrl}umbraco/api/custombasket/userBasket${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	})
		.then((res) => {
			const { data } = res;
			if (data) {
				return convertBasketResponse(data, culture);
			} else {
				return null;
			}
		})
		.catch((error) => {
			logger.error(`callGetUserBasket ${error}`);
			throw new Error(error);
		});
};

export const updateBasket: (
	accessToken,
	updateBasket: {
		culture: string;
		basketId: string;
		sku: string;
		quantityDelta: number;
		bpId: string;
		SkipFeeUpdate: boolean;
		useLNPrices: boolean;
	},
	isQuickOrder?: boolean,
	skipFeeUpdate?: boolean,
) => Promise<Models.Basket> = async (
	accessToken,
	updateBasket,
	isQuickOrder = false,
	skipFeeUpdate = false,
) => {
	const method = 'POST';
	const headers = {
		Accept: 'application/json, text/plain, */*',
		'Content-Type': 'application/json',
	};
	headers['Authorization'] = `Bearer ${accessToken}`;

	const path = 'Update'; //
	const basketApiUrl = CONTENT_API_URL;
	const endpointUrl = `${basketApiUrl}umbraco/api/custombasket/${path}`;

	if (isQuickOrder) {
		updateBasket['useLNPrices'] = true;
	}

	if (skipFeeUpdate) {
		updateBasket['SkipFeeUpdate'] = true;
	}

	/**
	 * By providing ExecutionMode and TestErrorType we can simulate
	 * Errors from eg. LN - This is only used for debugging
	 */
	/* updateBasket['ExecutionMode'] = 2;
	updateBasket['TestErrorType'] = 'ProductMissingFromBasket'; */

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
		data: updateBasket,
	})
		.then((res) => {
			const { data } = res;
			return convertBasketResponse(data, updateBasket.culture);
		})
		.catch((error) => {
			logger.error(`updateBasket ${error}`);
			const errors = error.response.data.errors;
			return Promise.reject(errors);
		});
};

export const callGetProductPrice: (
	accessToken,
	data: Models.GetPriceRequest,
) => Promise<Models.Price> = async (
	accessToken,
	data,
) => {
	const method = 'GET';
	const headers = {
		Accept: 'application/json, text/plain, */*',
		'Content-Type': 'application/json',
	};
	headers['Authorization'] = `Bearer ${accessToken}`;

	const path = 'GetLNPrice'; //
	const basketApiUrl = CONTENT_API_URL;
	const endpointUrl = `${basketApiUrl}umbraco/api/price/${path}?bpId=${data.bpId}&sku=${data.sku}&qty=${data.qty}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	})
		.then((res) => {
			const { data } = res;
			return data;
		})
		.catch((error) => {
			logger.error(`callGetProductPrice ${error}`);
			const errors = error.response.data.errors;
			return Promise.reject(errors);
		});
};

export const updateBasketMultiple: (
	accessToken,
	updateBasketMultiple: {
		culture: string;
		basketId: string;
		bpId: string;
		items: {
			sku: string;
			quantityDelta: number;
		};
	},
) => Promise<Models.BasketMultiple> = async (
	accessToken,
	updateBasketMultiple,
) => {
	const method = 'POST';
	const headers = {
		Accept: 'application/json, text/plain, */*',
		'Content-Type': 'application/json',
	};
	headers['Authorization'] = `Bearer ${accessToken}`;
	// updateBasketMultiple.culture = 'da-DK'; //todo: confirm this is not needed

	const path = 'UpdateMultiple';
	const basketApiUrl = CONTENT_API_URL;
	const endpointUrl = `${basketApiUrl}umbraco/api/custombasket/${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
		data: updateBasketMultiple,
	})
		.then((res) => {
			const { data } = res;
			const convertedData = convertBasketResponse(
				data?.BasketResponse,
				updateBasketMultiple.culture,
			);

			return {
				...convertedData,
				nonExistingProducts: data?.NonExistingProducts,
			};
		})
		.catch((error) => {
			logger.error(`updateBasketMultiple ${error}`);
			throw new Error(error);
		});
};

export const validateBasket: (
	accessToken,
	id: string,
	bpId: string,
	culture: string,
) => Promise<Models.Basket> = async (accessToken, id, bpId, culture) => {
	const method = 'GET';
	const headers = {};
	headers['Authorization'] = `Bearer ${accessToken}`;
	const path = 'validateBasket' + '/?id=' + id + '&bpId=' + bpId;
	const basketApiUrl = CONTENT_API_URL;
	const endpointUrl = `${basketApiUrl}umbraco/api/custombasket/${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	})
		.then((res) => {
			const { data } = res;
			return convertBasketResponse(data, culture);
		})
		.catch((error) => {
			logger.error(`validateBasket ${error}`);
			const errors = error.response.data.errors;
			return Promise.reject(errors);
		});
};

export const updateInformation: (
	accessToken,
	model: any, //todo: create model
) => Promise<Models.Basket> = async (accessToken, model) => {
	const method = 'POST';
	const headers = {
		Accept: 'application/json, text/plain, */*',
		'Content-Type': 'application/json',
	};
	headers['Authorization'] = `Bearer ${accessToken}`;
	const path = 'UpdateInformation'; //
	const basketApiUrl = CONTENT_API_URL;
	const endpointUrl = `${basketApiUrl}umbraco/api/custombasket/${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
		data: model,
	})
		.then((res) => {
			const { data } = res;
			return convertBasketResponse(data, model.culture);
		})
		.catch((error) => {
			logger.error(`updateInformation ${error}`);
			throw new Error(error);
		});
};

export const updateShippingData: (
	accessToken,
	model: any, // todo: create model
) => Promise<Models.Basket> = async (accessToken, model) => {
	const method = 'POST';
	const headers = {
		Accept: 'application/json, text/plain, */*',
		'Content-Type': 'application/json',
	};
	headers['Authorization'] = `Bearer ${accessToken}`;
	const path = 'UpdateShipping'; //
	const basketApiUrl = CONTENT_API_URL;
	const endpointUrl = `${basketApiUrl}umbraco/api/custombasket/${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
		data: model,
	})
		.then((res) => {
			const { data } = res;
			return convertBasketResponse(data, model.culture);
		})
		.catch((error) => {
			logger.error(`updateShippingData ${error}`);
			const errors = error.response.data.errors;
			return Promise.reject(errors);
		});
};

export const getPaymentMethods: (
	accessToken,
	bpId: string,
) => Promise<string[]> = async (accessToken, bpId) => {
	const method = 'GET';
	const headers = {};
	headers['Authorization'] = `Bearer ${accessToken}`;
	const path = 'GetPaymentMethods' + '?bpId=' + bpId;
	const baseUrl = CONTENT_API_URL;
	const endpointUrl = `${baseUrl}umbraco/api/payment/${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	})
		.then((res) => {
			const { data } = res;
			return data;
		})
		.catch((error) => {
			logger.error(`getPaymentMethods ${error}`);
			throw new Error(error);
		});
};

export const createPaymentIntent: (
	accessToken,
	bpId: string,
	basketId: string,
) => Promise<Models.StripeIntent> = async (accessToken, bpId, basketId) => {
	const method = 'POST';
	const headers = {};
	headers['Authorization'] = `Bearer ${accessToken}`;
	const path = 'CreatePayment' + '?bpId=' + bpId + '&basketId=' + basketId;
	const baseUrl = CONTENT_API_URL;
	const endpointUrl = `${baseUrl}umbraco/api/payment/${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	})
		.then((res) => {
			const { data } = res;
			return convertStripeIntentResponse(data);
		})
		.catch((error) => {
			logger.error(`createPaymentIntent ${error}`);
			throw new Error(error);
		});
};

export const createSetupIntent: (
	accessToken,
	bpId: string,
	basketId: string,
) => Promise<Models.StripeIntent> = async (accessToken, bpId, basketId) => {
	const method = 'POST';
	const headers = {};
	headers['Authorization'] = `Bearer ${accessToken}`;
	const path = 'CreateSetup' + '?bpId=' + bpId + '&basketId=' + basketId;
	const baseUrl = CONTENT_API_URL;
	const endpointUrl = `${baseUrl}umbraco/api/payment/${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	})
		.then((res) => {
			const { data } = res;
			return convertStripeIntentResponse(data);
		})
		.catch((error) => {
			logger.error(`createSetupIntent ${error}`);
			throw new Error(error);
		});
};

export const getCarrierOptions: (
	accessToken,
	bpId: string,
	culture: string,
) => Promise<Models.FreightCarrier[]> = async (accessToken, bpId, culture) => {
	const method = 'GET';
	const headers = {};
	headers['Authorization'] = `Bearer ${accessToken}`;
	const path = 'GetCarrierOptions' + '?bpId=' + bpId + '&culture=' + culture;
	const baseUrl = CONTENT_API_URL;
	const endpointUrl = `${baseUrl}umbraco/api/freight/${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	})
		.then((res) => {
			const { data } = res;
			if (data) {
				return data;
			} else {
				return [];
			}
		})
		.catch((error) => {
			logger.error(`getCarrierOptions ${error}`);
			throw new Error(error);
		});
};

export const addOrUpdateOrderFees: (
	accessToken,
	basketId: string,
	bpId: string,
	chosenCarrierId: string,
	culture: string,
) => Promise<Models.Basket> = async (
	accessToken,
	basketId,
	bpId,
	chosenCarrierId = null,
	culture,
) => {
	const method = 'POST';
	const headers = {};
	headers['Authorization'] = `Bearer ${accessToken}`;
	let path =
		'AddOrUpdateOrderFees' + '?basketId=' + basketId + '&bpId=' + bpId;
	if (chosenCarrierId) path += '&chosenCarrierId=' + chosenCarrierId;
	const baseUrl = CONTENT_API_URL;
	const endpointUrl = `${baseUrl}umbraco/api/custombasket/${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	})
		.then((res) => {
			const { data } = res;
			if (data) {
				return convertBasketResponse(data, culture);
			} else {
				return null;
			}
		})
		.catch((error) => {
			logger.error(`addOrUpdateOrderFees ${error}`);
			throw new Error(error);
		});
};

export const removeAllOrderFees: (
	accessToken,
	basketId: string,
	bpId: string,
	culture,
) => Promise<Models.Basket> = async (accessToken, basketId, bpId, culture) => {
	const method = 'DELETE';
	const headers = {};
	headers['Authorization'] = `Bearer ${accessToken}`;
	const path =
		'RemoveAllOrderFees' + '?basketId=' + basketId + '&bpId=' + bpId;
	const baseUrl = CONTENT_API_URL;
	const endpointUrl = `${baseUrl}umbraco/api/custombasket/${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	})
		.then((res) => {
			const { data } = res;
			if (data) {
				return convertBasketResponse(data, culture);
			} else {
				return null;
			}
		})
		.catch((error) => {
			logger.error(`removeAllOrderFees ${error}`);
			throw new Error(error);
		});
};

export const addOrUpdateOrderTax: (
	accessToken,
	basketId: string,
	bpId: string,
	culture: string,
) => Promise<Models.Basket> = async (accessToken, basketId, bpId, culture) => {
	const method = 'POST';
	const headers = {};
	headers['Authorization'] = `Bearer ${accessToken}`;
	const path =
		'AddOrUpdateOrderTax' +
		'?basketId=' +
		basketId +
		'&bpId=' +
		bpId +
		'&culture=' +
		culture;
	const basketApiUrl = CONTENT_API_URL;
	const endpointUrl = `${basketApiUrl}umbraco/api/custombasket/${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	})
		.then((res) => {
			const { data } = res;
			return convertBasketResponse(data, culture);
		})
		.catch((error) => {
			logger.error(`addOrUpdateOrderTax ${error}`);
			throw new Error(error);
		});
};

export const invoicePaymentCheckout: (
	accessToken,
	basketId: string,
) => Promise<string> = async (accessToken, basketId) => {
	const method = 'POST';
	const headers = {};
	headers['Authorization'] = `Bearer ${accessToken}`;
	const path = 'InvoicePaymentCheckout' + '?basketId=' + basketId;
	const basketApiUrl = CONTENT_API_URL;
	const endpointUrl = `${basketApiUrl}umbraco/api/payment/${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	})
		.then((res) => {
			const { data } = res;

			return data.toString();
		})
		.catch((error) => {
			logger.error(`invoicePaymentCheckout ${error}`);
			throw new Error(error);
		});
};

export const getOrderState: (
	accessToken,
	orderId: string,
) => Promise<Models.OrderState> = async (accessToken, orderId) => {
	const method = 'GET';
	const headers = {};
	headers['Authorization'] = `Bearer ${accessToken}`;
	const path = 'GetOrderState' + '?orderGuid=' + orderId;
	const basketApiUrl = CONTENT_API_URL;
	const endpointUrl = `${basketApiUrl}umbraco/api/weborder/${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	})
		.then((res) => {
			const { data } = res;
			const orderState: Models.OrderState = {
				basketId: data.BasketId,
				orderNumber: data.LnOrderNumber,
				orderStatus: data.LNOrderStatus,
				paymentMethodName: data.PaymentMethodName,
				paymentStatusName: data.PaymentStatusName,
				transactionId: data.TransactionId,
				webOrderStatus: data.WebOrderStatus,
				webOrderNumber: data.WebOrderNumber,
			};
			return orderState;
		})
		.catch((error) => {
			logger.error(`getOrderState ${error}`);
			throw new Error(error);
		});
};

export const getBasketQuoteByEmail: (
	accessToken,
	basketId: string,
	bpId: string,
	culture: string,
) => void = async (accessToken, basketId, bpId, culture) => {
	const method = 'POST';
	const headers = {};
	headers['Authorization'] = `Bearer ${accessToken}`;
	const path =
		'GetBasketQuoteByEmail' +
		'?basketId=' +
		basketId +
		'&bpId=' +
		bpId +
		'&culture=' +
		culture;
	const basketApiUrl = CONTENT_API_URL;
	const endpointUrl = `${basketApiUrl}umbraco/api/custombasket/${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	}).catch((error) => {
		logger.error(`getBasketQuoteByEmail ${error}`);
		throw new Error(error);
	});
};

export const deleteUserBasketId: (bpId: string) => void = async (bpId) => {
	const method = 'GET';
	const headers = {};
	const path = 'DeleteUserBasket' + '?bpId=' + bpId;
	const basketApiUrl = CONTENT_API_URL;
	const endpointUrl = `${basketApiUrl}umbraco/api/custombasket/${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	}).catch((error) => {
		logger.error(`deleteUserBasketId ${error}`);
		throw new Error(error);
	});
};
