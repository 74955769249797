import { Heading } from 'ui/components';
import styles from './ModalTab.module.scss';
import classNames from 'classnames';

export interface ProductDescriptionTabProps {
	continent?: string;
	isActive?: boolean;
	setIsActive?: React.Dispatch<React.SetStateAction<any>>;
}

export const ModalTab: React.FC<ProductDescriptionTabProps> = ({
	continent,
	isActive,
	setIsActive,
}) => {
	return (
		<>
			{continent && (
				<li
					className={classNames(
						styles.ModalTab_tab,
						isActive ? styles.isActive : '',
					)}
					onClick={() => {
						setIsActive(continent);
					}}
				>
					<Heading
						headingLevel="h3"
						style="sm"
						className={styles.ModalTab_header}
					>
						{continent}
					</Heading>
				</li>
			)}
		</>
	);
};
