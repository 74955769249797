import classNames from 'classnames';
import styles from './FormfieldSelect.module.scss';
import { ErrorMessage, Label, ConditionalWrapper } from 'ui/components';

export type Option = {
	value: string;
	text: string;
	disabled?: boolean;
	hidden?: boolean;
	selected?: boolean;
};

type State = {
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	isHidden?: boolean;
};

export interface FormfieldSelectProps {
	className?: string;
	id: string;
	multiple?: boolean;
	name?: string;
	label?: string;
	options: Option[];
	size?: number;
	register?: any;
	errorMessage?: string;
	state?: State;
	defaultValue?: string;
	handleChange?: any;
	layout?: 'row' | 'row-slim' | 'column';
}

const ariaLabel = (state: State, label: string) => {
	if (state?.['isHidden']) return label;
};

const ariaError = (state: State, name: string, id: string) => {
	if (state?.['hasError']) return name + id;
};

export const FormfieldSelect: React.FC<FormfieldSelectProps> = ({
	className,
	register,
	errorMessage,
	state,
	id,
	label,
	options,
	name,
	multiple,
	size,
	defaultValue,
	handleChange,
	layout,
}) => {
	const { required, disabled, hasError } = state ?? {};
	return (
		<div
			className={classNames(
				styles.FormfieldSelect,
				className,
				{ [styles[`FormfieldSelect___${layout}`]]: layout },
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
			)}
		>
			{label && (
				<Label
					id={id}
					className={styles.FormfieldSelect_label}
					state={state}
				>
					{label}
				</Label>
			)}

			<ConditionalWrapper
				enabled={layout === 'row' ? true : false}
				className={classNames(styles.FormfieldString__input_wrapper)}
			>
				<div
					className={classNames(styles.FormfieldSelect_wrapper, {
						[styles.isMultiple]: multiple === true,
					})}
				>
					<select
						name={name}
						id={id}
						className={classNames(styles.FormfieldSelect_element, {
							[styles.hasError]: hasError,
						})}
						aria-label={ariaLabel(state, label)}
						aria-describedby={ariaError(state, name, id)}
						multiple={multiple}
						size={size}
						required={required}
						disabled={disabled}
						defaultValue={defaultValue}
						{...(register && { ...register(name, { required }) })}
						onChange={handleChange}
					>
						{options.map((option, index) => (
							<option
								value={option.value}
								disabled={option.disabled}
								hidden={option.hidden}
								key={index}
							>
								{option.text}
							</option>
						))}
					</select>
				</div>
				{hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
			</ConditionalWrapper>
		</div>
	);
};
