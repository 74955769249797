import { useContext } from 'react';
import classNames from 'classnames';
import styles from './ArticleHeader.module.scss';
import { Heading } from 'ui/components';
import { DictionaryContext } from 'application/adapters/context/Dictionary';

export interface ArticleHeaderProps {
	className?: string;
	teaser?: string;
	heading?: React.ReactNode;
	subheading?: string;
	showDate?: boolean;
	updateDate?: string;
}

export const ArticleHeader: React.FC<ArticleHeaderProps> = ({
	className,
	teaser,
	heading,
	subheading,
	showDate = true,
	updateDate,
}) => {
	const dictionary = useContext(DictionaryContext);
	const updatedLabel = dictionary.getValue(
		'Article.UpdatedLabel',
		null,
		'Last updated',
	);

	return (
		<header className={classNames(styles.ArticleHeader, className)}>
			<div className={styles.ArticleHeader_heading}>
				<Heading headingLevel="h1" style="xl">
					{heading}
				</Heading>
			</div>
			<div className={styles.ArticleHeader_subheading}>{subheading}</div>
			<div className={styles.ArticleHeader_lead}>{teaser}</div>
			{showDate && updateDate && (
				<div className={styles.ArticleHeader_date}>
					{updatedLabel}: {updateDate}
				</div>
			)}
		</header>
	);
};
