import classNames from 'classnames';
import styles from './ShoppingCartItem.module.scss';
import React, { useContext, useEffect, useState } from 'react';
import { Icon, Link, Picture } from 'ui/components';
import { ReactComponent as DeleteIcon } from 'ui/icons/icon-trash.svg';
import { DictionaryContext } from 'application/adapters/context/Dictionary';

export interface ShoppingCartItemProps {
	className?: string;
	cartProduct: Models.CartProduct;
	removeProduct: (cartProduct: Models.CartProduct) => void;
	addProduct: (cartProduct: Models.CartProduct) => void;
	decrementProduct: (cartProduct: Models.CartProduct) => void;
	handleLinkClick: () => void;
}

export const ShoppingCartItem: React.FC<ShoppingCartItemProps> = ({
	className,
	cartProduct,
	removeProduct,
	addProduct,
	decrementProduct,
	handleLinkClick,
}) => {
	const product = cartProduct;
	const [price, setPrice] = useState(0);
	const dictionary = useContext(DictionaryContext);

	useEffect(() => {
		setPrice(cartProduct.price.total);
	}, [cartProduct]);

	return (
		<div className={classNames(styles.ShoppingCartItem, className)}>
			<Link
				className={styles.ShoppingCartItem_image}
				url={product?.sku ? `/product/${product?.sku}` : null}
				aria-label={product?.name}
				handleClick={handleLinkClick}
			>
				<Picture
					sizes="33vw"
					url={product?.imageUrl}
					aspectRatio={0.75}
					className={styles.ProductCard_imageElement}
				/>
			</Link>
			<div className={styles.ShoppingCartItem_info}>
				<div className={styles.ShoppingCartItem_section}>
					<Link
						className={styles.ShoppingCartItem_title}
						url={product?.sku ? `/product/${product?.sku}` : null}
						aria-label={product?.name}
						handleClick={handleLinkClick}
					>
						{product?.name}
					</Link>
					<button
						className={styles.ShoppingCartItem_deleteButton}
						onClick={() => removeProduct(cartProduct)}
						aria-label={dictionary.getValue(
							'Product.RemoveAll',
							null,
							'Remove all products',
						)}
					>
						<Icon size="md">
							<DeleteIcon />
						</Icon>
					</button>
				</div>
				{/* <p>{variant?.name}</p> */}
				<div className={styles.ShoppingCartItem_section}>
					<div className={styles.ShoppingCartItem_quantity}>
						<button
							className={styles.ShoppingCartItem_quantityButton}
							onClick={() => decrementProduct(cartProduct)}
							aria-label={dictionary.getValue(
								'Product.Remove',
								null,
								'Remove a product',
							)}
						>
							-
						</button>
						<p>
							{dictionary.getValue(
								'Product.Quantity',
								null,
								'Quantity',
							)}
							: {cartProduct?.quantity}
						</p>
						<button
							className={styles.ShoppingCartItem_quantityButton}
							onClick={() => addProduct(cartProduct)}
							aria-label={dictionary.getValue(
								'Product.Add',
								null,
								'Add a product',
							)}
						>
							+
						</button>
					</div>
					<p>{product?.price?.formattedTotal}</p>
				</div>
			</div>
		</div>
	);
};
