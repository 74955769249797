import classNames from 'classnames';
import styles from './Spinner.module.scss';

export interface SpinnerProps {
	color?: 'light' | 'dark' | 'error';
	size?: 'large' | 'small';
	speed?: 'normal' | 'slow';
	text?: string;
	center?: boolean;
}

export const Spinner: React.FC<SpinnerProps> = ({
	color = 'dark',
	size,
	speed,
	text,
	center,
}) => (
	<>
		<div
			className={classNames(
				styles.Spinner_screenReader,
				styles.Spinner_loadingMsg,
			)}
			role="alert"
		>
			loading...
		</div>
		<div
			className={classNames(
				styles.Spinner,
				styles[`Spinner___${size}`],
				styles[`Spinner___${color}`],
				styles[`Spinner___${speed}`],
				{[styles.Spinner___centered]: center}
			)}
			aria-hidden="true"
		></div>
		{text && <div className={classNames(styles.Spinner___centered, styles.Spinner___text)}>{text}</div>}
	</>
);
