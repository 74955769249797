import React, { useContext } from 'react';
import {
	FormRepositoryFactory,
	FormRepositoryInterface,
} from 'application/repositories/formRepository';

const FormContext = React.createContext<Partial<FormRepositoryInterface>>({});

export interface FormContextProviderProps {
	children?: React.ReactNode;
	formData: Form.ContactForm;
}

export const FormContextProvider: React.FC<FormContextProviderProps> = (
	props,
) => {
	const formRepository = FormRepositoryFactory();

	return (
		<FormContext.Provider value={formRepository}>
			{props.children}
		</FormContext.Provider>
	);
};

export function useFormContext(): Partial<FormRepositoryInterface> {
	return useContext(FormContext);
}
