import React, { useState, useEffect, useRef } from 'react';
import styles from './AccordionItem.module.scss';
import { generateId } from 'helpers/id';
import AnimateHeight from 'react-animate-height';
import { RichText } from 'ui/components';

export type AccordionItemProps = {
	title: string;
	text: string;
	routerPath?: string;
};

export const AccordionItem: React.FC<AccordionItemProps> = ({
	title,
	text,
	routerPath,
}) => {
	const id = generateId();
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	const accordionItemId = title
		? encodeURI(title.split(' ').join('-').toLowerCase())
		: '';
	const accordionRef = useRef<HTMLDivElement>();

	useEffect(() => {
		if (routerPath && accordionRef.current) {
			const itemId = routerPath.substring(
				routerPath.lastIndexOf('#') + 1,
			);

			if (itemId === accordionItemId) {
				setIsOpen(true);
				setTimeout(() => {
					accordionRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				}, 500);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div ref={accordionRef} className={styles.AccordionItemContainer}>
			<h3 className={styles.AccordionItem_triggerWrapper}>
				<button
					onClick={toggle}
					aria-expanded={isOpen}
					className={styles.AccordionItem_trigger}
					aria-controls={`panel-${id}`}
					id={`trigger-${id}`}
				>
					<span className={styles.AccordionItem_title}>
						{title}
						<span className={styles.AccordionItem_icon}></span>
					</span>
				</button>
			</h3>
			<AnimateHeight height={isOpen ? 'auto' : 0} duration={200}>
				<div
					id={`panel-${id}`}
					role="region"
					aria-labelledby={`trigger-${id}`}
					className={styles.AccordionItem_panel}
					hidden={!isOpen}
				>
					<RichText
						content={text}
						className={styles.AccordionItem_text}
					/>
				</div>
			</AnimateHeight>
		</div>
	);
};
