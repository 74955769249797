import React, { useContext } from 'react';
import {
	NavigationRepositoryFactory,
	NavigationRepositoryInterface,
} from 'application/repositories/navigationRepository';

const NavigationContext = React.createContext<
	Partial<NavigationRepositoryInterface>
>({});

export interface NavigationContextProviderProps {
	children?: React.ReactNode;
	navigationData: Navigation.NestedNavigationItem;
}

export const NavigationContextProvider: React.FC<NavigationContextProviderProps> =
	(props) => {
		const navigationRepository = NavigationRepositoryFactory(
			props.navigationData,
		);
		return (
			<NavigationContext.Provider value={navigationRepository}>
				{props.children}
			</NavigationContext.Provider>
		);
	};

export function useNavigationContext(): Partial<NavigationRepositoryInterface> {
	return useContext(NavigationContext);
}
