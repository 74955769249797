import styles from './Main.module.scss';

export interface MainProps {
	header?: React.ReactNode;
	children: React.ReactNode;
}

export const Main: React.FC<MainProps> = ({ header, children }) => (
	<main id="main" className={styles.Main}>
		<header>{header}</header>
		<article className={styles.Main_article}>{children}</article>
	</main>
);
