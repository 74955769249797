import classNames from 'classnames';
import { Link, Paragraph, Picture } from 'ui/components/1-atoms';
import styles from './ProductsInCart.module.scss';
import { ReactComponent as CloseIconRed } from 'ui/icons/icon-close-red.svg';
import { InformationText } from 'ui/components/2-molecules';
import { QuantityInput } from './QuantityInput';
import { MobileShipmentContent } from './MobileShipmentContent';
import { ProductDiscontinuedInfo } from '../ProductDiscontinuedInfo';

export interface ProductsInCartProps {
	product: Models.CartProduct;
	dictionary: { [key: string]: string };
	isBasketView?: boolean;
	onRemoveProduct: (product: Models.CartProduct | Models.OrderLine) => void;
	onProductUpdated: (product: any, delta: any) => Promise<Models.Basket>;
	earliestShipping: boolean;
	isOrderDetailsView: boolean;
}

export const ProductsInCartProduct: React.FC<ProductsInCartProps> = ({
	product,
	dictionary,
	isBasketView,
	onRemoveProduct,
	onProductUpdated,
	earliestShipping,
	isOrderDetailsView,
}) => {
	return (
		<div key={product.sku}>
			<div className={styles.ProductsInCart_container}>
				<div className={styles.ProductsInCart_mobileProductView}>
					<Paragraph
						style="md"
						className={styles.ProductsInCart_label}
					>
						{dictionary.shoppingCartProduct}
					</Paragraph>
					{isBasketView && (
						<div
							className={classNames(
								styles.ProductsInCart_closeIconRed,
								styles.ProductsInCart_closeIconRed___mobile,
							)}
							onClick={() => onRemoveProduct(product)}
						>
							<CloseIconRed />
						</div>
					)}
				</div>

				<div
					className={
						product.imageUrl
							? styles.ProductsInCart_productImage
							: styles.ProductsInCart_productId
					}
				>
					{product.imageUrl ? (
						<Picture
							url={product.imageUrl}
							aspectRatio={0.75}
							className={styles.ProductCard_imageElement}
						/>
					) : (
						product.sku
					)}
				</div>

				<div className={styles.ProductsInCart_info}>
					<Paragraph
						style="sm"
						className={styles.ProductsInCart_productNumber}
					>
						{product.sku} {/* {item.serialValue} */}
					</Paragraph>

					<Link
						url={`/product/${product.sku}`}
						className={styles.ProductsInCart_description}
					>
						{/* Replace with item.desc */}
						{product.name}
					</Link>

					{product.transportHazardous && (
						<InformationText
							className={styles.ProductsInCart_transportHazard}
							text={dictionary.shoppingCartTransportHazard}
							iconText={
								dictionary.shoppingCartTransportHazardInfo
							}
						/>
					)}
				</div>

				{!isBasketView && (
					<div
						className={classNames(
							styles.ProductsInCart_boxView,
							isBasketView &&
								styles.ProductsInCart_boxView___hidden,
						)}
					>
						<Paragraph
							style="md"
							className={styles.ProductsInCart_mobileLabel}
						>
							{dictionary.shoppingCartShipDate}
						</Paragraph>

						<div>
							<>
								<Paragraph
									className={styles.ProductsInCart_boldText}
								>
									{product.shipments?.length > 0
										? product.shipments.at(-1)
												.formattedEarliestDeliveryDate
										: ''}
								</Paragraph>

								{!product.expectedShippingDate ? (
									<>
										{earliestShipping ? (
											<Paragraph style="sm">
												{
													dictionary.shoppingCartEarliestPossible
												}
											</Paragraph>
										) : (
											<Paragraph style="sm">
												{
													dictionary.shoppingCartShipAtLatestDate
												}
											</Paragraph>
										)}
									</>
								) : (
									<Paragraph style="sm">
										{product.expectedShippingDate}
									</Paragraph>
								)}
							</>
						</div>
					</div>
				)}

				<div className={styles.ProductsInCart_boxView}>
					<Paragraph
						style="md"
						className={styles.ProductsInCart_mobileLabel}
					>
						{dictionary.shoppingCartQuantity}
					</Paragraph>

					{isBasketView ? (
						<QuantityInput
							item={product}
							productUpdated={onProductUpdated}
						></QuantityInput>
					) : (
						<Paragraph className={styles.ProductsInCart_boldText}>
							{product.quantity}
						</Paragraph>
					)}
				</div>
				{isOrderDetailsView && (
					<div className={styles.ProductsInCart_boxView}>
						<Paragraph style="sm">{product.orderStatus}</Paragraph>
					</div>
				)}
				{!isBasketView &&
					product.shipments?.length > 1 &&
					product.shipments.map((item, index) => (
						<MobileShipmentContent
							item={item}
							key={index}
						></MobileShipmentContent>
					))}

				{!product.isDiscontinued ? (
					<>
						<div className={styles.ProductsInCart_boxView}>
							<Paragraph
								style="md"
								className={styles.ProductsInCart_mobileLabel}
							>
								{dictionary.shoppingCartPrice}
							</Paragraph>

							{product.price.price !==
							product.price.unitPriceBeforeDiscount ? (
								<>
									<Paragraph
										className={classNames(
											styles.ProductsInCart_boldText,
											styles.ProductsInCart_discountText,
										)}
									>
										{
											product.price
												?.formattedUnitPriceBeforeDiscount
										}
									</Paragraph>

									<Paragraph
										className={
											styles.ProductsInCart_boldText
										}
									>
										{product.price?.formattedPrice}
									</Paragraph>
								</>
							) : (
								<Paragraph
									className={styles.ProductsInCart_boldText}
								>
									{product.price?.formattedPrice}
								</Paragraph>
							)}
						</div>

						<div
							className={classNames(
								styles.ProductsInCart_boxView,
								styles.ProductsInCart_boxView___last,
							)}
						>
							<Paragraph
								style="md"
								className={classNames(
									styles.ProductsInCart_mobileLabel,
									styles.ProductsInCart_mobileLabel___total,
								)}
							>
								{dictionary.shoppingCartTotal}
							</Paragraph>

							<Paragraph
								className={classNames(
									styles.ProductsInCart_boldText,
									styles.ProductsInCart_boldText___total,
								)}
							>
								{product.price?.formattedTotal}
							</Paragraph>
						</div>
					</>
				) : (
					<ProductDiscontinuedInfo
						replacements={product?.replacements}
						hasReplacements={product?.hasReplacements}
					/>
				)}

				{isBasketView && (
					<div
						className={classNames(
							styles.ProductsInCart_closeIconRed,
						)}
						onClick={() => onRemoveProduct(product)}
					>
						<CloseIconRed />
					</div>
				)}
			</div>

			{/* DESKTOP PRODUCT SHIPMENT */}
			{!isBasketView &&
				product.shipments?.length > 1 &&
				product.shipments.map((item, index) => (
					<div key={index}>
						{/* DESKTOP SHIPMENT RENDERING */}
						<div
							key={index}
							className={classNames(
								styles.ProductsInCart_container,
								styles.ProductsInCart_shipment,
							)}
						>
							<div
								className={
									styles.ProductsInCart_shipmentGridBox
								}
							>
								<div
									className={styles.ProductsInCart_mediumText}
								>
									{dictionary.shoppingCartShipment}{' '}
									{item.splitShipmentNumber}
								</div>
							</div>

							<div>
								<Paragraph style="sm">
									{item.formattedEarliestDeliveryDate}
								</Paragraph>
								<Paragraph style="sm">
									{dictionary.shoppingCartEarliestPossible}
								</Paragraph>
							</div>
							<Paragraph
								className={
									styles.ProductsInCart_shipmentDesktopQuantity
								}
							>
								{item.quantity}
							</Paragraph>
							{isOrderDetailsView && (
								<div className={styles.ProductsInCart_boxView}>
									<Paragraph style="sm">
										{product.orderStatus}
									</Paragraph>
								</div>
							)}
							<Paragraph
								className={styles.ProductsInCart_boldText}
							>
								{product?.price?.formattedPrice}
								{/* {item.price} LASTTODO */}
							</Paragraph>
							<Paragraph
								className={styles.ProductsInCart_boldText}
							>
								{product?.price?.formattedTotal}
								{/* {item.total} */}
							</Paragraph>
						</div>
					</div>
				))}
		</div>
	);
};
