import Link, { LinkProps } from 'next/link';

export const NextLink = ({
	href,
	locale,
	children,
}: LinkProps & { children: React.ReactNode }) => {
	return (
		<Link href={href} locale={locale}>
			{children}
		</Link>
	);
};
