import classNames from 'classnames';
import styles from './FormfieldRadio.module.scss';
import { ErrorMessage, Label } from 'ui/components';
import { RegisterOptions } from 'react-hook-form';

export interface FormfieldRadioProps {
	className?: string;
	defaultChecked?: boolean;
	value?: string;
	style?: 'default' | 'circle' | 'star';
	id: string;
	label: string;
	inputName: string;
	fieldGroup?: boolean;
	register: (
		name: string,
		options: RegisterOptions,
	) => { onChange; onBlur; name; ref };
	state?: {
		hasError?: boolean;
		disabled?: boolean;
		required?: boolean;
	};
	onRadioChange?: (value: string) => void;
	errorMessage?: string;
}

export const FormfieldRadio: React.FC<FormfieldRadioProps> = ({
	className,
	value,
	defaultChecked,
	state,
	id,
	label,
	inputName,
	register,
	fieldGroup,
	style = 'default',
	onRadioChange,
	errorMessage,
}) => {
	const { required, disabled, hasError } = state ?? {};
	const labelState = {
		isHidden: false,
		disabled: state?.disabled,
		required: state?.required && !fieldGroup,
		hasError: state?.hasError,
	};

	const { onChange, name, ref } = register(inputName, {
		required: {
			value: required,
			message: 'This field is required',
		},
		onChange: (e) => onRadioChange && onRadioChange(e.target?.value),
	});

	return (
		<div
			className={classNames(
				styles.FormfieldRadio,
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
				styles[`FormfieldRadio___${style}`],
			)}
		>
			<input
				type="radio"
				className={classNames(
					styles.FormfieldRadio_input,
					styles.Input___choice,
				)}
				id={id}
				defaultChecked={defaultChecked}
				disabled={disabled}
				value={value}
				onChange={onChange}
				name={name}
				ref={ref}
			/>
			<Label
				id={id}
				className={styles.FormfieldRadio_label}
				state={labelState}
			>
				{label}
			</Label>
			{hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
		</div>
	);
};
