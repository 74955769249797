import classNames from 'classnames';

export interface ConditionalWrapperProps {
	children: React.ReactNode;
	enabled: boolean;
	className?: string;
}

export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
	children,
	enabled,
	className,
}) => (
	<>
		{enabled ? (
			<div className={classNames(className)}>{children}</div>
		) : (
			children
		)}
	</>
);
