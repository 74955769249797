import { getCmsNavigation } from 'application/adapters/cms/navigationAdapter';

export interface GetNavigationInterface {
	host: string | string[];
	byName?: string;
	accessToken?: string;
	locale: string;
}

export interface NavigationRepositoryInterface {
	getNavigationVisible: ({
		byName: string,
	}?) => Navigation.NestedNavigationItem;
}

export const NavigationRepositoryFactory = (
	data: Navigation.NestedNavigationItem,
): NavigationRepositoryInterface => {
	if (!data) return;
	return {
		// Return visible navigation items.
		// This is only done for the first level for now
		// and could be refined if the need arises.
		getNavigationVisible(args): Navigation.NestedNavigationItem {
			let navigation = data;
			let byName = '';

			if (args) {
				({ byName } = args);
			}

			if (byName) {
				navigation = findByName(byName, navigation);
			}

			const filteredNavigation: Navigation.NestedNavigationItem = {
				id: navigation.id,
				name: navigation.name,
				url: navigation.url,
				decendants:
					navigation.decendants?.filter((item) => {
						return item.hidden !== true;
					}) ?? null,
				title: navigation.title ?? null,
				hidden: navigation.hidden ?? null,
			};

			return filteredNavigation;
		},
	};
};

// Return full set of nested navigation items.
// This is pure data for static props and must be serializable
// Preferably it should have been part of the repository factory function
// but objects cannot be passed as static props
export const getNavigationData = async ({
	host,
	accessToken,
	locale,
}: GetNavigationInterface): Promise<Navigation.NestedNavigationItem> => {
	const navigationResponse = await getCmsNavigation({
		host,
		accessToken,
		locale: locale,
	});

	return navigationResponse;
};

// Return navigation items by its name.
// This is only done for the first level of decendants for now
// and could be refined if the need arises.
const findByName = (
	name: string,
	item: Navigation.NestedNavigationItem,
): Navigation.NestedNavigationItem => {
	const normalizedName = name.toLowerCase();
	if (item.name === normalizedName) {
		return item;
	} else {
		const foundItem = item.decendants.filter((item) => {
			return item.name.toLowerCase() === normalizedName;
		});
		return foundItem.length
			? foundItem[0]
			: {
					id: 0,
					name: '',
					url: '',
			  };
	}
};
