import { useRef, useEffect } from 'react';
import { Paragraph } from 'ui/components';
import styles from './InformationIcon.module.scss';
import { ReactComponent as InfoIcon } from 'ui/icons/icon-information.svg';
import classNames from 'classnames';
import { useState } from 'react';

export const InformationIcon: React.FC = ({ children }) => {
	const [isActive, setIsActive] = useState(false);
	const [isForcedActive, setIsForcedActive] = useState(false);

	const infoIconRef = useRef<HTMLDivElement>(null);
	const useOutsideAlerter = (infoIconRef) => {
		useEffect(() => {
			function handleClickOutside(event) {
				if (
					infoIconRef.current &&
					!infoIconRef.current.contains(event.target)
				) {
					setIsActive(false);
					setIsForcedActive(false);
				}
			}
			// Bind the event listener
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [infoIconRef]);
	};
	useOutsideAlerter(infoIconRef);

	return (
		<div ref={infoIconRef} className={classNames(styles.InformationIcon)}>
			<InfoIcon
				className={classNames(styles.InformationIcon_icon, {
					[styles.InformationIcon_icon___isActive]: isActive,
				})}
				onClick={() => {
					setIsForcedActive(!isForcedActive);
				}}
				onMouseEnter={() => {
					setIsActive(true);
				}}
				onMouseLeave={() => {
					setIsActive(false);
				}}
			/>

			<div
				className={classNames(styles.InformationIcon_bubbleContainer, {
					[styles.InformationIcon_bubbleContainer___isActive]:
						isActive || isForcedActive,
				})}
			>
				<div className={styles.InformationIcon_pointer}></div>
				<Paragraph className={styles.InformationIcon_bubble}>
					{children}
				</Paragraph>
			</div>
		</div>
	);
};
