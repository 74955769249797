import {
	FormfieldCheckbox,
	FormfieldRadio,
	FormfieldString,
	Container,
	Label,
	ErrorMessage,
	Form,
} from 'ui/components';
import styles from './InformationForm.module.scss';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { forwardRef, useImperativeHandle } from 'react';

interface FormData {
	purchaseNumberRef: string;
	sDSReceiptEmail: string;
	termsAccepted: boolean;
	paymentMethod: boolean;
	shippingMethod: boolean;
}

export const InformationForm = forwardRef<
	{ getValues },
	{
		basketInformation: Models.BasketInformation;
		dictionary: { [key: string]: string };
		shippingOptions: Models.FreightCarrier[];
		onShippingChange?: (option) => void;
		hasCardPayment: boolean;
		requiredToggle?: boolean;
	}
>(
	(
		{
			basketInformation,
			dictionary,
			shippingOptions,
			onShippingChange,
			hasCardPayment,
			requiredToggle,
		},
		ref,
	) => {
		const {
			register,
			trigger,
			formState: { errors },
			getValues,
		} = useForm<FormData>({ mode: 'onBlur' });

		useImperativeHandle(ref, () => {
			return {
				getValues: () => getValues(),
				validate: async () => {
					await trigger();
					return errors;
				},
				errors,
			};
		});

		InformationForm.displayName = 'InformationForm';

		return (
			//TODO: Change hardcoded form field labels
			<Container width="Standard" className={styles.InformationForm}>
				<Form className={styles.InformationForm_inner}>
					<div className={styles.InformationForm_fieldset}>
						<FormfieldString
							id="purchaseNumberRef"
							name="purchaseNumberRef" //TODO: All names and placeholders should come from dictionary
							label={dictionary?.purchaseNumberLabel}
							type="text"
							register={register}
							placeholder={dictionary?.purchaseNumberLabel}
							className={styles.InformationForm_field}
							defaultValue={basketInformation?.purchaseNumberRef}
							state={
								errors.purchaseNumberRef
									? {
											hasError: true,
											required: requiredToggle,
									  }
									: { required: requiredToggle }
							}
							errorMessage={
								errors.purchaseNumberRef?.message || 'error'
							}
						/>
						<FormfieldString
							id="sDSReceiptEmail"
							name="sDSReceiptEmail"
							label={dictionary?.sDSReceiptEmailLabel}
							type="email"
							state={
								errors.sDSReceiptEmail
									? {
											hasError: true,
											required: false,
									  }
									: { required: false }
							}
							register={register}
							placeholder={dictionary?.sDSReceiptEmailPlaceholder}
							className={styles.InformationForm_field}
							defaultValue={basketInformation?.sDSReceiptEmail}
							errorMessage={
								errors.sDSReceiptEmail?.message || 'error'
							}
						/>
					</div>
					<div className={styles.InformationForm_fieldset}>
						<FormfieldString
							id="additionalReference"
							name="additionalReference"
							label={dictionary?.additionalReferenceLabel}
							type="text"
							register={register}
							placeholder={
								dictionary?.additionalReferencePlaceholder
							}
							className={styles.InformationForm_field}
							defaultValue={
								basketInformation?.additionalReference
							}
						/>
						<FormfieldString
							id="additionalDeliveryNotes"
							name="additionalDeliveryNotes"
							label={dictionary?.additionalDeliveryNotesLabel}
							type="text"
							register={register}
							placeholder={
								dictionary?.additionalDeliveryNotesPlaceholder
							}
							className={styles.InformationForm_field}
							defaultValue={
								basketInformation?.additionalDeliveryNotes
							}
						/>
					</div>
					<div className={styles.InformationForm_fieldset}>
						<div className={styles.InformationForm_field}>
							<Label
								id="paymentMethodId"
								className={classNames(
									styles.FormfieldString_label,
									styles[`FormfieldString_label___`],
								)}
								state={{ required: true }}
							>
								{dictionary?.paymentMethodLabel}
								{errors.paymentMethod && (
									<ErrorMessage>
										{errors.paymentMethod.message}
									</ErrorMessage>
								)}
							</Label>
							<div className="u-bottom-margin--md">
								<FormfieldRadio
									id="paymentMethod1"
									value="Invoice"
									inputName="paymentMethod"
									label={dictionary?.invoice}
									register={register}
									defaultChecked={
										basketInformation?.paymentMethod ===
										'Invoice'
											? true
											: false
									}
									state={
										errors.paymentMethod
											? {
													hasError: true,
													required: true,
											  }
											: { required: true }
									}
									fieldGroup
								/>
								{hasCardPayment && (
									<FormfieldRadio
										id="paymentMethod2"
										value="Credit"
										inputName="paymentMethod"
										label={dictionary?.creditcard}
										register={register}
										defaultChecked={
											basketInformation?.paymentMethod ===
											'Credit'
												? true
												: false
										}
										state={
											errors.paymentMethod
												? {
														hasError: true,
														required: true,
												  }
												: { required: true }
										}
										fieldGroup
									/>
								)}
							</div>
						</div>
						{shippingOptions.length > 0 && (
							<div className={styles.InformationForm_field}>
								<div>
									<Label
										id="shippingMethodId"
										className={classNames(
											styles.FormfieldString_label,
											styles[`FormfieldString_label___`],
										)}
										state={{ required: true }}
									>
										{dictionary?.uSDeliveryLabel}
										{errors.shippingMethod && (
											<ErrorMessage>
												{errors.shippingMethod.message}
											</ErrorMessage>
										)}
									</Label>
									<div className="u-bottom-margin--md">
										{shippingOptions
											.sort((a, b) =>
												a.carrierDisplayName >
												b.carrierDisplayName
													? 1
													: -1,
											)
											.map((so, idx) => (
												<div key={idx}>
													<FormfieldRadio
														id={so.carrierId}
														value={so.carrierId}
														inputName="shippingMethod"
														label={
															so.carrierDisplayName
														}
														register={register}
														onRadioChange={
															onShippingChange
														}
														// Right now the basket info does not contain information
														// about chosen shipping method, so we cannot preset it

														// defaultChecked={
														// 	basketInformation?.shippingMethod ===
														// 	so.carrierId
														// }
														defaultChecked={false}
														state={
															errors.shippingMethod
																? {
																		hasError:
																			true,
																		required:
																			true,
																  }
																: {
																		required:
																			true,
																  }
														}
													/>
												</div>
											))}
									</div>
								</div>
							</div>
						)}
						<div className={styles.InformationForm_field}>
							<Label
								id="termsAcceptedId"
								className={classNames(
									styles.FormfieldString_label,
									styles[`FormfieldString_label___`],
								)}
							>
								{dictionary?.termsConditionsLabel}
							</Label>
							<div className={styles.InformationForm_checkbox}>
								<FormfieldCheckbox
									id="termsAccepted"
									name="termsAccepted"
									className="u-bottom-margin--md"
									defaultChecked={
										basketInformation?.termsAccepted
									}
									state={
										errors.termsAccepted
											? {
													hasError: true,
													required: true,
											  }
											: { required: true }
									}
									register={register}
									errorMessage={errors.termsAccepted?.message}
									label={dictionary?.termsConditionsText}
								/>
							</div>
						</div>
					</div>
				</Form>
			</Container>
		);
	},
);
