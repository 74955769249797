import classNames from 'classnames';
import styles from './CategoryFilter.module.scss';
import { Button, Heading, Icon } from 'ui/components';
import { ReactComponent as ChevronIcon } from 'ui/icons/icon-chevron.svg';
import { useState } from 'react';
import { Paragraph } from 'ui/components/1-atoms';
import { Container } from 'ui/components/4-habitats';

export interface CategoryFilterProps {
	className?: string;
	categoryFilterItems?: Models.CategoryFilterItem[];
	style?: 'primary' | 'primarySmall' | 'secondary' | 'secondarySmall';
	title?: string;
	type?: 'button' | 'submit' | 'reset';
	disabled?: boolean;
	heading?: React.ReactNode;
	smallHeading?: React.ReactNode;
	categoryNumber?: number;
	sortingParameter?: string;
	handleSelectionChanged?: (item: Models.CategoryFilterItem) => void;
	scrollIntoProductView?: () => void;
}

export const CategoryFilter: React.FC<CategoryFilterProps> = ({
	className,
	categoryFilterItems,
	heading,
	smallHeading,
	categoryNumber,
	sortingParameter,
	handleSelectionChanged,
	scrollIntoProductView,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const toggleOpen = () => {
		setIsOpen(!isOpen);
	};

	const handleSelectedOption = (
		categoryFilterItem: Models.CategoryFilterItem,
	) => {
		handleSelectionChanged(categoryFilterItem);
		setIsOpen(!isOpen);
	};

	return (
		<>
			<Container width="Standard">
				<div className={classNames(styles.CategoryFilter)}>
					{heading && (
						<Heading
							headingLevel="h1"
							style="xl"
							className={styles.CategoryFilter_heading}
						>
							{heading}
							{categoryNumber && (
								<span className={styles.CategoryFilter_number}>
									({categoryNumber})
								</span>
							)}
						</Heading>
					)}
					{smallHeading && (
						<Paragraph
							className={styles.CategoryFilter_smallHeading}
						>
							{smallHeading}
						</Paragraph>
					)}

					<div
						className={classNames(styles.CategoryFilter_container, {
							[styles.CategoryFilter_Modal]:
								className === 'CategoryFilter_Modal',
						})}
					>
						<Button
							className={classNames(
								styles.CategoryFilter_toggle,
								{
									[styles.isOpen]: isOpen,
								},
								{
									[styles.CategoryFilter_Modal]:
										className === 'CategoryFilter_Modal',
								},
							)}
							onClick={toggleOpen}
						>
							{sortingParameter}
							<Icon
								size="xl"
								className={styles.CategoryFilter_buttonIcon}
							>
								<ChevronIcon />
							</Icon>
						</Button>

						<ul
							className={classNames(
								styles.CategoryFilter_wrapper,
								{
									[styles.isOpen]: isOpen,
								},
								{
									[styles.CategoryFilter_Modal]:
										className === 'CategoryFilter_Modal',
								},
							)}
						>
							{categoryFilterItems?.length > 0 &&
								categoryFilterItems.map(
									(
										categoryFilterItem: Models.CategoryFilterItem,
										index,
									) => (
										<li key={index}>
											<Button
												style="secondary"
												className={
													styles.CategoryFilter_button
												}
												{...categoryFilterItem}
												onClick={() => {
													handleSelectedOption(
														categoryFilterItem,
													);
													scrollIntoProductView();
												}}
												activeStyle={sortingParameter}
											/>
										</li>
									),
								)}
						</ul>
					</div>
				</div>
			</Container>
		</>
	);
};
