import { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './Modal.module.scss';
import { ReactComponent as IconCloseCross } from 'ui/icons/icon_close_cross.svg';
import { ReactComponent as StripesIcon } from 'ui/icons/icon-stripes.svg';
import FocusTrap from 'focus-trap-react';
import { Button, Heading, Icon } from 'ui/components';
import { ModalTab } from './ModalTab';
import { ModalTabContent } from './ModalTabContent';
import { ModalProductImages } from './ModalProductImages';
import { LinkProps } from 'ui/components/1-atoms/Action/Link/Link';
import { Grid } from 'ui/components/4-habitats';
import { CategoryFilterProps } from '../CategoryFilter/CategoryFilter';
import { ModalForm } from './ModalForm';

export interface ModalProps {
	className?: string;
	modalType: 'formModal' | 'languageSelectorModal' | 'productDetailsModal';
	title?: string;
	acceptText?: string;
	ariaClose?: string;
	children?: React.ReactNode;
	continents?: Umbraco.Continent[];
	categoryFilter?: CategoryFilterProps;
	handleClose: () => void;
	handleClick?: (...args) => void;
	product?: Models.CategoryProduct;
	activeSlide?: number | string;
}

export const Modal: React.FC<ModalProps> = ({
	className,
	modalType,
	title,
	acceptText,
	ariaClose,
	children,
	continents,
	categoryFilter,
	handleClose,
	handleClick,
	product,
	activeSlide,
}) => {
	const continentsData =
		modalType === 'languageSelectorModal'
			? continents?.map((continent) => continent?.properties)
			: null;
	const [isActive, setIsActive] = useState<string>(
		continentsData && continentsData[0].continentName,
	);

	const closeEscape = (event) => {
		if (event.keyCode === 27) {
			handleClose();
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', closeEscape);

		return () => {
			document.removeEventListener('keydown', closeEscape);
		};
	});
	const cookieConsent = (window as any).CookieConsent;
	const trapOptions = {
		allowOutsideClick:
			!cookieConsent?.consented && !cookieConsent?.declined,
	};

	return (
		<FocusTrap focusTrapOptions={trapOptions}>
			<div
				className={classNames(styles.Modal, className)}
				role="dialog"
				aria-modal={true}
				aria-describedby="dialog-description"
			>
				<div
					className={classNames(
						styles.Modal_overlay,
						'u-center-absolute',
					)}
					onClick={handleClose}
				></div>
				<div
					className={classNames(styles.Modal_modal, {
						[styles.Modal___formModal]: modalType === 'formModal',
						[styles.Modal___languageSelectorModal]:
							modalType === 'languageSelectorModal',
						[styles.Modal___productDetailsModal]:
							modalType === 'productDetailsModal',
					})}
				>
					<button
						className={classNames(styles.Modal_close, {
							[styles.Modal___formModal]:
								modalType === 'formModal',
							[styles.Modal___languageSelectorModal]:
								modalType === 'languageSelectorModal',
							[styles.Modal___productDetailsModal]:
								modalType === 'productDetailsModal',
						})}
						onClick={handleClose}
						aria-label={ariaClose}
					>
						<Icon className={styles.Modal_closeIcon}>
							<IconCloseCross />
						</Icon>
					</button>
					<Icon
						size="xl"
						className={classNames(styles.Modal_icon, {
							[styles.Modal___formModal]:
								modalType === 'formModal',
							[styles.Modal___languageSelectorModal]:
								modalType === 'languageSelectorModal',
							[styles.Modal___productDetailsModal]:
								modalType === 'productDetailsModal',
						})}
					>
						<StripesIcon />
					</Icon>
					{title && (
						<Heading
							headingLevel="h3"
							style={modalType === 'formModal' ? 'xl' : 'lg'}
							className={classNames(styles.Modal_header, {
								[styles.Modal___formModal]:
									modalType === 'formModal',
								[styles.Modal___languageSelectorModal]:
									modalType === 'languageSelectorModal',
							})}
						>
							{title}
						</Heading>
					)}

					{children}

					{/* HELP FORM */}
					{modalType === 'formModal' && categoryFilter && (
						<ModalForm categoryFilter={categoryFilter} />
					)}

					{/* LANGUAGE SELECTOR */}
					{modalType === 'languageSelectorModal' && continentsData && (
						<div className={styles.Modal_tabMenu}>
							<ul className={styles.Modal_tabMenuContainer}>
								{continentsData.map((item, index) => (
									<ModalTab
										key={index}
										continent={item.continentName}
										isActive={
											item.continentName === isActive
												? true
												: false
										}
										setIsActive={setIsActive}
									/>
								))}
								<div
									className={styles.Modal_tabMenuBorder}
								></div>
							</ul>
							<Grid
								className={styles.Modal_tabMenuContentContainer}
							>
								{continentsData.map((item, index) => (
									<ModalTabContent
										key={index}
										countries={item.countries}
										isActive={
											item.continentName === isActive
												? true
												: false
										}
										handleClose={handleClose}
										handleClick={handleClick}
									/>
								))}
							</Grid>
						</div>
					)}

					{/* PRODUCT IMAGES */}
					{modalType === 'productDetailsModal' && product && (
						<ModalProductImages
							product={product}
							activeSlide={activeSlide}
						/>
					)}

					{acceptText && (
						<Button
							style="primary"
							className={styles.Modal_accept}
							onClick={handleClose}
						>
							{' '}
							{acceptText}{' '}
						</Button>
					)}
				</div>
			</div>
		</FocusTrap>
	);
};
