import { buildHeaders } from 'application/services/buildHeaders';
import { ApiError, ApiRedirect } from 'helpers/error';
import axios from 'axios';
import { CONTENT_API_URL, DOMAIN_PORT, DOMAIN_PROTOCOL } from './constants';

export interface NavigationAdapterRequestInterface {
	host: string | string[];
	accessToken?: string;
	locale: string;
}

export interface NavigationAdapterResponseInterface {
	properties: Record<string, unknown>;
	children: Array<NavigationAdapterResponseInterface>;
	id: number;
	culture: string;
	name: string;
	url: string;
	documentType: string;
	template: string;
	createDate: string;
	sortOrder: number;
}

export const getCmsNavigation = ({
	host,
	accessToken,
	locale,
}: NavigationAdapterRequestInterface): Promise<Navigation.NestedNavigationItem> => {
	if (!CONTENT_API_URL) {
		throw new ApiError(
			'CONTENT_API_URL:NOTDEFINED',
			500,
			'Content api url is not defined',
		);
	}

	if (host === 'localhost' && process.env.FALLBACK_HOSTNAME) {
		host = process.env.FALLBACK_HOSTNAME;
	}

	const path = '/';
	const method = 'POST';

	// We cannot make recursive expressions in GraphQL so we
	// assume 5 levels is enough for most use cases
	const query = `{
		navigation: page(path: "${path}") {
			...fields
			children {
				...fields
				children {
					...fields
					children {
						...fields
						children {
							...fields
							children {
								...fields
							}
						}
					}
				}
			}
		}
	}

	fragment fields on PageType {
		id
		name
		url
		properties
	}`;

	const domain = `${DOMAIN_PROTOCOL ? `${DOMAIN_PROTOCOL}://` : ''}${host}${
		DOMAIN_PORT ? `:${DOMAIN_PORT}` : ''
	}/`;

	const headers = buildHeaders({ domain, accessToken, locale });
	// Function to transform JSON to a more friendly structure
	// and discard all irrelevant data
	const transform = (item): Array<Navigation.NestedNavigationItem> => {
		const transformed = item.map((child) => {
			let url = child.url;
			if (url.includes(host)) {
				//TODO: This might just be a hack. Next needs relative urls for this to work correctly but they're returned as full paths. Should probably come from the back end as relative.
				url = url.replace('http://', '').replace('https://', '');
				const splitUrl = url.split('/');
				if (
					splitUrl &&
					splitUrl.length > 0 &&
					splitUrl[0].includes(host)
				) {
					url = '/';
					for (let i = 1; i < splitUrl.length; i++) {
						if (splitUrl[i]) {
							url = `${url}${splitUrl[i]}/`;
						}
					}
				}
			}

			return {
				id: child.id,
				name: child.name,
				url: url,
				...(child.properties.pageSettings.length
					? child.properties.pageSettings.map((pageSetting) => {
							if (pageSetting.documentType === 'navigation') {
								const { umbracoNaviHide, navigationTitle } =
									pageSetting?.content?.properties ?? {};
								return {
									hidden: umbracoNaviHide ? true : false,
									title: navigationTitle
										? navigationTitle
										: null,
								};
							}
							return null;
					  })[0]
					: null),
				decendants: transform(child.children),
			};
		});
		return transformed;
	};

	return axios({
		url: `${CONTENT_API_URL}${path ? path : ''}`,
		method: method,
		maxRedirects: 0,
		headers,
		data: {
			query,
		},
	})
		.then((res) => {
			if (method === 'POST') {
				const { data, errors } = res.data;
				const transformedData: Navigation.NestedNavigationItem = {
					...transform([data.navigation])[0],
				};

				if (errors) {
					throw new Error(errors[0].message);
				}

				return transformedData;
			}

			if (method === 'GET') {
				const { data } = res;
				const transformedData: Navigation.NestedNavigationItem = {
					...transform([data.navigation])[0],
				};

				return transformedData;
			}
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			const statusCode = error?.response?.status || 500;

			if (statusCode === 404) {
				throw new ApiError('PageNotFound', 404, 'Page not found');
			}

			if (statusCode === 301 || statusCode === 302) {
				throw new ApiRedirect(statusCode, error.response.data.url);
			}

			if (statusCode === 500) {
				throw new ApiError('InternalServerError', 500, message);
			}

			throw new ApiError('Unknown error', statusCode, message);
		});
};
