import { Session } from 'next-auth';
import { getToken } from 'next-auth/jwt';
import { SessionProvider, useSession } from 'next-auth/react';
import { DXPToken } from '../../pages/api/auth/[...nextauth]';
import React from 'react';

export interface AuthenticationContextProviderProps {
	children?: React.ReactNode;
	session: Session;
}

export const AuthenticationContextProvider: React.FC<AuthenticationContextProviderProps> =
	(props) => {
		return (
			<SessionProvider session={props.session}>
				{props.children}
			</SessionProvider>
		);
	};

export const useAuthenticationContext = (): Models.Authentication => {
	const { data: loginSession, status } = useSession();
	//TODO: Maybe logout if it has expired?

	return {
		isLoggedIn: status === 'authenticated',
		expires: loginSession?.expires ? new Date(loginSession.expires) : null,
		user: loginSession?.user,
	};
};

export const getAccessToken = async (
	req: Parameters<typeof getToken>[0]['req'],
): Promise<string | null> => {
	const token = (await getToken({ req })) as DXPToken;

	return token?.accessToken;
};
