import styles from './QuantityInput.module.scss';
import { useEffect, useState, useRef, MutableRefObject } from 'react';
import classNames from 'classnames';
import { ReactComponent as RefreshIcon } from 'ui/icons/icon-refresh.svg';
import { ReactComponent as RefreshGrayIcon } from 'ui/icons/icon-refresh-gray.svg';

export interface QuantityInputProps {
	item: Models.CartProduct;
	productUpdated: (product, delta) => void;
}

export const QuantityInput: React.FC<QuantityInputProps> = ({
	item,
	productUpdated,
}) => {
	function usePrevious(value: number) {
		const ref: MutableRefObject<number> = useRef();
		useEffect(() => {
			ref.current = value; //assign the value of ref to the argument
		}, [value]); //this code will run when the value of 'value' changes
		return ref.current; //in the end, return the current ref value.
	}

	const [quantityValue, setQuantityValue] = useState<number>(item.quantity);

	let prevQuantityValue = usePrevious(quantityValue);
	if (prevQuantityValue === undefined) {
		prevQuantityValue = quantityValue;
	}
	const [prevQuantityState, setPrevQuantityState] =
		useState<number>(prevQuantityValue);

	function handleChange(e) {
		const value = e.target.value.replace(/[^\d]/, '');
		if (parseInt(value) !== 0) {
			setQuantityValue(() => {
				return parseInt(value);
			});
		}
	}

	function handleRefresh() {
		const delta = quantityValue - prevQuantityState;
		productUpdated(item, delta);
		setPrevQuantityState(quantityValue);
	}

	useEffect(() => {
		setQuantityValue(item.quantity);
		setPrevQuantityState(item.quantity);
	}, [item.quantity]);

	return (
		<div className={styles.QuantityInput_inputContainer}>
			<input
				type="number"
				className={classNames(
					(isNaN(quantityValue) ||
						quantityValue === undefined ||
						quantityValue === null) &&
						styles.hasError,
				)}
				value={quantityValue}
				onChange={handleChange}
			/>
			<div
				className={classNames(
					styles.QuantityInput_refreshIcon,
					quantityValue !== prevQuantityState &&
						styles.ProductsInCart_refreshIcon___hasError,
				)}
			>
				{quantityValue !== prevQuantityState ? (
					(!(
						isNaN(quantityValue) ||
						quantityValue === undefined ||
						quantityValue === null
					) && <RefreshIcon onClick={handleRefresh} />) || (
						<RefreshGrayIcon />
					)
				) : (
					<RefreshGrayIcon />
				)}
			</div>
		</div>
	);
};
