import { generateId } from './id';

const logger = {
	/**
	 * Logs information
	 *
	 * @param message The message to log
	 */
	info: (message: string, ...args: unknown[]): void => {
		const style = 'background-color: #cce5ff; color: #004085; border-radius: 0.25rem; padding: .2rem 0.4rem; border: 1px solid #b8daff;';
		console.info('%cInfo', style, message, ...args);
	},

	/**
	 * Logs a warning
	 *
	 * @param message The message to log
	 */
	warn: (message: string, ...args: unknown[]): void => {
		console.warn(message, ...args);
	},

	/**
	 * Logs a debug message
	 *
	 * @param message The message to log
	 */
	debug: (message: string, ...args: unknown[]): void => {
		console.debug(message, ...args);
	},

	/**
	 * Logs an error
	 *
	 * @param message The error message
	 */
	error: (message: string, ...args: unknown[]): void => {
		console.error(message, ...args);
	},

	/**
	 * Starts a timer
	 */
	startTimer: (): { done: (message: unknown) => void } => {
		const id = generateId(6);
		console.time(id);
		return {
			done: (message: unknown) => {
				message['timer'] = id;
				console.info(message);
				console.timeEnd(id);
			},
		};
	},
};

export default logger;
