import { InformationIcon, Paragraph } from 'ui/components';
import styles from './InformationText.module.scss';
import classNames from 'classnames';

export interface InformationTextProps {
	className?: string;
	text: string;
	iconText?: string;
}

export const InformationText: React.FC<InformationTextProps> = ({
	className,
	text,
	iconText,
}) => {
	return (
		<div className={classNames(styles.InformationText, className)}>
			<Paragraph className={styles.InformationText_text}>
				{text}
			</Paragraph>
			{iconText && <InformationIcon>{iconText}</InformationIcon>}
		</div>
	);
};
