import classNames from 'classnames';
import styles from './FooterAddressCard.module.scss';
import { useEffect, useState, useContext } from 'react';
import { FormfieldSelect } from '../../Form';
import { Heading, LinkButton, Paragraph } from 'ui/components/1-atoms';
import { SocialLinks } from 'ui/components/3-organisms';
import { DictionaryContext } from 'application/adapters/context/Dictionary';

export interface FooterAddressCardProps {
	offices: Umbraco.Offices[];
	contactLink?: Umbraco.Link;
	socialLinksItems: Umbraco.SocialLink[];
	showSocialLinksFullWidth: boolean;
	dictionary: { [key: string]: string };
}

export const FooterAddressCard: React.FC<FooterAddressCardProps> = ({
	offices,
	contactLink,
	socialLinksItems,
	showSocialLinksFullWidth,
	dictionary,
}) => {
	const [chosenOffice, setChosenOffice] = useState(
		offices && offices[0]?.properties?.title,
	);
	if (!offices) return null;

	const currentOfficeAddress = offices.find(
		(office) => office?.properties?.title === chosenOffice,
	);

	const { street, houseNumber, postalCode, country, city } =
		currentOfficeAddress?.properties?.address[0]?.properties ?? {};

	return (
		<div className={classNames(styles.FooterAddressCard_selectContainer)}>
			<article className={classNames(styles.FooterAddressCard_select)}>
				<Heading
					className={styles.FooterAddressCard_heading}
					headingLevel="h3"
					style="sm"
				>
					{dictionary.footerAddressHeading}
				</Heading>
				<FormfieldSelect
					id="select"
					name="select"
					options={offices.map((office) => ({
						value: office.properties.title,
						text: `${office.properties.address[0].properties.country} - ${office.properties.title} `,
					}))}
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						setChosenOffice(e.target.value);
					}}
				/>

				<div className={styles.FooterAddressCard_adressItems}>
					<Paragraph style="sm">{chosenOffice}</Paragraph>
					<Paragraph style="sm">{`${street} ${houseNumber}`}</Paragraph>
					<Paragraph style="sm">{`${postalCode} ${city}`}</Paragraph>
					<Paragraph style="sm">{country}</Paragraph>
					<Paragraph style="sm">
						{currentOfficeAddress?.properties?.phoneNumber}
					</Paragraph>
				</div>

				<div className={styles.FooterAddressCard_actionsWrapper}>
					{contactLink && (
						<LinkButton
							style="primary"
							target="_blank"
							url={contactLink?.url}
						>
							{contactLink?.name}
						</LinkButton>
					)}
					<SocialLinks
						className={classNames(
							styles.FooterAddressCard_socialLinks,
							showSocialLinksFullWidth
								? styles.FooterAddressCard_showSocialLinksFullWidth
								: '',
						)}
						SocialLinksItems={socialLinksItems}
					/>
				</div>
			</article>
		</div>
	);
};
