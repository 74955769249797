import { useRouter } from 'next/router';
import { useContext } from 'react';
import styles from './ModalTabContent.module.scss';
import classNames from 'classnames';
import { Link } from 'ui/components/1-atoms/Action/Link/Link';
import { Paragraph } from 'ui/components/1-atoms';
import { GridCell } from 'ui/components/4-habitats';

export interface ProductDescriptionContentProps {
	countries?: Umbraco.Continent['properties']['countries'];
	isActive?: boolean;
	handleClose: () => void;
	handleClick: (culture: string, countryName: string) => void;
}

export const ModalTabContent: React.FC<ProductDescriptionContentProps> = ({
	countries,
	isActive,
	handleClose,
	handleClick,
}) => {
	const router = useRouter();

	const languageName = new Intl.DisplayNames('en', {
		type: 'language',
	});

	return (
		<>
			{countries &&
				countries.map((country, index) => (
					<GridCell
						className={classNames(
							styles.ModalTabContent_content,
							isActive ? styles.isActive : '',
						)}
						mobileWidth="100"
						desktopWidth="25"
						key={index}
					>
						<Paragraph
							className={styles.ModalTabContent_country}
							style="sm"
						>
							{country?.properties?.countryName}
						</Paragraph>
						<ul>
							{country.properties.cultureCode.map(
								(culture, index) => (
									<li
										key={index}
										className={
											styles.ModalTabContent_listItem
										}
										onClick={() => {
											handleClick(
												culture as string,
												country?.properties
													?.countryCode ||
													country?.properties
														?.countryName,
											);
											handleClose();
										}}
									>
										<Link
											className={
												styles.ModalTabContent_language
											}
											locale={culture as string}
											url={router.asPath}
										>
											{languageName.of(culture as string)}
										</Link>
									</li>
								),
							)}
						</ul>
					</GridCell>
				))}
		</>
	);
};
