import classNames from 'classnames';
import styles from './Button.module.scss';
import { Icon } from 'ui/components';

export interface ButtonProps {
	children: React.ReactNode;
	style?:
		| 'primary'
		| 'primarySmall'
		| 'secondary'
		| 'secondarySmall'
		| 'transparent';
	title?: string;
	className?: string;
	iconClassName?: string;
	type?: 'button' | 'submit' | 'reset';
	disabled?: boolean;
	onClick?: (event?: React.MouseEvent) => void;
	activeStyle?: string;
	withBackIcon?: React.ReactNode;
	withFrontIcon?: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
	children,
	style,
	title,
	className,
	type,
	disabled,
	onClick,
	activeStyle,
	withBackIcon,
	withFrontIcon,
	iconClassName,
}) => (
	<button
		type={type || 'button'}
		title={title}
		className={classNames(
			styles.Button,
			styles[`Button___${style}`],
			className,
			{ [styles.isCategoryFilterActive]: activeStyle === children },
		)}
		disabled={disabled}
		aria-disabled={disabled}
		onClick={onClick}
	>
		{children}

		{withBackIcon && (
			<Icon
				size="sm"
				className={classNames(
					styles.Button_buttonIcon,
					styles.Button_buttonIcon___back,
					iconClassName,
				)}
			>
				{withBackIcon}
			</Icon>
		)}

		{withFrontIcon && (
			<Icon
				size="sm"
				className={classNames(
					styles.Button_buttonIcon,
					styles.Button_buttonIcon___front,
					iconClassName,
				)}
			>
				{withFrontIcon}
			</Icon>
		)}
	</button>
);
