import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'application/adapters/store/store';

const initialState: Models.BusinessPartner = {
	name: '',
	addresses: [],
	id: '',
	source: '',
	information: null,
	metadata: null,
	paymentOptions: [],
	emails: [],
};

export const customerSlice = createSlice({
	name: 'customer',
	initialState,
	reducers: {
		setCustomer: (state, action: PayloadAction<Models.BusinessPartner>) => {
			return action.payload;
		},
	},
});

export const currentCustomer: (state: RootState) => Models.BusinessPartner = (
	state: RootState,
) => state.customer;

export const { setCustomer } = customerSlice.actions;

export default customerSlice.reducer;
