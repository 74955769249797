import styles from './Pagination.module.scss';
import {
	Button,
	Icon,
	Link,
	PaginationControlProps,
	PaginationItemProps,
	PaginationProps,
} from 'ui/components';
import { ReactComponent as ChevronLeft } from 'ui/icons/icon-chevron-left.svg';
import { ReactComponent as ChevronRight } from 'ui/icons/icon-chevron-right.svg';
import classNames from 'classnames';

export interface PaginationWithButtonsProps extends PaginationProps {
	onPaginationElementClick: (event, page) => void;
}
export interface PaginationWithButtonsPropsProps
	extends PaginationControlProps {
	onPaginationElementClick: (event, page) => void;
	hidden?: boolean;
}

export const PaginationWithButtons: React.FC<PaginationWithButtonsProps> = ({
	controlFirst,
	controlPrev,
	controlNext,
	controlLast,
	paginationItems,
	onPaginationElementClick,
}) => {
	const activeElementIndex = paginationItems.findIndex((item) => item.active);

	return (
		<>
			<ol className={styles.Pagination}>
				{controlFirst && (
					<PaginationControl
						{...controlFirst}
						onPaginationElementClick={(e) =>
							onPaginationElementClick(e, 0)
						}
					>
						<Icon>
							<ChevronLeft />
						</Icon>
					</PaginationControl>
				)}

				{controlPrev && (
					<PaginationControl
						hidden={activeElementIndex === 0}
						{...controlPrev}
						onPaginationElementClick={(e) =>
							onPaginationElementClick(e, -1)
						}
					>
						<Icon>
							<ChevronLeft />
						</Icon>
					</PaginationControl>
				)}

				{paginationItems.map(
					(paginationItem: PaginationItemProps, index) => {
						if (
							paginationItems.length > 5 &&
							index > 2 &&
							index < paginationItems.length - 2
						) {
							return null;
						} else if (
							paginationItems.length > 5 &&
							index === paginationItems.length - 2
						) {
							return (
								<li
									key={index}
									className={classNames(
										styles.Pagination_item,
									)}
								>
									<span
										className={styles.Pagination_link}
									>
										...
									</span>
								</li>
							);
						}

						const label =
							paginationItem.label || (index + 1).toString();

						return (
							<li
								key={index}
								className={classNames(
									styles.Pagination_item,
									{
										[styles.Pagination_item___active]:
											paginationItem.active,
									},
								)}
							>
								<Button
									className={styles.Pagination_link}
									onClick={(event) =>
										onPaginationElementClick(
											event,
											index,
										)
									}
								>
									{label}
								</Button>
							</li>
						);
					},
				)}

				{controlNext && (
					<PaginationControl
						hidden={
							activeElementIndex === paginationItems.length - 1
						}
						{...controlNext}
						onPaginationElementClick={(e) =>
							onPaginationElementClick(e, activeElementIndex + 1)
						}
					>
						<Icon>
							<ChevronRight />
						</Icon>
					</PaginationControl>
				)}

				{controlLast && (
					<PaginationControl
						{...controlLast}
						onPaginationElementClick={(e) =>
							onPaginationElementClick(
								e,
								paginationItems.length - 1,
							)
						}
					>
						<Icon>
							<ChevronRight />
						</Icon>
					</PaginationControl>
				)}
			</ol>
		</>
	);
};

const PaginationControl: React.FC<PaginationWithButtonsPropsProps> = ({
	children,
	direction,
	label = '',
	link,
	title,
	ariaLabel,
	hidden,
	onPaginationElementClick,
}) => {
	const hasLabelStyle =
		label?.length > 0
			? classNames(
					styles.Pagination_item,
					styles.Pagination_item___gutter,
					styles.Pagination_item___mobile,
			  )
			: styles.Pagination_item;
	const classes = classNames(
		hasLabelStyle,
		hidden ? styles.Pagination_item___hide : '',
	);
	return (
		<li className={classes}>
			<Button
				className={classNames(
					styles.Pagination_link,
					styles.Pagination_link___next,
				)}
				onClick={(e: React.MouseEvent<Element>) =>
					onPaginationElementClick(e, 0)
				}
				aria-label={ariaLabel}
			>
				{direction === 'prev' && label}
				{label && (
					<span className={styles.Pagination_chevron}>
						{children}
					</span>
				)}
				{direction === 'next' && label}
			</Button>
		</li>
	);
};
