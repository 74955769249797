const CONTENT_API_URL = process.env.NEXT_PUBLIC_CONTENT_API_URL?.endsWith('/')
	? process.env.NEXT_PUBLIC_CONTENT_API_URL
	: `${process.env.NEXT_PUBLIC_CONTENT_API_URL}/`;

const API_URL = process.env.NEXT_PUBLIC_API_URL?.endsWith('/')
	? process.env.NEXT_PUBLIC_API_URL
	: `${process.env.NEXT_PUBLIC_API_URL}/`;

const DOMAIN_PROTOCOL = process.env.DOMAIN_PROTOCOL ?? 'https';
const DOMAIN_PORT = process.env.DOMAIN_PORT;

export { CONTENT_API_URL, API_URL, DOMAIN_PROTOCOL, DOMAIN_PORT };
