import classNames from 'classnames';
import styles from './Footer.module.scss';
import {
	Container,
	Grid,
	FooterAddressCard,
	GridCell,
	SocialLinks,
} from 'ui/components';
import { FooterCard } from 'ui/components/2-molecules/Card/FooterCard';
import { FooterLanguageSelector } from 'ui/components/2-molecules/Card/FooterLanguageSelector';
import { useSelector } from 'react-redux';

export interface FooterProps {
	className?: string;
	footerCards: Umbraco.FooterCard[];
	contactLink?: Umbraco.Link;
	languageSelectorProps: Umbraco.LanguageSelector['languageSelector']['pages'];
	selectedLocale: string;
	offices: Umbraco.Offices[];
	socialLinksItems: Umbraco.SocialLink[];
	culture: string;
	dictionary: { [key: string]: string };
	handleCountrySelection: (language: string, country: string) => void;
}

const Footer: React.FC<FooterProps> & { Card: React.FC } = ({
	className,
	offices,
	footerCards,
	contactLink,
	socialLinksItems,
	languageSelectorProps,
	selectedLocale,
	culture,
	dictionary,
	handleCountrySelection,
}) => {
	const gridCellWidth =
		footerCards?.length > 4
			? 20
			: Math.round(100 / (footerCards?.length + 1));
	return (
		<footer
			className={classNames(styles.Footer, className)}
		>
			<Container width="Standard">
				<Grid
					wrap={true}
					container={true}
					className={styles.Footer_grid}
				>
					<GridCell
						className={styles.Footer_cell}
						mobileWidth="100"
						desktopWidth={
							!footerCards
								? '50'
								: (gridCellWidth.toString() as Models.GridCellWidth)
						}
					>
						<FooterAddressCard
							offices={offices}
							contactLink={contactLink}
							socialLinksItems={socialLinksItems}
							showSocialLinksFullWidth={
								!footerCards || footerCards?.length <= 1
							}
							dictionary={dictionary}
						/>
					</GridCell>

					{footerCards &&
						footerCards.map((footerCard, index) => (
							<GridCell
								className={styles.Footer_cell}
								key={`cell ${index}`}
								mobileWidth="100"
								desktopWidth={
									gridCellWidth.toString() as Models.GridCellWidth
								}
							>
								<FooterCard
									heading={
										footerCard?.content.properties.heading
									}
									links={footerCard?.content.properties.links}
								/>
								{footerCards.length > 1 && index === 0 && (
									<SocialLinks
										className={styles.Footer_socialLinks}
										SocialLinksItems={socialLinksItems}
									/>
								)}
								{index === footerCards.length - 1 && (
									<FooterLanguageSelector
										selectedLocale={selectedLocale}
										{...languageSelectorProps}
										handleCountrySelection={handleCountrySelection}
									/>
								)}
							</GridCell>
						))}
					{!footerCards && (
						<GridCell
							className={classNames(
								styles.Footer_cell,
								styles.Footer_cellLanguageSelector,
							)}
							mobileWidth="100"
							desktopWidth="50"
						>
							<FooterLanguageSelector
								selectedLocale={selectedLocale}
								{...languageSelectorProps}
								handleCountrySelection={handleCountrySelection}
							/>
						</GridCell>
					)}
				</Grid>
			</Container>
		</footer>
	);
};

Footer.Card = FooterCard;

export { Footer };
