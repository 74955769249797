import { buildHeaders } from 'application/services/buildHeaders';
import { ApiError, ApiRedirect } from 'helpers/error';
import axios from 'axios';
import { CONTENT_API_URL, DOMAIN_PORT, DOMAIN_PROTOCOL } from './constants';
import { RequestInterface } from './contentAdapter';

const getDomain = (host: string | string[]) => `${DOMAIN_PROTOCOL ? `${DOMAIN_PROTOCOL}://` : ''}${host}${DOMAIN_PORT ? `:${DOMAIN_PORT}` : ''}/`;

export const postFormToUrl: (
	url: string,
	data: any,
	accessToken: any,
	locale: string,
	host: string,
) => Promise<any> = async (
	url,
	data,
	accessToken,
	locale,
	host,
) => {
	const method = 'POST';
	const headers = {
		Accept: 'application/json, text/plain, */*',
		'Content-Type': 'application/json',
	};
	headers['Authorization'] = `Bearer ${accessToken}`;
	const contentApiUrl = process.env.NEXT_PUBLIC_CONTENT_API_URL;
	const endpointUrl = `${contentApiUrl}${url}?culture=${locale}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
		data,
	})
		.then((res) => {
			const ok = res.status == 200;
			return ok;
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			const statusCode = error?.response?.status || 500;

			if (statusCode === 404) {
				throw new ApiError('PageNotFound', 404, 'Page not found');
			}

			if (statusCode === 401 || statusCode === 403) {
				// "401 Unauthorized" really means "unauthenticated"
				// "403 Forbidden" really means "unauthorized"
				return error?.response?.data?.data;
			}

			if (statusCode === 301 || statusCode === 302) {
				throw new ApiRedirect(statusCode, error.response.data.url);
			}

			if (statusCode === 500) {
				throw new ApiError('InternalServerError', 500, message);
			}

			throw new ApiError('Unknown error', statusCode, message);
		});
};


// ContactForm
export const getContactForm = <TResponse = any>({
	host,
	locale,
	accessToken,
}: RequestInterface): Promise<TResponse> => {
	if (!CONTENT_API_URL) {
		throw new ApiError(
			'CONTENT_API_URL:NOTDEFINED',
			500,
			'Content api url is not defined',
		);
	}

	if (host === 'localhost' && process.env.FALLBACK_HOSTNAME) {
		host = process.env.FALLBACK_HOSTNAME;
	}

	const domain = getDomain(host);
	const query = `{
		pageSearch(documentType:["modalHelpForm"] limit: 1)
		{
			pages {
				properties
			}
		}
	}`;
	const headers = buildHeaders({
		domain,
		locale,
		accessToken,
	});
	const path = '/';
	const method = 'POST';
	const url = `${CONTENT_API_URL}${path ? path : ''}`;

	return axios({
		url,
		method,
		maxRedirects: 0,
		headers,
		data: {
			query,
		},
	})
		.then((res) => {
			if (method === 'POST') {
				const { data, errors } = res.data;

				if (errors) {
					throw new Error(errors[0].message);
				}

				return {
					content: data.pageSearch.pages[0] || null,
					settings: null,
				};
			}

			if (method === 'GET') {
				const { data } = res;

				return {
					content: data.pageSearch.pages[0] || null,
					settings: null,
				};
			}
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			const statusCode = error?.response?.status || 500;

			if (statusCode === 404) {
				throw new ApiError('PageNotFound', 404, 'Page not found');
			}

			if (statusCode === 401 || statusCode === 403) {
				// "401 Unauthorized" really means "unauthenticated"
				// "403 Forbidden" really means "unauthorized"
				return error?.response?.data?.data;
			}

			if (statusCode === 301 || statusCode === 302) {
				throw new ApiRedirect(statusCode, error.response.data.url);
			}

			if (statusCode === 500) {
				throw new ApiError('InternalServerError', 500, message);
			}

			throw new ApiError('Unknown error', statusCode, message);
		});
};

// SignupForm
export const getSignupForm = <TResponse = any>({
	host,
	locale,
	accessToken,
}: RequestInterface): Promise<TResponse> => {
	if (!CONTENT_API_URL) {
		throw new ApiError(
			'CONTENT_API_URL:NOTDEFINED',
			500,
			'Content api url is not defined',
		);
	}

	if (host === 'localhost' && process.env.FALLBACK_HOSTNAME) {
		host = process.env.FALLBACK_HOSTNAME;
	}

	const domain = getDomain(host);
	const query = `{
		pageSearch(documentType:["modalSignupForm"] limit: 1)
		{
			pages {
				properties
			}
		}
	}`;
	const headers = buildHeaders({
		domain,
		locale,
		accessToken,
	});
	const path = '/';
	const method = 'POST';
	const url = `${CONTENT_API_URL}${path ? path : ''}`;

	return axios({
		url,
		method,
		maxRedirects: 0,
		headers,
		data: {
			query,
		},
	})
		.then((res) => {
			if (method === 'POST') {
				const { data, errors } = res.data;

				if (errors) {
					throw new Error(errors[0].message);
				}

				return {
					content: data.pageSearch.pages[0] || null,
					settings: null,
				};
			}

			if (method === 'GET') {
				const { data } = res;

				return {
					content: data.pageSearch.pages[0] || null,
					settings: null,
				};
			}
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			const statusCode = error?.response?.status || 500;

			if (statusCode === 404) {
				throw new ApiError('PageNotFound', 404, 'Page not found');
			}

			if (statusCode === 401 || statusCode === 403) {
				// "401 Unauthorized" really means "unauthenticated"
				// "403 Forbidden" really means "unauthorized"
				return error?.response?.data?.data;
			}

			if (statusCode === 301 || statusCode === 302) {
				throw new ApiRedirect(statusCode, error.response.data.url);
			}

			if (statusCode === 500) {
				throw new ApiError('InternalServerError', 500, message);
			}

			throw new ApiError('Unknown error', statusCode, message);
		});
};
