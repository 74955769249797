import classNames from 'classnames';
import styles from './MediaStory.module.scss';
import { Heading, Paragraph, Player, Icon } from 'ui/components';
import { ReactComponent as ChevronIcon } from 'ui/icons/icon-chevron.svg';
import { useState } from 'react';
import { LinkButton } from 'ui/components/1-atoms';
import { Container } from 'ui/components/4-habitats';

export interface MediaStoryProps {
	heading: string;
	subHeading: string;
	lead: string;
	videoUrl: string;
	buttonText: string;
	link?: Umbraco.Link;
}

export const MediaStory: React.FC<MediaStoryProps> = ({
	heading = 'Story about polishing',
	subHeading,
	lead,
	videoUrl,
	buttonText,
	link,
}) => {
	const [isActive, setIsActive] = useState(false);
	const toggleVisibility = () => {
		setIsActive(!isActive);
	};

	return (
		<section className={styles.MediaStory}>
			<Container width="Standard">
				{/* HEADING */}
				{heading && (
					<div
						className={classNames(styles.MediaStory_heading, {
							[styles.isClosed]: isActive,
						})}
					>
						<Heading headingLevel="h2" style="md">
							{heading}
						</Heading>
						<div onClick={toggleVisibility}>
							<Icon
								size="xl"
								className={styles.MediaStory_buttonIcon}
							>
								<ChevronIcon />
							</Icon>
						</div>
					</div>
				)}

				<div
					className={classNames(styles.MediaStory_grid, {
						[styles.isClosed]: isActive,
					})}
				>
					<div className={styles.MediaStory_video}>
						{/* MEDIA PLAYER */}
						<Player url={videoUrl} />
					</div>

					<div className={styles.MediaStory_content}>
						{/* SUBHEADER */}
						<Heading
							headingLevel="h3"
							style="sm"
							className={styles.MediaStory_subHeading}
						>
							{subHeading}
						</Heading>

						{/* PARAGRAPH */}
						<Paragraph className={styles.MediaStory_lead}>
							{lead}
						</Paragraph>

						{/* PRIMARY BUTTON */}
						<LinkButton
							style="primary"
							className={styles.MediaStory_button}
							url={link.url}
						>
							{buttonText}
						</LinkButton>
					</div>
				</div>
			</Container>
		</section>
	);
};
