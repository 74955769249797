import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = 'en';
export const cultureSlice = createSlice({
	name: 'culture',
	initialState,
	reducers: {
		setCulture: (state, action: PayloadAction<string>) => {
			if (action.payload) {
				return action.payload;
			} else {
				return state;
			}
		},
	},
});

export const currentCulture: (state: RootState) => string = (
	state: RootState,
) => state.culture;

export const { setCulture } = cultureSlice.actions;
export default cultureSlice.reducer;
