import classNames from 'classnames';
import styles from './FormfieldString.module.scss';
import { ErrorMessage, Label, ConditionalWrapper } from 'ui/components';

type State = {
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	isHidden?: boolean;
	hasTextNoWrap?: boolean;
};

export interface FormfieldStringProps {
	className?: string;
	defaultValue?: string;
	id: string;
	label?: string;
	labelStyle?: 'small';
	name?: string;
	placeholder?: string;
	register?: any;
	handleKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	errorMessage?: string;
	state?: State;
	type:
		| 'text'
		| 'email'
		| 'search'
		| 'number'
		| 'password'
		| 'tel'
		| 'url'
		| 'date';
	min?: number;
	max?: number;
	pattern?: string;
	rules?: Record<string, unknown>;
	layout?: 'row' | 'column';
}

const ariaLabel = (state: State, label: string) => {
	if (state?.['isHidden']) return label;
};

const ariaError = (state: State, type: string, id: string) => {
	if (state?.['hasError']) return type + id;
};

export const FormfieldString: React.FC<FormfieldStringProps> = ({
	className,
	state,
	id,
	label,
	labelStyle,
	type,
	register,
	rules,
	placeholder,
	name,
	defaultValue,
	min,
	max,
	pattern,
	layout,
	errorMessage,
	handleKeyPress,
}) => {
	const { required, disabled, hasError, isHidden } = state ?? {};

	const emailRules =
		type === 'email'
			? {
					pattern: {
						value: new RegExp(
							'(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])',
						),
						message: 'Incorrect email format',
					},
			  }
			: null;

	return (
		<div
			className={classNames(
				styles.FormfieldString,
				className,
				{ [styles['FormfieldString--row']]: layout === 'row' },
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
			)}
		>
			{!isHidden && (
				<Label
					id={id}
					className={classNames(
						styles.FormfieldString_label,
						styles[`FormfieldString_label___${labelStyle}`],
						//	hasError && styles.FormfieldString_label___hasError,
					)}
					state={state}
				>
					{label}
				</Label>
			)}
			<ConditionalWrapper
				enabled={layout === 'row' ? true : false}
				className={classNames(styles.FormfieldString__input_wrapper)}
			>
				<input
					type={type}
					className={classNames(
						styles.FormfieldString_input,
						styles.Input___string,
						{
							[styles.hasError]: hasError,
						},
					)}
					name={name}
					id={id}
					required={required}
					disabled={disabled}
					placeholder={placeholder}
					defaultValue={defaultValue}
					aria-label={ariaLabel(state, label)}
					aria-describedby={ariaError(state, type, id)}
					min={min}
					max={max}
					pattern={pattern}
					{...(register &&
						register(name, {
							required: {
								value: required,
								message: 'This field is required',
							},
							...emailRules,
							...rules,
						}))}
					onKeyPress={(e) => {
						handleKeyPress && handleKeyPress(e);
					}}
				/>
				{hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
			</ConditionalWrapper>
		</div>
	);
};
