export class ApiError extends Error {
	public statusCode: number;
	public url: number;

	constructor(name: string, statusCode: number, message?: string) {
		super(message);
		this.name = name;
		this.statusCode = statusCode;
	}
}

export class ApiRedirect extends Error {
	public statusCode: number;
	public url: string;

	constructor(statusCode: number, url: string) {
		super();
		this.statusCode = statusCode;
		this.url = url;
	}
}

/**
 * TODO: Refactor and combine EcommerceApiError with ApiError
 * The two error types could most likely be consolidated into one
 */
export class EcommerceApiError extends Error {
	public area: string;
	public code: number;
	public message: string;
	public name: string;
	public subjectId: string;

	constructor(
		message: string,
		name?: string,
		code?: number,
		area?: string,
		subjectId?: string,
	) {
		super(message);
		this.message = message;
		this.name = name;
		this.code = code;
		this.area = area;
		this.subjectId = subjectId;
	}
}
