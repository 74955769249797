import classNames from 'classnames';
import { ReactComponent as CompanyLogo } from 'ui/icons/icon-logo.svg';
import styles from './Logo.module.scss';
import { Icon } from 'ui/components';
import { Heading } from 'ui/components';
import { MouseEventHandler } from 'react';

export interface LogoProps {
	className?: string;
	heading?: string;
	isSmall?: boolean;
	logoClicked?: MouseEventHandler<HTMLAnchorElement>;
	href: string;
}

export const Logo: React.FC<LogoProps> = ({
	className,
	heading = 'Struers',
	isSmall,
	logoClicked,
	href,
}) => {
	return (
		<a
			href={href}
			className={classNames(styles.Logo, className)}
			onClick={logoClicked}
		>
			<Heading headingLevel="h6" className={styles.Logo_heading}>
				{heading}
			</Heading>
			<Icon
				className={classNames(
					styles.Logo_asset,
					isSmall && styles.Logo_asset___isSmall,
				)}
				size="xl"
			>
				<CompanyLogo />
			</Icon>
		</a>
	);
};
