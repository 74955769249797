import classNames from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';
import {
	Container,
	FormfieldSelect,
	Heading,
	OrderHistoryItem,
	OrderHistoryItemProps,
} from 'ui/components';
import { Option } from 'ui/components/2-molecules/Form/FormfieldSelect';
import styles from './OrderHistory.module.scss';

export interface OrderHistoryProps {
	className?: string;
	heading?: string;
	dictionary?: { [key: string]: string };
	filterOptions?: Option[];
	orders?: OrderHistoryItemProps[];
	ordersMessage?: string | React.ReactElement;
	locale: string;
	onFilterChange?: (value: string) => void;
}

export const OrderHistory: React.FC<OrderHistoryProps> = ({
	className,
	heading,
	dictionary,
	filterOptions,
	orders,
	ordersMessage,
	locale,
	onFilterChange,
}) => {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		onFilterChange(value);
	};

	return (
		<Container
			width="Standard"
			className={classNames(styles.OrderHistory, className)}
		>
			<div className={styles.OrderHistory_header}>
				{heading && <Heading headingLevel="h3">{heading}</Heading>}
				<div className={styles.OrderHistory_filter}>
					<strong>{dictionary?.filterLabel}</strong>
					<FormfieldSelect
						id={'OrderHistory_select'}
						options={filterOptions}
						handleChange={handleChange}
					/>
				</div>
			</div>
			<div className={styles.OrderHistory_orders}>
				{!orders && ordersMessage}
				{orders &&
					orders
						?.sort(
							(a, b) =>
								b.orderDate.getTime() - a.orderDate.getTime(),
						)
						.map((order) => {
							return (
								<OrderHistoryItem
									dictionary={dictionary}
									{...order}
									locale={locale}
									key={
										order.orderId +
										Math.ceil(Math.random() * 10000000)
									}
								/>
							);
						})}
			</div>
		</Container>
	);
};
