import React from 'react';
import classNames from 'classnames';
import styles from './CartQuantity.module.scss';

export interface CartQuantityProps {
	className?: string;
	status?: number;
}

export const CartQuantity: React.FC<CartQuantityProps> = ({
	className,
	status,
}) => {
	return (
		<div className={classNames(styles.CartQuantity_container, className)}>
			<div className={styles.CartQuantity_wrapper}>
				<p className={styles.CartQuantity_status}>{status}</p>
			</div>
		</div>
	);
};
