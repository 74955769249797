import styles from './ProductSorting.module.scss';
import classNames from 'classnames';
import { useState } from 'react';
import { ReactComponent as ChevronIcon } from 'ui/icons/icon-chevron.svg';
import { Button, Icon } from 'ui/components';
export interface SortingParameter {
	parameter: number;
	text: string;
}

export interface ProductSortingProps {
	className?: string;
	sortingSettings: SortingParameter[];
	chosenSortingParameter: SortingParameter;
	setSorting: (sortingParameter: SortingParameter) => void;
	dictionary: { [key: string]: string };
}

export enum SortingTypes {
	UNSORTED,
	NAME,
	PRICEMIN,
	PRICEMAX,
}

export const ProductSorting: React.FC<ProductSortingProps> = ({
	className,
	sortingSettings,
	chosenSortingParameter,
	setSorting,
	dictionary,
}) => {
	const [sortingVisibility, setSortingVisibility] = useState(false);
	const [sortingParameter, setSortingParameter] = useState(
		chosenSortingParameter,
	);
	const [isActive, setActive] = useState(false);
	const toggleSortingVisibility = () => {
		setSortingVisibility(!sortingVisibility);
		setActive(!isActive);
	};

	const handleSortingChange = (sortingParameter: SortingParameter) => {
		setSortingVisibility(false);
		setSortingParameter(sortingParameter);
		setSorting(sortingParameter);
		setActive(!isActive);
	};

	return (
		<div className={classNames(styles.ProductSorting, className)}>
			<span className={styles.ProductSorting_sortingLabel}>
				{dictionary.searchSortBy}
			</span>
			<div className={styles.ProductSorting_dropdown}>
				<Button
					className={classNames(styles.ProductSorting_toggle, {
						[styles.isOpen]: isActive,
					})}
					onClick={toggleSortingVisibility}
				>
					<span>{sortingParameter.text}</span>
					<Icon
						size="xl"
						className={styles.ProductSorting_buttonIcon}
					>
						<ChevronIcon />
					</Icon>
				</Button>
				{sortingVisibility && (
					<div className={styles.ProductSorting_controls}>
						{sortingSettings.map((sortingParameter, index) => {
							return (
								<Button
									className={
										styles.ProductSorting_parameterButton
									}
									key={index}
									onClick={() =>
										handleSortingChange(sortingParameter)
									}
								>
									{sortingParameter.text}
								</Button>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};
