import {
	Heading,
	Paragraph,
	Picture,
	Link,
	Container,
	Button,
} from 'ui/components';
import styles from './ProductsInCart.module.scss';
import classNames from 'classnames';
import { QuickOrder } from './QuickOrder/QuickOrder';
import { EmailQuote } from './EmailQuote/EmailQuote';
import { ShippingDate } from './ShippingDate';
import { ShippingDetails } from './ShippingDetails';
import { CostDetails, CostDetailsProps } from './CostDetails';
import { PageSpinner } from 'ui/components/1-atoms/Media/PageSpinner';
import logger from 'helpers/logger';
import { EcommerceApiError } from 'helpers/error';
import { ReactComponent as DeleteIcon } from 'ui/icons/icon-trash-small.svg';
import { ProductsInCartProduct } from './ProductsInCartProduct';
export interface ProductsInCartProps {
	showHeading?: boolean;
	products: Models.CartProduct[] | Models.OrderLine[]; // todo this needs serious refactoring, models are used interchangeably and that's not ok
	transportHazard?: boolean;
	isBasketView?: boolean;
	isOrderDetailsView?: boolean;
	shippingAddress?: Models.AddressLines;
	invoiceAddress?: Models.AddressLines;
	costDetails: CostDetailsProps;
	productUpdated?: (product, delta) => Promise<Models.Basket>;
	handleProceed?: () => void;
	shippingUpdated?: (earliest: boolean) => void;
	showShipping?: boolean;
	earliestShipping?: boolean;
	deliveryInformation?: Models.AddressLines;
	currency: string;
	isProcessing?: boolean;
	quickOrderError?: string;
	dictionary: { [key: string]: string };
	ourProductsLink?: string;
	accessToken?: any;
	setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
	errors?: EcommerceApiError[];
	clearBasket?: () => Promise<void>;
}

export const ProductsInCart: React.FC<ProductsInCartProps> = ({
	showHeading = true,
	products,
	transportHazard = true,
	isBasketView,
	isOrderDetailsView = false,
	shippingAddress,
	invoiceAddress,
	costDetails,
	productUpdated,
	handleProceed,
	shippingUpdated,
	showShipping,
	earliestShipping,
	deliveryInformation,
	currency,
	isProcessing = false,
	quickOrderError,
	dictionary,
	ourProductsLink,
	accessToken,
	setIsLoading,
	errors,
	clearBasket,
}) => {
	const isUS =
		shippingAddress?.country === 'US' ||
		deliveryInformation?.country === 'US';
	const removeProduct = (product) => {
		const quantity = -product.quantity;
		productUpdated(product, quantity).then(() => {
			logger.info('product removed');
		});
	};

	const selectionUpdated = (earliest: boolean) => {
		shippingUpdated(earliest);
	};

	const proceedDisabled =
		products.some((p) => p.isDiscontinued) || errors?.length > 0;

	return (
		<Container width="Standard">
			<div className={styles.ProductsInCart}>
				{/* OVERLAY */}
				<div className={styles.ProductsInCart_headingContainer}>
					{showHeading && (
						<>
							<Heading
								headingLevel="h4"
								style="sm"
								className={classNames(
									styles.ProductsInCart_heading,
									styles.ProductsInCart_heading___desktop,
									isBasketView &&
										styles.ProductsInCart_heading___basketView,
								)}
							>
								{dictionary?.shoppingCartProductsInCart}
							</Heading>

							<Heading
								headingLevel="h4"
								style="md"
								className={classNames(
									styles.ProductsInCart_heading,
									styles.ProductsInCart_heading___mobile,
								)}
							>
								{dictionary?.shoppingCartProductsInCart}
							</Heading>
						</>
					)}

					{isBasketView && (
						<div
							className={
								styles.ProductsInCart_emptyTheCartContainer
							}
						>
							<Button
								className={styles.ProductsInCart_emptyTheCart}
								onClick={() => clearBasket()}
							>
								{dictionary?.shoppingCartEmptyTheCart}
							</Button>
							<DeleteIcon />
						</div>
					)}
				</div>

				<div
					className={classNames(
						styles.ProductsInCart_container,
						styles.ProductsInCart_overlay,
					)}
				>
					<Paragraph
						style="md"
						className={styles.ProductsInCart_label}
					>
						{dictionary.shoppingCartProductId}
					</Paragraph>
					<Paragraph
						style="md"
						className={styles.ProductsInCart_label}
					>
						{dictionary.shoppingCartProduct}
					</Paragraph>
					{!isBasketView && (
						<Paragraph
							style="md"
							className={styles.ProductsInCart_label}
						>
							{dictionary.shoppingCartShipDate}
						</Paragraph>
					)}
					<Paragraph
						style="md"
						className={styles.ProductsInCart_label}
					>
						{dictionary.shoppingCartQuantity}
					</Paragraph>

					{isOrderDetailsView && (
						<Paragraph
							style="md"
							className={styles.ProductsInCart_label}
						>
							{dictionary.orderStatus}
						</Paragraph>
					)}
					<Paragraph
						style="md"
						className={styles.ProductsInCart_label}
					>
						{dictionary.shoppingCartPrice}
					</Paragraph>
					<Paragraph
						style="md"
						className={styles.ProductsInCart_label}
					>
						{dictionary.shoppingCartTotal}
					</Paragraph>
				</div>

				<div className={styles.ProductsInCart_dataContainer}>
					{/* LASTTODO */}
					{/* REPLACE PRODUCT VARIANT WITH DIFFERENT IMAGES FROM API */}
					{products?.length > 0 &&
						products.map((product, index) => (
							<ProductsInCartProduct
								key={index}
								product={product}
								dictionary={dictionary}
								isBasketView={isBasketView}
								onRemoveProduct={removeProduct}
								onProductUpdated={productUpdated}
								earliestShipping={earliestShipping}
								isOrderDetailsView={isOrderDetailsView}
							/>
						))}
					{/* QUICK ORDER INPUT PRODUCT ID */}
					{isBasketView && (
						<QuickOrder
							productUpdated={productUpdated}
							quickOrderError={quickOrderError}
							dictionary={dictionary}
						/>
					)}
				</div>
			</div>

			{isOrderDetailsView && (
				<div
					className={classNames(
						styles.ProductsInCart_container,
						styles.ProductsInCart_grandTotalContainer,
					)}
				>
					<Paragraph
						style="md"
						className={classNames(
							styles.ProductsInCart_label,
							styles.ProductsInCart_grandTotalLabel,
						)}
					>
						{dictionary?.productsInCartGrandTotal}
					</Paragraph>
					<Paragraph
						style="md"
						className={classNames(
							styles.ProductsInCart_label,
							styles.ProductsInCart_grandTotal,
						)}
					>
						{costDetails.total.formattedTotal}
					</Paragraph>
				</div>
			)}

			{/* EMAIL QUOTE */}
			{isBasketView && (
				<EmailQuote
					dictionary={dictionary}
					ourProductsLink={ourProductsLink}
					accessToken={accessToken}
					setIsLoading={setIsLoading}
				/>
			)}

			{showShipping && (
				<ShippingDate
					heading={dictionary.shippingDate}
					buttonLeftText={dictionary.shoppingCartEarliestPossible}
					buttonRightText={dictionary.shoppingCartShipAtLatestDate}
					helpText={dictionary.shippingDateHelpText}
					selectionUpdated={selectionUpdated}
					defaultValue={earliestShipping}
				/>
			)}

			{/* SHIPPING DETAILS */}
			{!isBasketView && (
				<ShippingDetails
					shippingAddress={shippingAddress ?? deliveryInformation}
				/>
			)}

			{!isOrderDetailsView && (
				<CostDetails
					total={costDetails.total}
					costs={costDetails.costs}
					handleProceed={handleProceed}
					currency={currency}
					dictionary={dictionary}
					proceedDisabled={proceedDisabled}
					isBasketView={isBasketView}
				/>
			)}
			{isProcessing && <PageSpinner />}
			{errors?.length > 0 && (
				<div className={styles.ProductsInCart_errors}>
					{logger.error(`Errors occurred in the basket`, errors)}
					<h2>
						{dictionary.errorMessageBasketFollowingErrorsOccured}
					</h2>

					{errors.map((error, index) => (
						<div
							className={styles.ProductsInCart_error}
							key={index}
						>
							<strong>{error.area}:</strong> {error.message}
						</div>
					))}
				</div>
			)}
		</Container>
	);
};
