import styles from './ProductCard.module.scss';
import classNames from 'classnames';
import { Picture } from 'ui/components';
import { useContext, useState } from 'react';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { Button, Link, LinkButton } from 'ui/components/1-atoms';

export interface ProductCardProps {
	className?: string;
	columnWidth: Models.GridCellWidth;
	product: Models.Product;
	imageSizes: string;
	isLoggedIn: boolean;
	onAddToCart: (product) => void;
	hasBp: boolean;
}

export const ProductCard: React.FC<ProductCardProps> = ({
	className,
	isLoggedIn,
	onAddToCart,
	hasBp,
	...props
}) => {
	const { product, imageSizes } = props || {};
	const dictionary = useContext(DictionaryContext);
	const [quantityValue, setQuantityValue] = useState<number>(1);

	return (
		<section className={classNames(styles.ProductCard, className)}>
			<Link
				className={styles.ProductCard_link}
				aria-label={product?.link?.name}
				url={'/product/' + product.sku}
			>
				<div className={styles.ProductCard_image}>
					<div className={styles.ProductCard_imageBox}>
						<Picture
							sizes={imageSizes}
							url={product?.image}
							aspectRatio={0.75}
							className={styles.ProductCard_imageElement}
						/>
					</div>
				</div>

				<div className={styles.ProductCard_details}>
					<p className={styles.ProductCard_category}>
						{product?.categories?.length && product?.categories[0]}
					</p>
					{isLoggedIn && hasBp && (
						<p className={styles.ProductCard_meta}>
							{product?.historyLabel} {product?.historyValue}
						</p>
					)}
					<div className={styles.ProductCard_priceAndMeta}>
						<p className={styles.ProductCard_meta}>
							{product?.serialLabel} {product?.sku}
						</p>
						{isLoggedIn && hasBp && product?.price?.price > 0 && (
							<p className={styles.ProductCard_price}>
								{product?.price?.formattedPrice}
							</p>
						)}

					</div>
					
			
					<p className={styles.ProductCard_name}>{product?.name}</p>
					
					{/* ProductCard with variants are not finished. The code is kept if the feature should be used in the future. Look in history, it was deleted 15/06/2022 */}
					{/* {!inStock && (
					<p className={styles.ProductCard_soldOut}>
						{dictionary.getValue(
							'Product.SoldOut',
							null,
							'Sold out',
						)}
					</p>
				)} */}{' '}
					{/* TODO: Figure out how to check stock */}
				</div>
			</Link>
			<div className={styles.ProductCard_actions}>
				<div className={styles.ProductCard_action}>
					{isLoggedIn && hasBp && (
						<input
							type="number"
							className={styles.ProductCard_input}
							value={quantityValue}
							min={1}
							onChange={(e) => {
								const value = e.target.value.replace(
									/[^\d]/,
									'',
								);
								const numericValue = parseInt(value);
								if (numericValue !== 0) {
									setQuantityValue(numericValue);
								}
							}}
						/>
					)}
					{isLoggedIn && hasBp && (
						<Button
							className={styles.ProductCard_cta}
							style="primary"
							onClick={(e) => {
								const target = e.target as HTMLButtonElement;
								target.blur();

								onAddToCart({
									sku: product.sku,
									quantityDelta: quantityValue,
								});
							}}
						>
							{dictionary.getValue(
								'Search.ProductListLoggedInCTA',
								null,
								'Add to cart',
							)}
						</Button>
					)}
				</div>
				<LinkButton
					className={styles.ProductCard_secondaryCta}
					style="secondary"
					url={'/product/' + product.sku}
				>
					{product?.price?.price > 0
						? dictionary.getValue(
								'Search.ProductListNotLoggedInCTA',
								null,
								'View Product',
						  )
						: dictionary.getValue(
								'Search.ProductFetchPriceCTA',
								null,
								'View Price & Product',
						  )}
				</LinkButton>
			</div>
		</section>
	);
};
