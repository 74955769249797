import classNames from 'classnames';
import styles from './Icon.module.scss';

export interface IconProps {
	className?: string;
	children: React.ReactNode;
	ariaHidden?: boolean;
	style?: 'circle';
	size?: 'sm' | 'md' | 'lg' | 'xl';
}

export const Icon: React.FC<IconProps> = ({
	style,
	size = 'md',
	className,
	ariaHidden = true,
	children,
}) => (
	<span
		className={classNames(
			styles.Icon,
			styles[`Icon___${style}`],
			styles[`Icon___${size}`],
			className,
		)}
		aria-hidden={ariaHidden}
	>
		{children}
	</span>
);
