import classNames from 'classnames';
import styles from './FooterLanguageSelector.module.scss';
import { Paragraph, Icon } from 'ui/components';
import { useEffect, useState } from 'react';
import { ReactComponent as ChevronIcon } from 'ui/icons/icon-chevron.svg';
import { Modal } from 'ui/components/3-organisms/Modal';

export interface FooterLanguageSelectorProps {
	className?: string;
	selectedLocale: string;
	continents?: Umbraco.Continent[];
	dropdownLabel?: string;
	modalTitle?: string;
	handleCountrySelection: (language: string, country: string) => void;
}

export const FooterLanguageSelector: React.FC<FooterLanguageSelectorProps> = ({
	className,
	selectedLocale,
	continents,
	dropdownLabel,
	modalTitle,
	handleCountrySelection,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const handleClose = () => setIsOpen(false);

	useEffect(() => {
		if (isOpen) {
			document.querySelector('body').style.overflow = 'hidden';
		} else {
			document.querySelector('body').style.overflow = '';
		}
	}, [isOpen]);

	return (
		<div className={classNames(styles.FooterLanguageSelector, className)}>
			<div className={styles.FooterLanguageSelector_textbox}>
				{continents && (
					<>
						<Paragraph style="sm">{dropdownLabel}</Paragraph>
						<div
							className={
								styles.FooterLanguageSelector_languageSelector
							}
							onClick={() => setIsOpen(true)}
						>
							<Paragraph
								style="sm"
								className={
									styles.FooterLanguageSelector_languageSelectorText
								}
							>
								{selectedLocale}
							</Paragraph>
							<div>
								<Icon
									size="xl"
									className={
										styles.FooterLanguageSelector_buttonIcon
									}
								>
									<ChevronIcon />
								</Icon>
							</div>
						</div>
						{isOpen && (
							<Modal
								title={modalTitle}
								modalType="languageSelectorModal"
								ariaClose={'close'}
								handleClose={handleClose}
								handleClick={handleCountrySelection}
								continents={continents}
							/>
						)}
					</>
				)}
			</div>
		</div>
	);
};
