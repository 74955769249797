import { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './PageSpinner.module.scss';

export interface PageSpinnerProps {
	color?: 'dark' | 'error';
	size?: 'large' | 'small';
	speed?: 'normal' | 'slow';
	delay?: number; // In miliseconds
	text?: string;
}

export const PageSpinner: React.FC<PageSpinnerProps> = ({
	color = 'dark',
	size,
	speed,
	delay = 300,
	text,
}) => {
	const [isRendered, setIsRendered] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setIsRendered(true);
		}, delay);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	if (!isRendered) return null;

	return (
		<div className={classNames(styles.PageSpinner__bg)}>
			<div
				className={classNames(
					styles.PageSpinner_screenReader,
					styles.PageSpinner_loadingMsg,
				)}
				role="alert"
			>
				loading...
			</div>

			<div className={styles.PageSpinner_container}>
				<div
					className={classNames(
						styles.PageSpinner,
						styles[`PageSpinner___${size}`],
						styles[`PageSpinner___${color}`],
						styles[`PageSpinner___${speed}`],
					)}
					aria-hidden="true"
				/>
				{text && <p className={styles.PageSpinner_text}>{text}</p>}
			</div>
		</div>
	);
};
