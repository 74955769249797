import classNames from 'classnames';
import styles from './Avatar.module.scss';
import { Button, Icon } from 'ui/components/1-atoms';
import { ReactComponent as ChevronIcon } from 'ui/icons/icon-chevron.svg';
import { useEffect, useState } from 'react';

export interface AvatarProps {
	onSignIn?: () => void;
	onSignOut?: () => void;
	onSetAvatarActive?: () => void;
	isAvatarActive: boolean;
	profile?: {
		name?: string | null;
		email?: string | null;
		image?: string | null;
	};
	dictionary?: { [key: string]: string };
}

export const Avatar: React.FC<AvatarProps> = ({
	profile,
	onSignIn,
	onSignOut,
	isAvatarActive,
	onSetAvatarActive,
	dictionary,
}) => {
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(profile != null);
	const [accounts, setAccounts] = useState<string[]>([]);

	useEffect(() => {
		setIsLoggedIn(profile != null);
	}, [profile]);
	return (
		<>
			<div className={classNames(styles.Avatar)}>
				{isLoggedIn && (
					<div
						className={classNames(styles.Avatar_container, {
							[styles.isOpen]: isAvatarActive,
						})}
					>
						<Button
							style="secondarySmall"
							className={classNames(
								styles.Avatar_button,
								styles.Avatar_toggle,
								{
									[styles.isOpen]: isAvatarActive,
								},
							)}
							onClick={onSetAvatarActive}
						>
							{`${profile?.name} (${profile?.email})`}
							<Icon
								size="xl"
								className={styles.Avatar_buttonIcon}
							>
								<ChevronIcon />
							</Icon>
						</Button>

						<div className={styles.Avatar_dropdownContainer}>
							{accounts &&
								accounts.map((account, index) => (
									<div
										key={index}
										className={classNames(
											styles.Avatar_wrapper,
											{
												[styles.isOpen]: isAvatarActive,
											},
										)}
									>
										<Button
											style="secondarySmall"
											className={classNames(
												styles.Avatar_button,
												styles.Avatar_dropdownButton,
											)}
										>
											{account}
										</Button>
									</div>
								))}
						</div>
					</div>
				)}

				{!isLoggedIn && (
					<Button
						style="secondarySmall"
						className={classNames(
							styles.Avatar_button,
							styles.Avatar_button___loginButton,
						)}
						onClick={() => {
							onSignIn();
						}}
					>
						{dictionary?.actionLogin}
					</Button>
				)}

				{isLoggedIn && (
					<Button
						style="secondary"
						className={classNames(
							styles.Avatar_button,
							styles.Avatar_button___logoutButton,
						)}
						onClick={onSignOut}
					>
						Logout
					</Button>
				)}
			</div>
		</>
	);
};
