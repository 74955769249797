import classNames from 'classnames';
import styles from './VideoCard.module.scss';
import { Heading, Player } from 'ui/components';

export interface VideoCardProps {
	className?: string;
	poster?: Umbraco.Image;
	kicker?: string;
	heading?: string;
	text?: string;
	mediaURL: string;
	orientation: Umbraco.Orientation;
	disableLightPlayer: boolean;
	playing: boolean;
	loop: boolean;
	controls: boolean;
}

export const VideoCard: React.FC<VideoCardProps> = ({
	className,
	poster,
	mediaURL,
	kicker,
	heading,
	text,
	orientation,
	disableLightPlayer,
	playing,
	loop,
	controls,
}) => {
	return (
		<div
			className={classNames(
				styles.VideoCard,
				'u-bottom-padding--md',
				{ [styles.VideoCard___row]: orientation === 'Horizontal' },
				{ [styles.VideoCard___rowReverse]: orientation === 'Horizontal Reverse' },
				className,
			)}
		>
			<div className={styles.VideoCard_media}>
				<span className={styles.VideoCard_mediabox}>
					<Player
						url={mediaURL}
						poster={poster.url}
						isLight={!disableLightPlayer}
						playing={playing}
						loop={loop}
						controls={!controls}
					/>
				</span>
			</div>

			<div className={styles.VideoCard_textbox}>
				<div className={styles.VideoCard_kicker}>{kicker}</div>
				<Heading headingLevel="h3">{heading}</Heading>
				<div className={styles.VideoCard_text} dangerouslySetInnerHTML={{ __html: text }}></div>
			</div>
		</div>
	);
};
