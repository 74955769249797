import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	LoggedIn as ProductCardLoggedIn,
	NotLoggedIn as ProductCardNotLoggedIn,
	ManyVariants as ProductCardManyVariants,
	FewVariants as ProductCardFewVariants,
	SoldOut as ProductCardSoldOut,
} from 'ui/components/2-molecules/Card/ProductCard/ProductCard.stories';
import { RootState } from 'application/adapters/store/store';

export const getProducts = createAsyncThunk(
	'products/getProducts',
	async () => {
		try {
			const productsPlaceholder = await fetch(
				'//jsonplaceholder.typicode.com/posts',
			);
			await productsPlaceholder.json();
			if (productsPlaceholder) {
				const hardCodedProducts = [
					ProductCardLoggedIn.args.product,
					ProductCardNotLoggedIn.args.product,
					ProductCardManyVariants.args.product,
					ProductCardFewVariants.args.product,
					ProductCardSoldOut.args.product,
				];
				return hardCodedProducts;
			}
		} catch (error) {
			throw Error(error);
		}
	},
);

interface ProductsState {
	products: Models.Product[];
}

const initialState: ProductsState = {
	products: [],
};

const productsSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getProducts.fulfilled, (state, action) => {
			state.products = action.payload;
		});
	},
});

export const getProductBySku = (productId: string) => (state: RootState) => {
	const product = state.products.products.find((product) => {
		return product.id === productId;
	});
	if (product) return product;
	if (!product) throw Error(`Product id: ${productId} not found in products`);
};

export default productsSlice.reducer;
