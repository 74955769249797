import classNames from 'classnames';
import styles from './LinkButton.module.scss';
import { NextLink } from '../Link/NextLink/NextLink';
import { Icon } from 'ui/components';

export interface LinkButtonProps {
	children: React.ReactNode;
	state?: 'inactive';
	style?: 'primary' | 'secondary' | 'inactive' | 'sm';
	title?: string;
	className?: string;
	url?: string;
	target?: string;
	withBackIcon?: React.ReactNode;
	withFrontIcon?: React.ReactNode;
	iconClassName?: string;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
	children,
	style,
	title,
	className,
	state,
	url,
	target,
	withBackIcon,
	withFrontIcon,
	iconClassName,
}) => {
	return (
		<NextLink href={url ?? '/'}>
			<a
				target={target}
				title={title}
				className={classNames(
					styles.LinkButton,
					styles[`LinkButton___${style}`],
					styles[`LinkButton___${state}`],
					{
						[styles.LinkButton___hasIcon]:
							withBackIcon || withFrontIcon,
					},
					className,
				)}
			>
				{withFrontIcon && (
					<Icon
						size="sm"
						className={classNames(
							styles.LinkButton_buttonIcon,
							styles.LinkButton_buttonIcon___front,
							iconClassName,
						)}
					>
						{withFrontIcon}
					</Icon>
				)}

				{children}

				{withBackIcon && (
					<Icon
						size="sm"
						className={classNames(
							styles.LinkButton_buttonIcon,
							styles.LinkButton_buttonIcon___back,
							iconClassName,
						)}
					>
						{withBackIcon}
					</Icon>
				)}
			</a>
		</NextLink>
	);
};
