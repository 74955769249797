import React from 'react';
import classNames from 'classnames';
import styles from './Subheading.module.scss';

export interface SubheadingProps {
	children: React.ReactNode;
	className?: string;
}

export const Subheading: React.FC<SubheadingProps> = ({
	children,
	className,
}) => <p className={classNames(styles.Subheading, className)}>{children}</p>;
