import classNames from 'classnames';
import styles from './BlurbCard.module.scss';
import { Heading, GridCell } from 'ui/components';

export const BlurbCard: React.FC<Models.BlurbCard> = ({ link, className, heading, kicker, text, settings, date }) => {
	const { desktopWidth, mobileWidth } = settings?.properties;

	return (
		<GridCell desktopWidth={desktopWidth} mobileWidth={mobileWidth}>
			<div className={classNames(styles.BlurbCard, className)}>
				<a className={styles.BlurbCard_link} href={link?.url} target={link?.target} title={link?.name}>
					<div className={styles.BlurbCard_textbox}>
						{kicker && <div className={styles.BlurbCard_kicker}>{kicker}</div>}
						<Heading headingLevel="h3">{heading}</Heading>
						{date && <div className={styles.BlurbCard_date}>{date}</div>}
						<div className={styles.BlurbCard_text} dangerouslySetInnerHTML={{ __html: text }}></div>
					</div>
				</a>
			</div>
		</GridCell>
	);
};
