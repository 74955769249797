import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = '';
export const orderSlice = createSlice({
	name: 'order',
	initialState,
	reducers: {
		setCurrentOrder: (state, action: PayloadAction<string>) => {
			if (action.payload) {
				return action.payload;
			} else {
				return state;
			}
		},
	},
});

export const currentOrder: (state: RootState) => string = (state: RootState) =>
	state.order;

export const { setCurrentOrder } = orderSlice.actions;
export default orderSlice.reducer;
