import React from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SliderItem, SliderItemProps } from './SliderItem/SliderItem';
import styles from './Slider.module.scss';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export interface SliderProps {
	className?: string;
	slides: SliderItemProps[];
}

export const Slider: React.FC<SliderProps> = ({ slides }) => {
	return (
		<Swiper
			spaceBetween={50}
			slidesPerView={3}
			navigation
			pagination={{
				clickable: true,
				type: 'bullets',
			}}
			className={styles.Slider}
			breakpoints={{
				// when window width is >= 320px
				320: {
					slidesPerView: 1,
					spaceBetween: 20,
				},
				// when window width is >= 640px
				640: {
					slidesPerView: 2,
					spaceBetween: 40,
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 40,
				},
			}}
		>
			{slides.map((slide, index) => (
				<SwiperSlide key={index} className={styles.Slider_slide}>
					<SliderItem {...slide} />
				</SwiperSlide>
			))}
		</Swiper>
	);
};
