import { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
	Button,
	Container,
	ErrorMessage,
	Heading,
	Icon,
	Spinner,
} from 'ui/components';
import styles from './CheckoutForm.module.scss';
import {
	PaymentElement,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import { ReactComponent as StripeLogo } from 'ui/icons/stripe-logo.svg';

interface CheckoutFormProps {
	className?: string;
	heading?: string;
	isButtonDisabled: boolean;
	buttonText: string;
	statusMessages: { [key: string]: string };
	amount: number;
	currency: string;
}

export const CheckoutForm: React.FC<CheckoutFormProps> = ({
	className,
	heading,
	isButtonDisabled,
	buttonText,
	statusMessages,
	amount,
	currency,
}) => {
	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get(
			'payment_intent_client_secret',
		);

		if (!clientSecret) {
			return;
		}

		stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
			switch (setupIntent.status) {
				case 'succeeded':
					setMessage(statusMessages.succeeded);
					break;
				case 'processing':
					setMessage(statusMessages.processing);
					break;
				case 'requires_payment_method':
					setMessage(statusMessages.requiresPaymentMethod);
					break;
				default:
					setMessage(statusMessages.failed);
					break;
			}
		});
	}, [stripe]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const { error } = await stripe.confirmSetup({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: window.location.toString(),
			},
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (error.type === 'card_error' || error.type === 'validation_error') {
			setMessage(error.message);
		} else {
			setMessage('An unexpected error occurred.');
		}

		setIsLoading(false);
	};

	return (
		<Container
			width="Skinny"
			className={classNames(styles.CheckoutForm, className)}
		>
			<form onSubmit={handleSubmit}>
				<Heading
					className={styles.CheckoutForm_heading}
					headingLevel="h3"
				>
					{heading}
				</Heading>
				
				<Icon className={styles.CheckoutForm_image}>
					<StripeLogo />
				</Icon>
				<PaymentElement className={styles.CheckoutForm_payment} />
				<Heading headingLevel="h1">
					{amount} {currency}
				</Heading>
				<Button
					type="submit"
					className={styles.CheckoutForm_button}
					style="primary"
					disabled={isButtonDisabled}
				>
					<span>
						{isLoading ? (
							<Spinner size="small" color="light" speed="slow" />
						) : (
							buttonText
						)}
					</span>
				</Button>
				{message && (
					<ErrorMessage
						className={styles.CheckoutForm_paymentMessage}
						style="md"
					>
						{message}
					</ErrorMessage>
				)}
			</form>
		</Container>
	);
};
