import { Heading, Paragraph } from 'ui/components/';
import styles from './ShippingDetails.module.scss';
import classNames from 'classnames';
import { FormfieldRadio } from 'ui/components/2-molecules';
import React, { useContext } from 'react';
import { DictionaryContext } from 'application/adapters/context/Dictionary';

export interface ShippingDetailsProps {
	shippingAddress?: Models.AddressLines;
}

export const ShippingDetails: React.FC<ShippingDetailsProps> = ({
	shippingAddress,
}) => {
	const dictionary = useContext(DictionaryContext);
	const shippingAddressData = (
		shippingAddress: ShippingDetailsProps['shippingAddress'],
	) => {
		return (
			<div className={styles.ShippingDetails_data}>
				{shippingAddress?.displayAddress && (
					shippingAddress.displayAddress.split('\n').map((line, index) => (
					<Paragraph key={index} style="xs">{line}</Paragraph>)
					)
				)}
			</div>
		);
	};

	const shippingType = (shippingAddress) => {
		if (shippingAddress === 'USA')
			return (
				<form className={styles.ShippingDetails_data}>
					<FormfieldRadio
						id="FedEx"
						inputName="radio"
						label="FedEx"
						defaultChecked={true}
						register={() => null}
					/>
					<FormfieldRadio
						id="FedEx Air"
						inputName="radio"
						label="FedEx Air"
						register={() => null}
					/>
					<FormfieldRadio
						id="Ship collect"
						inputName="radio"
						label="Ship collect"
						register={() => null}
					/>
				</form>
			);
	};

	return (
		<div className={styles.ShippingDetails}>
			<div className={styles.ShippingDetails_container}>
				<Heading
					headingLevel="h4"
					style="md"
					className={classNames(styles.ShippingDetails_heading)}
				>
					{dictionary.getValue(
						'Checkout.ShippingDetails',
						null,
						'Shipping Details',
					)}
				</Heading>
				<div
					className={classNames(styles.ShippingDetails_data_wrapper)}
				>
					<div>
						<Paragraph
							style="md"
							className={styles.ShippingDetails_label}
						>
							{dictionary.getValue(
								'Checkout.ShippingAddress',
								null,
								'Shipping address',
							)}
						</Paragraph>
						{shippingAddressData(shippingAddress)}
					</div>
					<div>
						<Paragraph
							style="md"
							className={styles.ShippingDetails_label}
						>
							{/* Commented out invoice address
							TODO: Reimplement correct shipping address */}
							&nbsp;
							{/* {dictionary.getValue(
							'Checkout.InvoiceAddress',
							null,
							'Invoice address',
						)} */}
						</Paragraph>
						{/* {invoiceAddressData(invoiceAddress)} */}
					</div>
					{shippingAddress?.country === 'USA' ? (
						<div>
							<Paragraph
								style="md"
								className={styles.ShippingDetails_label}
							>
								{dictionary.getValue(
									'Checkout.ShippingType',
									null,
									'Shipping type',
								)}
							</Paragraph>
							{shippingType(shippingAddress?.country)}
						</div>
					) : null}
					{/* Empty cells for grid display on desktop */}
					{shippingAddress?.country !== 'USA' ? (
						<div>
							<Paragraph
								style="md"
								className={classNames(
									styles.ShippingDetails_label,
									styles.ShippingDetails_label__emptycell,
								)}
							>
								&emsp;
							</Paragraph>
						</div>
					) : null}
					<div>
						<Paragraph
							style="md"
							className={classNames(
								styles.ShippingDetails_label,
								styles.ShippingDetails_label__emptycell,
							)}
						>
							&emsp;
						</Paragraph>
					</div>
				</div>
			</div>
		</div>
	);
};
