import React from 'react';
import { PageContentResponse } from 'application/repositories/pageContentRepository';

export class Page {
	private _pageType: PageContentResponse;

	public constructor(pageType: PageContentResponse) {
		this._pageType = pageType;
	}

	public get template(): string {
		return this._pageType?.page?.template;
	}

	public get name(): string {
		return this._pageType?.page?.name;
	}

	public get settings(): Umbraco.HomePage['properties']['pageSettings'] {
		return this.pageType?.page?.properties?.pageSettings;
	}

	public get pageType(): PageContentResponse {
		return this._pageType;
	}

	public get id(): number {
		return this._pageType?.page?.id;
	}

	public get url(): string {
		return this._pageType?.page?.url;
	}

	public get content(): Umbraco.HomePage['properties'] {
		return this.pageType?.page?.properties;
	}

	public get languageVariantUrls(): Umbraco.HomePage['availableUrls'] {
		return this.pageType?.page?.availableUrls;
	}

	public set languageVariantUrls(languageVariantUrls) {
		if (this.pageType?.page) {
			this.pageType.page.availableUrls = languageVariantUrls;
		}
	}

	public get footer(): PageContentResponse['footer'] {
		return this.pageType?.footer;
	}

	public getSettings(key: 'siteTheme'): Umbraco.SiteTheme['content']['properties'];
	public getSettings(key: 'navigation'): Umbraco.Navigation['content']['properties'];
	public getSettings(key: 'openGraph'): Umbraco.OpenGraph['content']['properties'];
	public getSettings(
		key: 'siteTheme' | 'navigation' | 'openGraph',
	): (Umbraco.SiteTheme | Umbraco.Navigation | Umbraco.OpenGraph)['content']['properties'] {
		const setting = this.settings.find((setting) => setting.documentType === key);
		return setting?.content?.properties;
	}
}

export const PageContext = React.createContext<Partial<Page>>(new Page(null));
