import React from 'react';
import { ProductCard, ProductCardProps } from './ProductCard';
import { Story, Meta } from '@storybook/react';

export default {
	title: 'Molecules/Card/ProductCard',
	component: ProductCard,
} as Meta;

const Template: Story<ProductCardProps> = (args) => <ProductCard {...args} />;

export const LoggedIn: Story<ProductCardProps> = Template.bind({});
LoggedIn.args = {
	columnWidth: '50',
	imageSizes: '(max-width: 768px) 50vw, 50vw',
	isLoggedIn: true,
	product: {
		id: '1',
		name: 'productName',
		price: {
			price: 400,
			referencePrice: 440,
			currencyCode: 'EUR',
		},
		categories: ['one'],
		historyLabel: 'Purchased on',
		historyValue: '15/08/2021',
		serialLabel: 'Prod.no:',
		sku: '05046922',
		ctaLoggedIn: 'Add to cart',
		ctaNotLoggedIn: 'View product',
		image: {
			url: 'https://e-shop.struers.com/US/EN/images/Item_40500098_250_300_png',
			properties: {
				altText: 'Alternative text',
			},
		},
		link: {
			url: '#',
		},
		variants: [
			{
				id: '1',
				type: 'sizes',
				name: 'S',
				price: 152,
				quantity: 3,
			},
		],
	} as Models.Product,
	hasBp: true,
};

export const NotLoggedIn: Story<ProductCardProps> = Template.bind({});
NotLoggedIn.args = {
	columnWidth: '50',
	imageSizes: '(max-width: 768px) 50vw, 50vw',
	isLoggedIn: false,
	product: {
		id: '1',
		name: 'productName',
		price: {
			price: 400,
			referencePrice: 440,
			currencyCode: 'EUR',
		},
		categories: ['category'],
		historyLabel: 'Purchased on',
		historyValue: '15/08/2021',
		serialLabel: 'Prod.no:',
		sku: '05046922',
		ctaLoggedIn: 'Add to cart',
		ctaNotLoggedIn: 'View product',
		image: {
			url: 'https://e-shop.struers.com/US/EN/images/Item_40500098_250_300_png',
			properties: {
				altText: 'Alternative text',
			},
		},
		link: {
			url: '#',
		},
		variants: [
			{
				id: '1',
				type: 'sizes',
				name: 'S',
				price: 152,
				quantity: 3,
			},
		],
	} as Models.Product,
	hasBp: false,
};
// The following versions of ProductCard with variants are not finished
// The code is kept if the feature should be used in the future
export const ManyVariants: Story<ProductCardProps> = Template.bind({});
ManyVariants.args = {
	...LoggedIn.args,
	product: {
		id: '2',
		name: 'Mixer for mixing of Epoxy, 1 mixer and 1 disposable propeller ...',
		price: {
			price: 1055,
			referencePrice: 1100,
			currencyCode: 'DKK',
			formattedPrice: '1.055,00 DKK',
		},
		categories: ['Clothing'],
		historyLabel: 'Purchased on',
		historyValue: '15/08/2021',
		serialLabel: 'Prod.no:',
		sku: '85046922',
		guid: '',
		ctaLoggedIn: 'Add to cart',
		ctaNotLoggedIn: 'View product',
		image: {
			url: 'https://e-shop.struers.com/US/EN/images/Item_40600313_250_300_png',
			properties: {
				altText: 'Alternative text',
			},
		},
		link: {
			name: 'Gå til produktet',
			url: '#',
		},
		variants: [
			{
				id: '2',
				type: 'sizes',
				name: 'S',
				price: 152,
				quantity: 3,
				historyLabel: 'Purchased on',
				historyValue: '15/08/2021',
				serialLabel: 'Prod.no:',
				serialValue: '12456922',
				cta: 'Add to cart',
			},
			{
				id: '21',
				type: 'sizes',
				name: 'M',
				price: 452,
				quantity: 5,
				historyLabel: 'Purchased on',
				historyValue: '15/08/2021',
				serialLabel: 'Prod.no:',
				serialValue: '32546922',
				cta: 'Add to cart',
			},
			{
				id: '22',
				type: 'sizes',
				name: 'L',
				price: 652,
				quantity: 0,
				historyLabel: 'Purchased on',
				historyValue: '15/08/2021',
				serialLabel: 'Prod.no:',
				serialValue: '45046922',
				cta: 'Add to cart',
			},
			{
				id: '23',
				type: 'sizes',
				name: 'XL',
				price: 1552,
				quantity: 5,
				historyLabel: 'Purchased on',
				historyValue: '15/08/2021',
				serialLabel: 'Prod.no:',
				serialValue: '95046922',
				cta: 'Add to cart',
			},
			{
				id: '24',
				type: 'sizes',
				name: 'XXL',
				price: 22,
				quantity: 5,
				historyLabel: 'Purchased on',
				historyValue: '15/08/2021',
				serialLabel: 'Prod.no:',
				serialValue: '75046922',
				cta: 'Add to cart',
			},
		],
		productImages: [],
		quantity: 5,
	},
};

export const FewVariants: Story<ProductCardProps> = Template.bind({});
FewVariants.args = {
	...LoggedIn.args,
	product: {
		id: '3',
		name: 'Produkt1',
		price: {
			price: 155,
			referencePrice: 170,
			currencyCode: 'DKK',
			formattedPrice: '155,00 DKK',
		},
		categories: ['Clothing'],
		historyLabel: 'Purchased on',
		historyValue: '15/08/2021',
		serialLabel: 'Prod.no:',
		sku: '75046922',
		guid: '',
		ctaLoggedIn: 'Add to cart',
		ctaNotLoggedIn: 'View product',
		image: {
			url: 'https://e-shop.struers.com/US/EN/images/Item_40700031_250_300_png',
			properties: {
				altText: 'Alternative text',
			},
		},
		link: {
			name: 'Gå til produktet',
			url: '#',
		},
		variants: [
			{
				id: '3',
				type: 'sizes',
				name: 'S',
				price: 152,
				quantity: 3,
				historyLabel: 'Purchased on',
				historyValue: '15/08/2021',
				serialLabel: 'Prod.no:',
				serialValue: '55046922',
				cta: 'Add to cart',
			},
			{
				id: '31',
				type: 'sizes',
				name: 'M',
				price: 452,
				quantity: 5,
				historyLabel: 'Purchased on',
				historyValue: '15/08/2021',
				serialLabel: 'Prod.no:',
				serialValue: '25046922',
				cta: 'Add to cart',
			},
			{
				id: '32',
				type: 'sizes',
				name: 'L',
				price: 652,
				quantity: 0,
				historyLabel: 'Purchased on',
				historyValue: '15/08/2021',
				serialLabel: 'Prod.no:',
				serialValue: '35046922',
				cta: 'Add to cart',
			},
		],
		productImages: [],
		quantity: 5,
	},
};

export const SoldOut: Story<ProductCardProps> = Template.bind({});
SoldOut.args = {
	...LoggedIn.args,
	product: {
		id: '4',
		name: 'Produkt1',
		price: {
			price: 550,
			referencePrice: 600,
			currencyCode: 'DKK',
			formattedPrice: '550,00 DKK',
		},
		categories: ['Clothing'],
		historyLabel: 'Purchased on',
		historyValue: '15/08/2021',
		serialLabel: 'Prod.no:',
		sku: '45046922',
		guid: '',
		ctaLoggedIn: 'Add to cart',
		ctaNotLoggedIn: 'View product',
		image: {
			url: 'https://e-shop.struers.com/US/EN/images/Item_40700041_250_300_png',
			properties: {
				altText: 'Alternative text',
			},
		},
		link: {
			name: 'Gå til produktet',
			url: '#',
		},
		variants: [
			{
				id: '41',
				type: 'sizes',
				name: 'S',
				price: 152,
				quantity: 0,
				historyLabel: 'Purchased on',
				historyValue: '15/08/2021',
				serialLabel: 'Prod.no:',
				serialValue: '05046922',
				cta: 'Add to cart',
			},
			{
				id: '42',
				type: 'sizes',
				name: 'M',
				price: 452,
				quantity: 0,
				historyLabel: 'Purchased on',
				historyValue: '15/08/2021',
				serialLabel: 'Prod.no:',
				serialValue: '05046922',
				cta: 'Add to cart',
			},
			{
				id: '43',
				type: 'sizes',
				name: 'L',
				price: 652,
				quantity: 0,
				historyLabel: 'Purchased on',
				historyValue: '15/08/2021',
				serialLabel: 'Prod.no:',
				serialValue: '05046922',
				cta: 'Add to cart',
			},
		],
		productImages: [],
		quantity: 5,
	},
};
