import styles from './Facets.module.scss';
import classNames from 'classnames';
import { Facet } from '../Facet';

export interface FacetProp {
	facetvalues: {
		value: string;
		count: number;
	}[];
	name: string;
	displayName: string;
	count: number;
}

export interface FacetProps {
	className?: string;
	facets: FacetProp[];
	handleFacetsChanged: (name: string, value: string) => void;
	selectionId?: string;
}

export const Facets: React.FC<FacetProps> = ({
	className,
	facets,
	handleFacetsChanged,
	selectionId,
}) => {
	const printFacets = (facet) => {
		return (
			<Facet
				facetChanged={(value) => handleFacetsChanged(facet.name, value)}
				facet={facet}
				key={selectionId + facet.name}
				selectionId={selectionId}
			></Facet>
		);
	};

	return (
		<aside className={classNames(styles.Facet, className)}>
			{facets && facets.map((facet) => printFacets(facet))}
		</aside>
	);
};
