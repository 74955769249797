import { useState } from 'react';
import styles from './ModalForm.module.scss';
import { Button } from 'ui/components/1-atoms';
import {
	FormfieldSelect,
	FormfieldString,
	FormfieldTextarea,
} from 'ui/components/2-molecules';
import { Grid, GridCell } from 'ui/components/4-habitats';
import {
	CategoryFilter,
	CategoryFilterProps,
} from '../../CategoryFilter/CategoryFilter';
import { Form } from '../../Form';
import { useForm, SubmitHandler, useFormState } from 'react-hook-form';
import { useContext } from 'react';
import { DictionaryContext } from 'application/adapters/context/Dictionary';

interface FormData {
	description: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	company: string;
	jobTitle: string;
	country: string;
	city: string;
}

export interface ModalProps {
	categoryFilter?: CategoryFilterProps;
}

export const ModalForm: React.FC<ModalProps> = ({ categoryFilter }) => {
	const dictionary = useContext(DictionaryContext);
	// FORM HANDLING
	const [allErros, setAllErros] = useState(0);
	const [errorSummary, setErrorSummary] = useState(0);
	const { register, handleSubmit, control } = useForm<FormData>();

	const onSubmit: SubmitHandler<FormData> = (data) => {
		alert(`Thank for submitting, ${JSON.stringify(data)}`);
	};
	const { errors } = useFormState({
		control,
	});

	const onError = (data) => {
		const totalErrors = Object.keys(data).length;
		setErrorSummary(totalErrors);
		setAllErros(data);
	};
	// FORM HANDLING

	return (
		<>
			<CategoryFilter
				className="CategoryFilter_Modal"
				smallHeading={categoryFilter.smallHeading}
				categoryFilterItems={categoryFilter.categoryFilterItems}
			/>
			<Form
				onSubmit={handleSubmit(onSubmit, onError)}
				errorSummary={errorSummary}
			>
				<Grid wrap={true} container={true}>
					<GridCell mobileWidth="100" desktopWidth="100">
						<FormfieldTextarea
							id="description"
							name="description"
							label={dictionary.getValue(
								'Form.Description',
								null,
								'Description',
							)}
							placeholder="Type here"
							className="u-bottom-margin--md"
							register={register}
							state={
								errors.description
									? {
											hasError: true,
											required: true,
									  }
									: { required: true }
							}
							errorMessage={dictionary.getValue(
								'Form.ErrorMessage',
								null,
								'This field is required',
							)}
						/>
					</GridCell>
					<GridCell mobileWidth="100" desktopWidth="50">
						<FormfieldString
							id="firstName"
							name="firstName"
							label={dictionary.getValue(
								'Form.FirstName',
								null,
								'First name',
							)}
							type="text"
							register={register}
							className="u-bottom-margin--md"
							state={
								errors.firstName
									? {
											hasError: true,
											required: true,
									  }
									: { required: true }
							}
							errorMessage={dictionary.getValue(
								'Form.ErrorMessage',
								null,
								'This field is required',
							)}
						/>
					</GridCell>

					<GridCell mobileWidth="100" desktopWidth="50">
						<FormfieldString
							id="lastName"
							name="lastName"
							label={dictionary.getValue(
								'Form.LastName',
								null,
								'Last name',
							)}
							type="text"
							register={register}
							className="u-bottom-margin--md"
							state={
								errors.lastName
									? {
											hasError: true,
											required: true,
									  }
									: { required: true }
							}
							errorMessage={dictionary.getValue(
								'Form.ErrorMessage',
								null,
								'This field is required',
							)}
						/>
					</GridCell>

					<GridCell mobileWidth="100" desktopWidth="50">
						<FormfieldString
							id="email"
							name="email"
							label={dictionary.getValue(
								'Form.Email',
								null,
								'Email',
							)}
							type="email"
							register={register}
							state={
								errors.email
									? {
											hasError: true,
											required: true,
									  }
									: { required: true }
							}
							errorMessage={dictionary.getValue(
								'Form.ErrorMessage',
								null,
								'This field is required',
							)}
							pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
							className="u-bottom-margin--md"
						/>
					</GridCell>

					<GridCell mobileWidth="100" desktopWidth="50">
						<FormfieldString
							id="phone"
							name="phone"
							label={dictionary.getValue(
								'Form.PhoneNumber',
								null,
								'Phone number',
							)}
							type="number"
							register={register}
							className="u-bottom-margin--md"
						/>
					</GridCell>

					<GridCell mobileWidth="100" desktopWidth="50">
						<FormfieldString
							id="company"
							name="company"
							label={dictionary.getValue(
								'Form.Company',
								null,
								'Company / Organization',
							)}
							type="text"
							register={register}
							state={
								errors.company
									? {
											hasError: true,
											required: true,
									  }
									: { required: true }
							}
							errorMessage={dictionary.getValue(
								'Form.ErrorMessage',
								null,
								'This field is required',
							)}
							className="u-bottom-margin--md"
						/>
					</GridCell>

					<GridCell mobileWidth="100" desktopWidth="50">
						<FormfieldString
							id="jobTitle"
							name="jobTitle"
							label={dictionary.getValue(
								'Form.JobTitle',
								null,
								'Job Title',
							)}
							type="text"
							register={register}
							className="u-bottom-margin--md"
						/>
					</GridCell>

					<GridCell mobileWidth="100" desktopWidth="50">
						<FormfieldSelect
							id="country"
							name="country"
							label={dictionary.getValue(
								'Form.Country',
								null,
								'Country',
							)}
							defaultValue=""
							options={[
								{
									value: '',
									text: 'Choose a country',
									hidden: true,
									disabled: true,
								},
								{
									value: 'denmark',
									text: 'Denmark',
								},
								{
									value: 'sweden',
									text: 'Sweden',
								},
								{
									value: 'norway',
									text: 'Norway',
								},
							]}
							className="u-bottom-margin--md"
							register={register}
							state={
								errors.country
									? {
											hasError: true,
											required: true,
									  }
									: { required: true }
							}
							errorMessage={dictionary.getValue(
								'Form.ErrorMessage',
								null,
								'This field is required',
							)}
						/>
					</GridCell>

					<GridCell mobileWidth="100" desktopWidth="50">
						<FormfieldString
							id="city"
							name="city"
							label={dictionary.getValue(
								'Form.City',
								null,
								'City',
							)}
							type="text"
							register={register}
							className="u-bottom-margin--md"
							state={
								errors.city
									? {
											hasError: true,
											required: true,
									  }
									: { required: true }
							}
							errorMessage={dictionary.getValue(
								'Form.ErrorMessage',
								null,
								'This field is required',
							)}
						/>
					</GridCell>

					<GridCell
						mobileWidth="100"
						desktopWidth="50"
						className={styles.ModalForm_submitButtonContainer}
					>
						<Button
							style="primary"
							type="submit"
							aria-label={dictionary.getValue(
								'Form.Send',
								null,
								'Send Enquiry',
							)}
						>
							{dictionary.getValue(
								'Form.Send',
								null,
								'Send Enquiry',
							)}
						</Button>
					</GridCell>
				</Grid>
			</Form>
		</>
	);
};
