import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProfileState {
	showProfile: boolean;
}

const initialState: ProfileState = {
	showProfile: false,
};

export const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		toggleProfileVisiblity: (state, action: PayloadAction<boolean>) => {
			state.showProfile = action.payload;
		},
	},
});

export const { toggleProfileVisiblity } = profileSlice.actions;
export default profileSlice.reducer;
