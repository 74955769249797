import classNames from 'classnames';
import styles from './Accordion.module.scss';
import { Heading } from 'ui/components';
import { AccordionItem, AccordionItemProps } from './AccordionItem';

export interface AccordionProps {
	className?: string;
	accordionItems: AccordionItemProps[];
	title?: string;
	routerPath?: string;
	id?: string;
}

export const Accordion: React.FC<AccordionProps> = ({
	className,
	accordionItems,
	title,
	routerPath,
	id,
}) => {
	return (
		<div className={classNames(styles.Accordion, className)}>
			<div className={classNames(styles.Accordion_ref)} id={id}></div>
			<Heading
				headingLevel="h2"
				style="md"
				className={styles.Accordion_heading}
			>
				{title}
			</Heading>
			{accordionItems &&
				accordionItems.map((accordionItem, index) => (
					<AccordionItem
						{...accordionItem}
						key={index}
						routerPath={routerPath}
					/>
				))}
		</div>
	);
};
