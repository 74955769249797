import React from 'react';
import styles from './NavigationStep.module.scss';
import classNames from 'classnames';

export interface NavigationStepProps {
	className?: string;
	stepNumber?: Models.NavigationStep['step'];
	stepTitle?: Models.NavigationStep['stepTitle'];
	isActive?: boolean;
	isSuccess?: boolean;
	isLastStep?: boolean;
}

export const NavigationStep: React.FC<NavigationStepProps> = ({
	className,
	stepNumber,
	stepTitle,
	isActive,
	isSuccess,
	isLastStep,
}) => {
	return (
		<div
			className={classNames(
				styles.NavigationStep,
				{
					[styles.NavigationStep___expand]: isActive && !isLastStep,
				},
				className,
			)}
		>
			<div className={classNames(styles.NavigationStep_container)}>
				<div
					className={classNames(
						styles.NavigationStep_stepNumber___wrapper,
						{
							[styles.NavigationStep_stepNumber___wrapper___isActive]:
								isActive,
						},
						{
							[styles.NavigationStep_stepNumber___wrapper___isSuccess]:
								isSuccess,
						},
					)}
				>
					<p
						className={classNames(
							styles.NavigationStep_stepNumber___text,
							{
								[styles.NavigationStep_stepNumber___text___isActive]:
									isActive,
							},
							{
								[styles.NavigationStep_stepNumber___text___isSuccess]:
									isSuccess,
							},
						)}
					>
						{stepNumber}
					</p>
				</div>
				<div
					className={classNames(styles.NavigationStep_stepTitle, {
						[styles.NavigationStep_stepTitle___isActive]: isActive,
					})}
				>
					<p
						className={classNames(
							styles.NavigationStep_stepTitle___text,
							{
								[styles.NavigationStep_stepTitle___text___isActive]:
									isActive,
							},
							{
								[styles.NavigationStep_stepTitle___text___isSuccess]:
									isSuccess,
							},
						)}
					>
						{stepTitle}
					</p>
				</div>
				{isLastStep ? null : (
					<div
						className={classNames(styles.NavigationStep_line)}
					></div>
				)}
			</div>
		</div>
	);
};
