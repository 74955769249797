import classNames from 'classnames';
import styles from './ErrorMessage.module.scss';
import { Paragraph, ParagraphProps } from '../../Paragraph';

export interface ErrorMessageProps {
	className?: string;
	children?: React.ReactNode;
	style?: ParagraphProps["style"];
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
	className,
	children,
	style = "sm",
}) => (
	<Paragraph
		style={style}
		className={classNames(styles.ErrorMessage, className)}
	>
		{children}
	</Paragraph>
);
