import classNames from 'classnames';
import styles from './AddressCard.module.scss';
import { Heading, Paragraph } from 'ui/components/1-atoms';

export interface AddressCardProps {
	style?: 'primary' | 'secondary';
	className?: string;
	addressType?: Models.AddressType;
	address: Models.AddressLines;
	id?: string;
	dictionary?: { [key: string]: string | unknown };
}

export const AddressCard: React.FC<AddressCardProps> = ({
	className,
	style,
	id,
	addressType,
	address,
	dictionary,
}) => {
	return (
		<>
			<div
				className={classNames(
					styles.AddressCard,
					styles[`AddressCard___${style}`],
					className,
				)}
			>
				<Heading
					headingLevel="h4"
					style="xxs"
					className={classNames(styles.AddressCard_heading)}
				>
					{dictionary[addressType]}
				</Heading>

				
				{address.displayAddress && (
					address.displayAddress.split('\n').map((line, index) => (
					<Paragraph key={index} style="xs">{line}</Paragraph>)
					)
				)}
				{id && (
					<Paragraph style="xs">ID: {id}</Paragraph>
				)}
			</div>
		</>
	);
};
