import classNames from 'classnames';
import styles from './ContentCard.module.scss';
import { Heading, LinkButton, Picture } from 'ui/components';

export interface ContentCardProps {
	className?: string;
	image?: Umbraco.Image;
	link?: Umbraco.Link;
	kicker?: string;
	heading?: string;
	text?: string;
	displayLinkAsButton: boolean;
	orientation: Models.Orientation;
}

export const ContentCard: React.FC<ContentCardProps> = ({
	className,
	image,
	link,
	kicker,
	heading,
	text,
	displayLinkAsButton,
	orientation,
}) => {
	// TODO: This needs some refactoring
	const contentInner = (
		<>
			{image && (
				<div className={styles.ContentCard_media}>
					<span className={styles.ContentCard_mediabox}>
						<Picture
							aspectRatio={0.5625}
							url={image.url}
							properties={image.properties}
							focalPoint={image.focalPoint}
							sizes="100vw"
						/>
					</span>
				</div>
			)}
			<div className={styles.ContentCard_textbox}>
				<div className={styles.ContentCard_kicker}>{kicker}</div>
				<Heading className={styles.ContentCard_heading} headingLevel="h3">
					{heading}
				</Heading>
				<div className={styles.ContentCard_text} dangerouslySetInnerHTML={{ __html: text }}></div>
			</div>
			<div>
				{displayLinkAsButton && link && (
					<LinkButton className={styles.ContentCard_button} style="primary">
						{link.name}
					</LinkButton>
				)}
			</div>
		</>
	);

	let content;

	if (link && !displayLinkAsButton) {
		content = (
			<a
				className={classNames(
					styles.ContentCard,
					{ [styles.ContentCard___row]: orientation === 'Horizontal' },
					{ [styles.ContentCard___rowReverse]: orientation === 'Horizontal Reverse' },
					styles.ContentCard___link,
					className,
				)}
				href={link.url}
				target={link.target}
				title={link.name}
			>
				{contentInner}
			</a>
		);
	} else {
		content = (
			<div
				className={classNames(
					styles.ContentCard,
					{ [styles.ContentCard___row]: orientation === 'Horizontal' },
					{ [styles.ContentCard___rowReverse]: orientation === 'Horizontal Reverse' },
					className,
				)}
			>
				{contentInner}
			</div>
		);
	}

	return content;
};
