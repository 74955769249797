import { Button } from 'ui/components';
import styles from './DoubleButton.module.scss';
import classNames from 'classnames';
import { useState } from 'react';

export interface DoubleButtonProps {
	textLeft: string;
	textRight: string;
	selectionUpdated?: (left: boolean) => void;
	defaultValue?: boolean;
}

export const DoubleButton: React.FC<DoubleButtonProps> = ({
	textLeft,
	textRight,
	selectionUpdated,
	defaultValue,
}) => {
	const [isActiveLeftButton, setIsActiveLeftButton] = useState(
		defaultValue === false ? false : true,
	);

	return (
		<div className={classNames(styles.DoubleButton_container)}>
			<Button
				className={classNames(
					styles.DoubleButton,
					styles.DoubleButton___left,
					{
						[styles.DoubleButton___isActive]: isActiveLeftButton,
					},
				)}
				onClick={() => {
					setIsActiveLeftButton(true);
					selectionUpdated(true);
				}}
				style="secondarySmall"
			>
				{textLeft}
			</Button>
			<Button
				className={classNames(
					styles.DoubleButton,
					styles.DoubleButton___right,
					{
						[styles.DoubleButton___isActive]: !isActiveLeftButton,
					},
				)}
				onClick={() => {
					setIsActiveLeftButton(false);
					selectionUpdated(false);
				}}
				style="secondarySmall"
			>
				{textRight}
			</Button>
		</div>
	);
};
