import { useState, useContext } from 'react';
import styles from './StepNavigation.module.scss';
import classNames from 'classnames';
import { NavigationStep, Button } from 'ui/components/';
import { ReactComponent as IconArrowRight } from 'ui/icons/icon_arrow_right.svg';
import { ReactComponent as IconArrowLeft } from 'ui/icons/icon_arrow_left.svg';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { Container, Heading } from 'ui/components/';

export interface StepNavigationProps {
	className?: string;
	steps: Models.NavigationStep[];
	currentStep?: number;
	handleProceed?: () => void;
	handlePrevious?: () => void;
	isProceedDisabled?: boolean; // If true, renders the button in a disabled state
	heading?: string;
}

export const StepNavigation: React.FC<StepNavigationProps> = ({
	className,
	steps,
	currentStep,
	handleProceed,
	handlePrevious,
	isProceedDisabled,
	heading,
}) => {
	const dictionary = useContext(DictionaryContext);

	return (
		<Container width="Standard">
			<div className={classNames(styles.StepNavigation, className)}>
				<div className={classNames(styles.StepNavigation_indicator)}>
					{steps?.map((step, index) => (
						<NavigationStep
							key={index}
							stepNumber={step.step + 1}
							stepTitle={dictionary.getValue(
								'StepNavigation.stepTitle',
								null,
								step.stepTitle,
							)}
							isSuccess={
								!(currentStep === step?.step) && // not the active/current Step
								!(currentStep === steps[index - 1]?.step) && // not the lastStep
								!(currentStep < step?.step) // not the next step
							}
							isActive={currentStep === step.step}
							isLastStep={
								step.step === steps[steps?.length - 1]?.step
							}
						/>
					))}
				</div>

				<div className={styles.StepNavigation_content}>
					{heading && (
						<Heading
							className={styles.StepNavigation_heading}
							headingLevel="h2"
							uppercase
						>
							{heading}
						</Heading>
					)}
					<div className={styles.StepNavigation_buttons}>
						{handlePrevious && (
							<Button
								style="primary"
								className={classNames(
									styles.StepNavigation_buttonPrevious,
								)}
								onClick={handlePrevious}
								disabled={currentStep === steps[0].step}
							>
								<IconArrowLeft
									className={
										styles.StepNavigation_buttonPrevious___iconLeft
									}
								/>
								{dictionary.getValue(
									'StepNavigation.Previous',
									null,
									'Previous',
								)}
							</Button>
						)}
						{(handleProceed || isProceedDisabled) && (
							<Button
								style="primary"
								onClick={handleProceed}
								disabled={
									isProceedDisabled ||
									currentStep === steps[steps.length - 1].step // disabled if it's the last step
								}
								className={classNames(
									styles.StepNavigation_buttonNext,
								)}
							>
								{dictionary.getValue(
									'StepNavigation.Proceed',
									null,
									'Proceed',
								)}
								<IconArrowRight
									className={
										styles.StepNavigation_buttonNext___iconRight
									}
								/>
							</Button>
						)}
					</div>
				</div>
			</div>
		</Container>
	);
};
